import React, { useState } from "react";
import "./clearance-item.styles.scss";
import { addItem } from "../../redux/cart/cart.actions";
import ColourChooser from "../../pages/shop/ColourChooser";
import CustomButton from "../custom-button/custom-button.component";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Typography,
  Grid,
  Paper,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Box,
  CircularProgress
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { createStructuredSelector } from "reselect";
import { selectUserAuthStatus } from "../../redux/user/user.selectors";
import { withRouter } from "react-router-dom";
import AuthOnly from "../AuthOnly";
import HiddenIf from "../HiddenIf";

import WishlistButton from "../wishlistButton/WishlistButton";
import { properRounding } from "../../utils/helpers";

const useStyles = makeStyles(theme => ({
  gst: {
    fontSize: 8
  },
  discountnoteclass: {
    fontSize: 11,
    textAlign: 'center'
  },
  colourChooser: {
    width: "100%"
  },
  boxedNumber: {
    border: "none",
    margin: "0 15px 0 0",
    padding: "0",
    display: "inline",
    fontSize: "14px"
  },
  price: {
    fontSize: "14px"
  },
  salePrice: {
    fontSize: "14px",
    textDecoration: "line-through"
  },
  discountPrice: {
    fontSize: "16px",
    color: "red",
    marginRight: "5px"
  },
  cardContent: {
    paddingTop: "10px",
    paddingBottom: "0"
  },
  cardMedia: {
    minHeight: "100px",
    objectFit: "contain",
    marginTop: "10px",
  },
  loading: {
    display: "flex",
    margin: "auto",
    alignItems: "center",
    height: "70px",
    width: "100%"
  },
  saleTag: {
    width: "100px",
    float: "right",
    transform: "translateX(-14px) translateY(-5px)",
    //left: "-10px",
    // top: "1px",
    zIndex: 1,
    position: "absolute",
    backgroundRepeat: "no-repeat",
    height: "100px"
  }
}));

const ClearanceItem = props => {
  const [state, setState] = useState({
    itemChosen: 0,
    itemChosenColorWatcher: props.product.productinstance[0].colourswatch,
    itemChosenTitle: props.product.productinstance[0].title,
    itemChosenColour:  props.product.productinstance[0].colour ?  props.product.productinstance[0].colour : "",
    errorCount: 0,
    isSelected: true,
    imageLoaded: true
  });

  const handleChosenItem = (choice, choiceIndex) => {
    setState({
      itemChosen: choiceIndex,
      itemChosenColorWatcher: choice.colourswatch ? choice.colourswatch : "",
      itemChosenTitle: choice.title ? choice.title : "",
      itemChosenColour: choice.colour ? choice.colour : "",
      errorCount: 0,
      isSelected: true
    });
  };

  const onImageError = e => {
    setState({
      ...state,
      errorCount: state.errorCount + 1
    });
    if (state.errorCount === 1) {
      e.target.src = currentItem.imageBackup;
    } else {
      e.target.src = currentItem.imageDefault;
    }
  };
  const onImageLoad = e => {
    setState({
      ...state,
      imageLoaded: true
    });
  };
  const product = { ...props.product, productinstance: undefined };
  const currentItem = props.product.productinstance[state.itemChosen];
  const classes = useStyles();
  const priceClass = !currentItem.clearanceitem
    ? classes.price
    : classes.salePrice;


  return (
    <>
      <Card className='prodgrid-item'>
        <HiddenIf value={currentItem.clearanceitem}>
          <div
            // className= "saletag"
            className={classes.saleTag}
            style={{
              backgroundImage: `url(${"https://www.optiqueline.com.au/skin/frontend/optiqueline/default/assets/images/sale-tag-new.png"})`
            }}
          />
        </HiddenIf>
        <CardActionArea className="buttonOnShop">
        <WishlistButton product={product} currentItem={currentItem} />
          <Link
            to={{
              pathname: "/productDetail",
              search: "?productId=" + props.product.productid
            }}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <CardMedia
              className={classes.cardMedia}
              title=""
              component="img"
              onError={onImageError}
              onLoad={onImageLoad}
              image={currentItem.image}
            />
            <CardContent
              className={classes.cardContent}
              style={{ paddingBottom: 0, paddingTop: "20px" }}
            >
              <Grid container alignItems="flex-end">
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography variant="h6" style={{ fontSize: "15px" }}>
                      {props.productfiltername}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" style={{ fontSize: "15px", textAlign: "right" }}>
                      {currentItem.model}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container alignItems="flex-end" justify="space-between">
                <Grid addItem>
                  
                  <Grid item>
                    <Box className={classes.boxedNumber}>
                      {currentItem.eyesize}.{currentItem.bridge}
                    </Box>
                    <Box className={classes.boxedNumber}>
                      {props.product.productdepth}
                    </Box>
                  </Grid>
                </Grid>
                <Grid item>
                  <AuthOnly>
                    <Grid container alignItems="center">
                      <HiddenIf value={currentItem.clearanceitem}>
                        <Grid item>
                          <Typography
                            display={"inline"}
                            className={classes.discountPrice}
                          >
                            ${properRounding(currentItem.price,2,1)}
                          </Typography>
                          {/* <Typography
                            display={"inline"}
                            className={classes.gst}
                            style={{ color: "red" }}
                          >
                            +GST
                          </Typography> */}
                        </Grid>
                      </HiddenIf>
                      <Grid item className={priceClass}>
                        ${properRounding(currentItem.normalprice,2,1)}
                        <Typography variant="inherit" className={classes.gst}>
                          +GST
                        </Typography>
                      </Grid>
                    </Grid>
                  </AuthOnly>
                </Grid>
              </Grid>
            </CardContent>
          </Link>
        </CardActionArea>

        <CardActions>
          <Grid container spacing={1}>
          <AuthOnly>
          {currentItem.discountnote?
          <Grid item xs={12}>
            <Typography className={classes.discountnoteclass}>
            {currentItem.discountnote}
            </Typography>
          </Grid>
            :null}
          </AuthOnly>
            
            <Grid item xs={12}>
              <ColourChooser
                className={classes.colourChooser}
                choices={props.product}
                itemChosen={state}
                productIndex={props.product.productid}
                handleChosenItem={handleChosenItem}
                sizeMatters={1}
              />
            </Grid>
            <AuthOnly>
              <Grid item xs={12}>
                <CustomButton
                  onClick={() => props.addItem({ ...currentItem, ...product})}
                >
                  {currentItem.stockavailable?'Add to cart':'Backorder'}
                </CustomButton>
              </Grid>
            </AuthOnly>
          </Grid>
        </CardActions>
      </Card>
    </>
  );
};
const mapStateToProps = createStructuredSelector({
  isLoggedIn: selectUserAuthStatus
});
export default connect(mapStateToProps, { addItem })(ClearanceItem);
