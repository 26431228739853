import React, { useState } from "react";
import CustomButton from "../custom-button/custom-button.component";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import OnInputChange from "../onInputChangeHOCs/OnInputChange.component";
import HiddenIf from "../HiddenIf";


const Input = props => <input {...props} />;

const WrappedInput = OnInputChange(Input);

const useStyles = makeStyles({
  formHeading: {
    padding: "10px 15px",
    borderRadius: "3px",
    backgroundColor: "#212121",
    fontSize: "25px",
    color: "#fff",
    marginBottom: "10px",
    textAlign: "center"
  },
  edit: {
    color: "#34b3d2",
    fontSize: "0.875em",
    "&:hover": {
      color: "#34b3d2"
    }
  },
  formField: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    padding: "20px",
    "@media screen and (max-width:800px)": {
      width: "100%"
    },
    '&:spanContainer': {
      display: "flex",
      flexDirection: 'row'
    }
  },
  addressInput: {
    marginBottom: "10px",
    padding: "10px",
    textAlign: "center"
  }
});
export const Uatt = {
  firstname: "firstname",
  middlename: "middlename",
  lastname: "lastname",
  street1: "street1",
  street2: "street2",
  city: "city",
  region: "region",
  postcode: "postcode",
  phonenumber: "phonenumber",
  country: "country",
  company: "company"
}

export const FormValidation = {
  
}

const AddressBookItem = ({ address, disabled = false, display = true , saveAble = true, ...props }) => {
  const [ isDisplay , setIsDisplay  ] = useState(display)
  const classes = useStyles();
  const handleEdit = (event) => {
    if(display){
      setIsDisplay(false)
    }
    event.preventDefault()
  }
  const handleCancel = (event) => {
    if(display){
      setIsDisplay(true)
    }
    props.resetUser(event)
  }
  const handleSave = (event) => {
    if(saveAble){
      props.saveUser(event)
    }
  }
  let location = ""
  if(address.city !== "" && address.region !== "" && address.postcode){
    location = `${address.city},${address.region},${address.postcode}`
  }

  let telephone = ""
  if(address.phonenumber !== ""){
    telephone = `T: ${address.phonenumber}`
  }

  return (
    <form className={classes.formField}>
      <div className={classes.formHeading}>{props.title}</div>
      <div className={classes.spanContainer}>
        <HiddenIf value={isDisplay && !disabled && display}>
          <span className={classes.edit} onClick={(event) => handleEdit(event)}>
            Edit
      </span>
         
      </HiddenIf>
      &nbsp;&nbsp;
        <HiddenIf value={!isDisplay && !disabled}>
          <span className={classes.edit} onClick={(event) => handleCancel(event)}>
            {display ? "Cancel" : "Reset"}
         </span>
        </HiddenIf>
      </div>
      <HiddenIf value={!isDisplay}>
        <input
          className={classes.addressInput}
          disabled={disabled}
          placeholder={"Company"}
          value={address.company}
          onChange={props.updateAddressData(Uatt.company)}
        />
        <input
          className={classes.addressInput}
          disabled={disabled}
          placeholder={"First Name"}
          value={address.firstname}
          onChange={props.updateAddressData(Uatt.firstname)}
        />
        <input
          className={classes.addressInput}
          disabled={disabled}
          placeholder={"Middle Name"}
          value={address.middlename}
          onChange={props.updateAddressData(Uatt.middlename)}
        />
        <input
          className={classes.addressInput}
          disabled={disabled}
          placeholder={"Last Name"}
          value={address.lastname}
          onChange={props.updateAddressData(Uatt.lastname)}
        />
        <input
          className={classes.addressInput}
          disabled={disabled}
          placeholder={"Street1"}
          value={address.street1}
          onChange={props.updateAddressData(Uatt.street1)}
        />
        <input
          className={classes.addressInput}
          disabled={disabled}
          placeholder={"Street2"}
          value={address.street2}
          onChange={props.updateAddressData(Uatt.street2)}
        />
        <input
          className={classes.addressInput}
          disabled={disabled}
          placeholder={"City"}
          value={address.city}
          onChange={props.updateAddressData(Uatt.city)}
        />
        <input
          className={classes.addressInput}
          disabled={disabled}
          placeholder={"Region"}
          value={address.region}
          onChange={props.updateAddressData(Uatt.region)}
        />
        <input
          className={classes.addressInput}
          disabled={disabled}
          placeholder={"Postcode"}
          value={address.postcode}
          onChange={props.updateAddressData(Uatt.postcode)}
        />
        <input
          className={classes.addressInput}
          disabled={disabled}
          placeholder={"Country"}
          value={address.country}
          onChange={props.updateAddressData(Uatt.country)}
        />
        <input
          className={classes.addressInput}
          disabled={disabled}
          placeholder={`Phone Number`}
          value={`${address.phonenumber}`}
          onChange={props.updateAddressData(Uatt.phonenumber)}
        />
        <HiddenIf value={!disabled && saveAble}>
          <CustomButton onClick={(event) => handleSave(event)}>Save</CustomButton>
        </HiddenIf>
      </HiddenIf>
      <HiddenIf value={isDisplay}>
        <p>
          {address.company}
          <br />
          {address.firstname} {address.middlename} {address.lastname}
          <br />
          {address.street1}
          <br />
          {address.street2}
          <br />
          {location}
          <br />
          {address.country}
          <br />
          {telephone}
          <br />
        </p>
      </HiddenIf>
    </form>
  );
};


export default AddressBookItem;
