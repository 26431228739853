//@ts-check
import { mapNavigationToExternal, getMicroNavigationPaths } from "../default/navigation"



const geminiEAllFilter = encodeURIComponent(JSON.stringify({
    "subbrand":{
        "type":"in",
        "value":[
            "GE",
            "GM"
        ]
    }
}))

const geminiCollectionFilter = encodeURIComponent(JSON.stringify({
    "subbrand":{
        "type":"in",
        "value":[
            "GM"
        ]
    }
}))

const geminiEssentialsFilter = encodeURIComponent(JSON.stringify({
    "subbrand":{
        "type":"in",
        "value":[
            "GE"
        ]
    }
}))

export const getNavigationPaths = () => {
    return {
        ...getMicroNavigationPaths(),
        shopCollection: "/shop/collection",
        shopEssentials: "/shop/essentials"
    }
}

export const getNavigationStructure = () => {
    const paths = getNavigationPaths()
    return {
        product: {
          label: "The Product",
          links: {
            "Online Shop" : { label: "Online Shop", path: paths.shop },            
          }
        },        
    }
}

const findAStockistFilter = encodeURIComponent("gemini collection");

export const getNavigationExternals = () => {
    const paths = getNavigationPaths();
    return {
        ...mapNavigationToExternal("https://www.optiqueline.com.au",getNavigationPaths),
        [paths.shopCollection]: `https://www.optiqueline.com.au/shop?filter=${geminiCollectionFilter}`,
        [paths.shopEssentials]: `https://www.optiqueline.com.au/shop?filter=${geminiEssentialsFilter}`,
        [paths.shop]: `https://www.optiqueline.com.au/shop?filter=${geminiEAllFilter}`,
        [paths.findAStockist]: `https://www.optiqueline.com.au/findAStockist?brandreset=1&stockistbrand=${findAStockistFilter}`,
        [paths.becomeastockist]: "https://www.optiqueline.com.au/stockist-request-form"
    }
}

export const getNavigationStatics = () => {
    const paths = getNavigationPaths()
    return {
        // [paths.aboutUs]:"aboutus",
    }
}