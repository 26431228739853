import React from "react";
import { connect } from 'react-redux';
import { selectedCartItemsCount } from '../../redux/cart/cart.selectors';
import { selectLogoInstance } from '../../redux/cms/cms.selectors';
import { AppBar, Toolbar, Box, Grid, Button, ButtonGroup, Hidden, useScrollTrigger, Badge, IconButton, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import { Link, withRouter } from "react-router-dom";
import { getURL, getNavigationPaths, getNavigationStructure, getAssets, getTheme } from '../../microSiteConfig';

import SideNav from './SideNav';


import LoginModal from './LoginModal';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';


import Search from './Search';
import { createStructuredSelector } from 'reselect';
import HiddenIf from "../HiddenIf";
import { ForEach } from "../ForEach"
import DropDownMenu from "./DropDownMenu";
import MainMenu from "./MainMenu";

const ENDPOINTS = getNavigationPaths()
const structure = getNavigationStructure()
const MICROTHEME = getTheme();
const config = getAssets();

const useStyles = makeStyles(theme => ({
    offset: theme.mixins.toolbar,
    logowrapper: theme.logowrapper
}))

function AppNavBar(props) {
    const classes = useStyles();
    //console.log("siteurl=", getURL());
    return (
        <>
            <AppBar className={' header-rootclass-' +MICROTHEME.rootclass} position="fixed" elevation={4}>
                <Container disableGutters >
                    <Toolbar disableGutters position="fixed">
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item>
                                <Grid container direction="row" justify="flex-start" alignItems="center">
                                    <Hidden mdUp>
                                        {/* Show on MOBILE */}
                                        <Grid item>
                                            <SideNav></SideNav>
                                        </Grid>
                                    </Hidden>
                                    <Grid className={classes.logowrapper} item>
                                        <Link to="/" className="link" >
                                            <Hidden xsDown>
                                                <img className="img-logo" src={config.logo} />
                                            </Hidden>
                                            <Hidden smUp>
                                                <img className="img-logo-small" src={config.logo} />
                                            </Hidden>
                                        </Link>
                                    </Grid>
                                    <Hidden smDown>
                                        {/* Show on DESKTOP */}
                                        <Grid item>
                                            <ButtonGroup>
                                                <ForEach items={structure} component={MainMenu} />
                                            </ButtonGroup>

                                        </Grid>
                                    </Hidden>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <HiddenIf value={["www.optiqueline.com.au", "www.optiqueline.co.nz", "optiqueline.com.au", "optiqueline.co.nz"].includes(getURL())}>
                                    <Grid container direction="row" justify="flex-start" alignItems="center">
                                        <Hidden smDown>
                                            <Grid item>
                                                <Search />
                                            </Grid>
                                            </Hidden>
                                        <Hidden xsDown>
                                        <Grid item>
                                            <LoginModal />
                                        </Grid>
                                        </Hidden>
                                        <Grid item>
                                            <IconButton aria-label="shopping cart" component={data => <Link to={"/cart"} style={{ fontSize: 14, color: 'inherit', textDecoration: 'inherit', color: 'gray' }}  {...data} />} >
                                                <Badge badgeContent={props.itemCount} max={9} color="secondary">
                                                    <ShoppingCartOutlinedIcon />
                                                </Badge>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </HiddenIf>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </Container>
            </AppBar>
            <div className={classes.offset} />
        </>
    );
}

const mapStateToProps = createStructuredSelector({
    itemCount: selectedCartItemsCount,
    mainlogo: selectLogoInstance
});

export default connect(mapStateToProps)(withRouter(AppNavBar));