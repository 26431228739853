


export const getTheme = () => {
    return {
        rootclass: 'optiquelinecomau',
        overrides: {
            MuiButton: {
                text: {
                    outline: 'none',
                },
                root: {
                    outline: 'none',
                    '&:visited': {
                        outline: 'none',
                    },
                    '&:active': {
                        outline: 'none',
                    },
                    '&:hover': {
                        outline: 'none',
                    },
                    '&:focus': {
                        outline: 'none',
                    },
                }
    
            }
        },
        palette: {
            primary: {
                main: "#5fd1ea",
                color: "#ffffff",
                text: "#ffffff",
                contrastText: "#fff"
            }, // blue
            secondary: {
                main: "#1e97ce",
                color: "#ffffff",
                text: "#ffffff",
                contrastText: "#ffffff"
            }, // gold
    
            background: {
                default: "#333",
            }
        }
    }
}