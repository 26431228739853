import React, { useState, useRef, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Link, Grid, Hidden, Button, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import { StyledMenu, StyledMenuItem } from "../componentStyles";

import { authStates } from "../../settings";

const useStyles = makeStyles(theme => ({
  textButton: {
    textAlign: "center",
    verticalAlign: "center",
    fontSize: 15,
    margin: "auto",
    height: "100%",
    color: "gray",
    fontWeight: "fontWeightBold"
  },
  textButtonBlue: {
    border: "1px solid #1e97ce",
    backgroundColor: "#1e97ce",
    color: "#fff",
    width: "130px",
    marginBottom: "20px",
    '&:hover': {
      color: '#1e97ce', // replace with your desired hover text color
      backgroundColor: '#fff', // replace with your desired hover background color
    },
  },
  textButtonWhite: {
    border: "1px solid #1e97ce",
    backgroundColor: "#fff",
    color: "#1e97ce",
    width: "130px",
    marginBottom: "20px",
    '&:hover': {
      color: '#fff', // replace with your desired hover text color
      backgroundColor: '#1e97ce', // replace with your desired hover background color
    },
  },
  iconButton: {
    textDecoration: "inherit",
    color: "gray",
    "&:active": {
      border: "none !important"
    }
  }
}));

function LoginFooter(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const open = Boolean(anchorEl);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = async () => {
    setAnchorEl(null);
    props.logOut();
  };

  const handleLogIn = loginType => {
    setAnchorEl(null);
    props.handleOpenModal(loginType);
  };

  useEffect(() => {}, [props.isLoggedIn]);

  return (
    <>
        {props.isLoggedIn ? (
          <>
              <Button className={classes.textButton} style={{fontSize: "12px"}} onClick={handleLogOut}>
                LOGOUT
              </Button>

              <IconButton
                aria-label="account of current user"
                aria-controls="menu-login"
                component="div"
                aria-haspopup="true"
                onClick={() => props.history.push("./account")}
                classes={{
                  root: classes.iconButton,
                  label: classes.iconButton
                }}
              >
                <AccountCircle />
              </IconButton>
          </>
        ) : (
          <div className="footerbuttons">
              <Button className={`${classes.textButton} ${classes.textButtonBlue}`} onClick={() => handleLogIn(authStates.SIGNUP)} >Register</Button>
              <Button className={`${classes.textButton} ${classes.textButtonWhite}`}  onClick={() => handleLogIn(authStates.LOGIN)} >Log in</Button>
          </div>
        )}
        </>
  );
}

export default withRouter(LoginFooter);
