//@ts-check
import { getProduct, queryProduct } from "../../services/product";
import { properRounding } from "../../utils/helpers";
import { notify } from "../app/app.actions";


const defaultState = {
    labDirectEnabled: false,
    storeDirectNum: 1,
    labDirectNum: 0,
    quantity: 1
}

const stockMessages = {
    RE_ORDER_NOT_ENOUGH:'Not enough stock to match previous order',
    ADD_TO_CART_NOT_ENOUGH:'Stock insufficient. Limit is '
}

const genBackorderinfo = (stocklevel = 0, rowQty = 0, storeQty = 0) => {
    
    var backordered = rowQty - stocklevel,
        backorderedlab = 0,
        toRet = [];
    
    if(backordered>0 && storeQty<backordered){
        backorderedlab = backordered - storeQty;
        backordered = backordered - backorderedlab;
    }

    toRet[0] = backordered>0?backordered+" store item"+(backordered>1?"s":"")+" will be backordered":null;
    toRet[2] = backordered;
    toRet[1] = backorderedlab>0?backorderedlab+" LAB item"+(backorderedlab>1?"s":"")+" will be backordered":null;
    toRet[3] = backorderedlab;

    return toRet;
}

export const updateCartItemQuantity = ({ cartItems }, item) => {
    const { itemChosen, quantity } = item;
    let tidyQty = Math.abs(Number(properRounding(quantity,0,0)));
    if (itemChosen.productinstanceid in cartItems) {

        cartItems[itemChosen.productinstanceid].data.customstockmessagenotify = null;

        let foundItem = cartItems[itemChosen.productinstanceid]
        if((itemChosen.stockavailable - foundItem.state.labDirectNum) >= tidyQty || itemChosen.canbackorder){

            foundItem.state.quantity = (foundItem.state.labDirectNum + tidyQty);
            cartItems[itemChosen.productinstanceid] = {
                data: foundItem.data,
                state: {
                    ...foundItem.state,
                    storeDirectNum: tidyQty
                }
            }

            var backorderinfo = genBackorderinfo(itemChosen.stockavailable,cartItems[itemChosen.productinstanceid].state.quantity,tidyQty);
            cartItems[itemChosen.productinstanceid].state.customstockmessage = backorderinfo[0];
            cartItems[itemChosen.productinstanceid].state.customstockmessagelab = backorderinfo[1];

        } else {
            cartItems[itemChosen.productinstanceid].data.customstockmessagenotify = stockMessages.ADD_TO_CART_NOT_ENOUGH+itemChosen.stockavailable;
        }
    }
    return {...cartItems};
}

export const updateCartItemLabQuantity = ({ cartItems }, item) => {
    const { itemChosen, quantity } = item;
    var tidyQty = Math.abs(Number(properRounding(quantity,0,0))),
        newStoreDirectNum = 0;
    if (itemChosen.productinstanceid in cartItems) {

        cartItems[itemChosen.productinstanceid].data.customstockmessagenotify = null;

        const foundItem = cartItems[itemChosen.productinstanceid]
        if(tidyQty !== foundItem.state.labDirectNum) {//only do things here if the qty has actually changed
            
            
            if(tidyQty < foundItem.state.labDirectNum){//is now fewer LabDirect items

                newStoreDirectNum = foundItem.state.storeDirectNum + (foundItem.state.labDirectNum - tidyQty);//make Store Direct take the Lab Direct items

                cartItems[itemChosen.productinstanceid] = {
                    data: cartItems[itemChosen.productinstanceid].data,
                    state: {
                        ...foundItem.state,
                        storeDirectNum: newStoreDirectNum,
                        labDirectNum: tidyQty
                    }
                }

                var backorderinfo = genBackorderinfo(itemChosen.stockavailable,cartItems[itemChosen.productinstanceid].state.quantity,newStoreDirectNum);
                cartItems[itemChosen.productinstanceid].state.customstockmessage = backorderinfo[0];
                cartItems[itemChosen.productinstanceid].state.customstockmessagelab = backorderinfo[1];

            } else {//is now more lab direct

                if(itemChosen.stockavailable >= tidyQty || itemChosen.canbackorder){

                    newStoreDirectNum = Math.max(Number(foundItem.state.storeDirectNum - (tidyQty - foundItem.state.labDirectNum)),0);
    
                    foundItem.state.quantity = (newStoreDirectNum + tidyQty);
    
                    cartItems[itemChosen.productinstanceid] = {
                        data: cartItems[itemChosen.productinstanceid].data,
                        state: {
                            ...foundItem.state,
                            storeDirectNum: newStoreDirectNum,
                            labDirectNum: tidyQty
                        }
                    }

                    var backorderinfo = genBackorderinfo(itemChosen.stockavailable,cartItems[itemChosen.productinstanceid].state.quantity,newStoreDirectNum);
                    cartItems[itemChosen.productinstanceid].state.customstockmessage = backorderinfo[0];
                    cartItems[itemChosen.productinstanceid].state.customstockmessagelab = backorderinfo[1];

                } else {
                    cartItems[itemChosen.productinstanceid].data.customstockmessagenotify = stockMessages.ADD_TO_CART_NOT_ENOUGH+itemChosen.stockavailable;
                }
            }
        }

    }
    return {...cartItems}
}

export const addCartItem = ({ cartItems }, itemChosen) => {
    
    if (itemChosen.productinstanceid in cartItems) {
        let foundItem = cartItems[itemChosen.productinstanceid]
        if((itemChosen.stockavailable - foundItem.state.labDirectNum) >= (Number(foundItem.state.storeDirectNum) + 1) || itemChosen.canbackorder){

            var newStoreDirectNum = foundItem.state.storeDirectNum +1;
            foundItem.state.quantity = (foundItem.state.labDirectNum + newStoreDirectNum);
            cartItems[itemChosen.productinstanceid] = {
                data: cartItems[itemChosen.productinstanceid].data,
                state: {
                    ...foundItem.state,
                    storeDirectNum: newStoreDirectNum
                }
            }

            var backorderinfo = genBackorderinfo(itemChosen.stockavailable,foundItem.state.quantity,newStoreDirectNum);
            cartItems[itemChosen.productinstanceid].state.customstockmessage = backorderinfo[0];
            cartItems[itemChosen.productinstanceid].state.customstockmessagelab = backorderinfo[1];

            cartItems[itemChosen.productinstanceid].data.customstockmessagenotify = null;
        } else {
            cartItems[itemChosen.productinstanceid].data.customstockmessagenotify = stockMessages.ADD_TO_CART_NOT_ENOUGH +itemChosen.stockavailable;
        }
    } else {
        if(itemChosen.stockavailable || itemChosen.canbackorder){
            cartItems[itemChosen.productinstanceid] = {
                data: itemChosen,
                state: { ...defaultState }
            }

            var backorderinfo = genBackorderinfo(itemChosen.stockavailable,1,1);
            cartItems[itemChosen.productinstanceid].state.customstockmessage = backorderinfo[0];
            cartItems[itemChosen.productinstanceid].state.customstockmessagelab = backorderinfo[1];
            cartItems[itemChosen.productinstanceid].data.customstockmessagenotify = null;
        } else {
            cartItems[itemChosen.productinstanceid].data.customstockmessagenotify = stockMessages.ADD_TO_CART_NOT_ENOUGH+itemChosen.stockavailable;
        }

    }
    return {...cartItems}
}

export const reAddCartItem = ({ cartItems}, theItems, checkItems) => {
    Object.keys(theItems.items).map(function(key, index) {

        let matchFound = 0;

        theItems.items[key].state.customstockmessage = null;
        theItems.items[key].state.customstockmessagelab = null;
        theItems.items[key].data.customstockmessagenotify = null;

        for(var i = 0, k=checkItems.length; i<k; i++){
            
            if(checkItems[i].productid === theItems.items[key].data.productid){

                for(var j = 0, m=checkItems[i].productinstance.length; j<m; j++){

                    if(checkItems[i].productinstance[j].productinstanceid===key){

                        //update important values to match latest from server
                        theItems.items[key].data.stockavailable = checkItems[i].productinstance[j].stockavailable;
                        theItems.items[key].data.canbackorder = checkItems[i].productinstance[j].canbackorder;
                        theItems.items[key].data.normalprice = checkItems[i].productinstance[j].normalprice;
                        theItems.items[key].data.price = checkItems[i].productinstance[j].price;
                        theItems.items[key].data.priceaud = checkItems[i].productinstance[j].priceaud;
                        theItems.items[key].data.pricenzd = checkItems[i].productinstance[j].pricenzd;

                        if(checkItems[i].productinstance[j].stockavailable > theItems.items[key].state.quantity || checkItems[i].productinstance[j].canbackorder){//enough stock to do same as last order
                            matchFound = 1;

                            if(theItems.items[key].state.quantity > checkItems[i].productinstance[j].stockavailable){

                                var backorderinfo = genBackorderinfo(checkItems[i].productinstance[j].stockavailable,theItems.items[key].state.quantity,theItems.items[key].state.storeDirectNum);
                                theItems.items[key].state.customstockmessage = backorderinfo[0];
                                theItems.items[key].state.customstockmessagelab = backorderinfo[1];

                            }


                        } else if(checkItems[i].productinstance[j].stockavailable){//not enough to match last order but some is there to apply

                            theItems.items[key].state.customstockmessage = stockMessages.RE_ORDER_NOT_ENOUGH;

                            theItems.items[key].state.quantity = checkItems[i].productinstance[j].stockavailable;
                            let qtytrack = checkItems[i].productinstance[j].stockavailable;

                            if(theItems.items[key].state.labDirectNum){//was lab direct items

                                if(theItems.items[key].state.labDirectNum <= qtytrack){//enough stock to cover all lab direct items

                                    qtytrack = qtytrack - theItems.items[key].state.labDirectNum;

                                } else {//not enough stock so only adding part of lab direct quantity

                                    theItems.items[key].state.labDirectNum = qtytrack;
                                    qtytrack = 0;
                                }
                            }

                            if(!qtytrack){//no stock left
                                if(theItems.items[key].state.storeDirectNum){//had store direct items so need to wipe and warn

                                    theItems.items[key].state.storeDirectNum = 0;
                                    theItems.items[key].state.customstockmessage = stockMessages.RE_ORDER_NOT_ENOUGH;

                                }
                            } else if(theItems.items[key].state.storeDirectNum){//had store direct items so lets apply remaining availabe stock as required

                                if(theItems.items[key].state.storeDirectNum > qtytrack){//not enough stock so only adding part of lab direct quantity

                                    theItems.items[key].state.storeDirectNum = qtytrack;
                                    theItems.items[key].state.customstockmessage = stockMessages.RE_ORDER_NOT_ENOUGH;
                                }
                            }

                        }
                        break;
                    }
                }
                break;
            }
        }

        if(!matchFound){

            //theItems.items[key].data.subbrand = "NOTAVAILABLE";
            //theItems.items[key].data.subbrandname = theItems.items[key].data.subbrandname + " - NOT AVAILABLE";
            theItems.items[key].state.customstockmessage = stockMessages.RE_ORDER_NOT_ENOUGH;
            theItems.items[key].state.customstockmessagelab = null;
            theItems.items[key].data.stockavailable = 0;
            theItems.items[key].data.canbackorder = false;
            theItems.items[key].state.quantity = 0;
            theItems.items[key].state.storeDirectNum = 0;
            theItems.items[key].state.labDirectNum = 0;
            theItems.items[key].state.totalPrice = 0;
            theItems.items[key].state.storeDirectTotal = "0.00";
            theItems.items[key].state.labDirectTotal = "0.00";
            theItems.items[key].state.labDirectEnabled = false;

        }

        cartItems[key] = {
            data: theItems.items[key].data,
            state: theItems.items[key].state
        }

    });
    return {...cartItems}
}

export const minusCartItem = ({ cartItems }, itemChosen) => {

    var newStoreDirectNum = 0;

    if (itemChosen.productinstanceid in cartItems) {

        cartItems[itemChosen.productinstanceid].data.customstockmessagenotify = null;

        let foundItem = cartItems[itemChosen.productinstanceid]
        newStoreDirectNum = (foundItem.state.storeDirectNum - 1);

        foundItem.state.quantity = newStoreDirectNum+foundItem.state.labDirectNum;
        cartItems[itemChosen.productinstanceid] = {
            data: cartItems[itemChosen.productinstanceid].data,
            state: {
                ...cartItems[itemChosen.productinstanceid].state,
                storeDirectNum: Math.max(newStoreDirectNum, 0)
            }
        }
    

        var backorderinfo = genBackorderinfo(itemChosen.stockavailable,cartItems[itemChosen.productinstanceid].state.quantity,newStoreDirectNum);
        cartItems[itemChosen.productinstanceid].state.customstockmessage = backorderinfo[0];
        cartItems[itemChosen.productinstanceid].state.customstockmessagelab = backorderinfo[1];
    }

    return {...cartItems};
}

export const addLabDirectItem = ({ cartItems }, itemChosen) => {
    if (itemChosen.productinstanceid in cartItems) {
        cartItems[itemChosen.productinstanceid].data.customstockmessagenotify = null;
        let foundItem = cartItems[itemChosen.productinstanceid]
        if(itemChosen.stockavailable >= (Number(foundItem.state.labDirectNum) + 1) || itemChosen.canbackorder){

            var newStoreDirectNum = foundItem.state.storeDirectNum?foundItem.state.storeDirectNum-1:0;
            foundItem.state.quantity = (foundItem.state.labDirectNum + newStoreDirectNum + 1);

            cartItems[itemChosen.productinstanceid] = {
                data: cartItems[itemChosen.productinstanceid].data,
                state: {
                    ...cartItems[itemChosen.productinstanceid].state,
                    labDirectNum: Number(foundItem.state.labDirectNum) + 1,
                    storeDirectNum: newStoreDirectNum
                }
            }
            var backorderinfo = genBackorderinfo(itemChosen.stockavailable,cartItems[itemChosen.productinstanceid].state.quantity,newStoreDirectNum);
            cartItems[itemChosen.productinstanceid].state.customstockmessage = backorderinfo[0];
            cartItems[itemChosen.productinstanceid].state.customstockmessagelab = backorderinfo[1];

        } else {
            cartItems[itemChosen.productinstanceid].data.customstockmessagenotify = stockMessages.ADD_TO_CART_NOT_ENOUGH +itemChosen.stockavailable;
        }
    } else {
        if(itemChosen.stockavailable || itemChosen.canbackorder){
            defaultState.storeDirectNum = 0;
            defaultState.labDirectNum = 1;
            cartItems[itemChosen.productinstanceid] = {
                data: itemChosen,
                state: { ...defaultState }
            }
            var backorderinfo = genBackorderinfo(itemChosen.stockavailable,cartItems[itemChosen.productinstanceid].state.quantity,0);
            cartItems[itemChosen.productinstanceid].state.customstockmessage = backorderinfo[0];
            cartItems[itemChosen.productinstanceid].state.customstockmessagelab = backorderinfo[1];
            cartItems[itemChosen.productinstanceid].data.customstockmessagenotify = null;
        } else {
            cartItems[itemChosen.productinstanceid].data.customstockmessagenotify = stockMessages.ADD_TO_CART_NOT_ENOUGH+itemChosen.stockavailable;
        }
    
    }
    return {...cartItems};
}

export const minusLabDirectItem = ({ cartItems }, itemChosen) => {

    var newStoreDirectNum = 0;

    if (itemChosen.productinstanceid in cartItems) {
        const foundItem = cartItems[itemChosen.productinstanceid]

        cartItems[itemChosen.productinstanceid].data.customstockmessagenotify = null;

        newStoreDirectNum = Math.max(Number(foundItem.state.storeDirectNum) + 1,0);
        cartItems[itemChosen.productinstanceid] = {
            data: cartItems[itemChosen.productinstanceid].data,
            state: {
                ...cartItems[itemChosen.productinstanceid].state,
                labDirectNum: Math.max(Number(foundItem.state.labDirectNum) - 1, 0),
                storeDirectNum: newStoreDirectNum
            }
        }

        var backorderinfo = genBackorderinfo(itemChosen.stockavailable,cartItems[itemChosen.productinstanceid].state.quantity,newStoreDirectNum);
        cartItems[itemChosen.productinstanceid].state.customstockmessage = backorderinfo[0];
        cartItems[itemChosen.productinstanceid].state.customstockmessagelab = backorderinfo[1];
    }
    return {...cartItems};
}

export const removeProduct = ({ cartItems }, itemChosen) => {
    if (itemChosen.productinstanceid in cartItems) {
        const foundItem = cartItems[itemChosen.productinstanceid]
        if(Number(foundItem.state.storeDirectNum) + Number(foundItem.state.labDirectNum)){
            foundItem.state.quantity = 0;
            cartItems[itemChosen.productinstanceid] = {
                data: cartItems[itemChosen.productinstanceid].data,
                state: {
                    ...cartItems[itemChosen.productinstanceid].state,
                    labDirectNum: 0,
                    storeDirectNum: 0
                }
            }
            cartItems[itemChosen.productinstanceid].state.customstockmessage = null;
            cartItems[itemChosen.productinstanceid].state.customstockmessagelab = null;
            cartItems[itemChosen.productinstanceid].data.customstockmessagenotify = null;
        } else {
            delete cartItems[itemChosen.productinstanceid];
        }
    }
    return {...cartItems};
}

export const setJobNum = ({ cartItems }, item) => {
    const { itemChosen, jobNum } = item;
    if (itemChosen.productinstanceid in cartItems) {
        cartItems[itemChosen.productinstanceid] = {
            data: cartItems[itemChosen.productinstanceid].data,
            state: {
                ...cartItems[itemChosen.productinstanceid].state,
                jobNum: jobNum
            }
        }
    }
    return {...cartItems};
}

export const setReference = ({ cartItems }, item) => {
    const { itemChosen, reference } = item;
    if (itemChosen.productinstanceid in cartItems) {
        cartItems[itemChosen.productinstanceid] = {
            data: cartItems[itemChosen.productinstanceid].data,
            state: {
                ...cartItems[itemChosen.productinstanceid].state,
                reference: reference
            }
        }
    }
    return {...cartItems}
}

export const setLabChecked = ({ cartItems }, item) => {
    const { itemChosen, checked } = item;
    if (itemChosen.productinstanceid in cartItems) {
        const foundItem = cartItems[itemChosen.productinstanceid]
        let resets = {}
        if (!checked) {
            resets = {
                labDirectNum: 0,
                storeDirectNum: foundItem.state.quantity
            }
        }
        cartItems[itemChosen.productinstanceid] = {
            data: cartItems[itemChosen.productinstanceid].data,
            state: {
                ...cartItems[itemChosen.productinstanceid].state,
                ...resets,
                labDirectEnabled: checked,
            }
        }
    }
    return {...cartItems}
}




