import React from 'react';
import { Link } from "react-router-dom";
import { Box,Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    unitContainer: {
        width: "100%",
        marginTop: "20px",
        marginBottom: "20px"
    },
    unit: {
        margin: "auto",
        textAlign: "center",
        width: "100%",
        fontSize: "0.875em",
        fontWeight: "bold",
        '& a': {
            color: '#666'
        }
    }
})
export const ManagerUnit = ({name, phoneNumber, phoneNumberLink, email}) => {
    const classes = useStyles()
    return(
        <Box className={classes.unitContainer}>
            <Typography className={classes.unit}>{name.toUpperCase()}</Typography>
            <Typography className={classes.unit}><a href={"tel:" + phoneNumberLink}>{phoneNumber}</a></Typography>
            <Typography className={classes.unit}><a href={"mailto:" + email}>{email}</a></Typography>
        </Box>
    )
}