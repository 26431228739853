import { initialState } from "./user.state";
import { types } from "./user.mutations";
import { authStates } from "../../settings";
import { set, get , cloneDeep} from 'lodash';
import { addItemToWishList, removeFromWishList, updateWishlistVals, applyUserBrandDiscountsUser } from "./user.utils"

const original = cloneDeep(initialState)

function addDateObjs(alldata){

  for(var i = 0, k = alldata.length; i<k; i++){

    alldata[i].data.order.dateobj = createdateobj(alldata[i].data.order.date);

  }
  return alldata;
}
function dateAMPMadjust(thisdate,isam){

  if(isam){
    switch(thisdate.getHours()){
      case 12:
        thisdate.setHours(0);
      break;
      default:
        break;
    }
  }
  if(!isam){
    switch(thisdate.getHours() < 12){
      case true:
        thisdate.setHours(thisdate.getHours()+12);
      break;
      default:
        break;
    }
  }
  return thisdate;
}

function createdateobj(datestr){
  var datereg = /(st|nd|th|rd|am|pm)*/gi,
  isam = datestr.indexOf('pm')<0;

  datestr = datestr.replace(datereg,'');

  var dateobj = new Date(datestr);

  dateobj = dateAMPMadjust(dateobj,isam);

  return dateobj;
}

function customdatesort(a,b){
    var datea = a.data.order.dateobj,
    dateb = b.data.order.dateobj;
  return dateb - datea;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.IS_FETCHING_DATA: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    case types.NOT_FETCHING_DATA: {
      return Object.assign({}, state, {
        isFetching: false
      });
    }
    case types.SET_PAST_ORDERS: {
      var data = {...state};

      action.data = addDateObjs(action.data);
      action.data.sort(customdatesort);

      data["pastOrders"] = action.data
      return data
    }
    case types.SET_ORDER_HISTORY_COUNT: {
      var data = {...state};

      //action.data = addDateObjs(action.data);
      //action.data.sort(customdatesort);

      data["orderHistoryCount"] = action.data.total
      return data
    }
    case types.ADD_TO_WISHLIST:
      var data = {...state};
      data["user"]["data"]["wishList"] = cloneDeep(addItemToWishList(get(state,"user.data.wishList", {}) , action.data));
      return data;
    case types.REMOVE_FROM_WISHLIST:
      var data = {...state};
      data["user"]["data"]["wishList"] = cloneDeep(removeFromWishList(get(state,"user.data.wishList", {}), action.data));
      return data;
    case types.UPDATE_VALUES_WISHLIST:
      var data = {...state};
      data["user"]["data"]["wishList"] = updateWishlistVals(action.payload, action.checks);
      return data;
    case types.UPDATE_PRODUCTS_BRAND_DISCOUNTS_USER:
          data = {...state};
          applyUserBrandDiscountsUser(action.userState, action.productState);
          return data;
    case types.GET_DATA_FROM_DB_SUCCESS: {
      var data = { ...state };
      data["user"]["data"] = cloneDeep(action.data);
      data["user"]["cache"] = cloneDeep(action.data);
      data["user"]["error"] = {};
      return data;
    }
    case types.GET_DATA_FROM_DB_FAILED: {
      var data = { ...state };
      data["user"]["error"] = action.data;
      return data;
    }
    case types.UPDATE_USER_INFO + action.type.slice(types.UPDATE_USER_INFO.length): {
      var data = { ...state};
      data["user"]["data"] = set(cloneDeep(data["user"]["data"]), action.data.location,action.data.state)
      return data;
    }
    case types.RESET_USER_DATA: {
      var data = {...state}
      data["user"] = {
        ...data["user"],
        data: cloneDeep(data["user"]["cache"])
      }
      return data
    }
    case types.SET_MODAL_OPEN: {
      var data = { ...state };
      data["modal"]["open"] = action.data;
      data["auth"]["error"] = {};
      return data;
    }
    case types.UPDATE_MODAL_STATUS: {
      var data = { ...state };
      data["modal"]["state"] = action.data;
      return data;
    }
    case types.SIGN_IN_SUCCESS: {
      var data = { ...state };
      data["auth"]["data"] = action.data;
      data["auth"]["error"] = {};
      return data;
    }
    case types.SIGN_IN_FAILED: {
      var data = { ...state };
      data["auth"]["error"] = action.data;
      data["modal"]["state"] = authStates.LOGIN_FAILED;
      return data;
    }
    case types.SIGN_OUT_SUCCESS: {
      var data = { ...state };
      data["auth"]["data"] = action.data;
      data["auth"]["error"] = {};
      data["modal"]["state"] = authStates.SIGN_OUT_SUCCESS;
      data["user"] = original.user
      return data;
    }
    case types.SIGN_OUT_FAILED: {
      var data = { ...state };
      data["auth"]["error"] = action.data;
      data["modal"]["state"] = authStates.SIGN_OUT_FAILED;
      return data;
    }
    case types.SET_SINGLE_COURIER: {
      var data = { ...state };
      data["user"]["data"]["courier"] = action.payload;
      return data;
    }
    case types.FORGOT_PASSWORD_SUBMITTED: {
      var data = { ...state };
      data["auth"]["data"] = action.data;
      data["auth"]["error"] = {};
      data["modal"]["state"] = authStates.FORGOT_PASSWORD_SUBMIT_SUCCESS;
      return data;
    }
    case types.FORGOT_PASSWORD_SUBMIT_FAILED: {
      var data = { ...state };
      data["auth"]["error"] = action.data;
      data["modal"]["state"] = authStates.FORGOT_PASSWORD_SUBMIT_FAILED;
      return data;
    }
    case types.FORGOT_PASSWORD_COMPLETED: {
      var data = { ...state };
      data["auth"]["data"] = action.data;
      data["auth"]["error"] = {};
      data["modal"]["state"] = authStates.FORGOT_PASSWORD_COMPLETED_SUCCESS;
      return data;
    }
    case types.FORGOT_PASSWORD_COMPLETE_FAILED: {
      var data = { ...state };
      data["auth"]["error"] = action.data;
      data["modal"]["state"] = authStates.FORGOT_PASSWORD_COMPLETED_FAILED;
      return data;
    }
    case types.CHANGE_PASSWORD_SUCCESS: {
      var data = { ...state };
      data["auth"]["data"] = action.data;
      data["auth"]["error"] = {};
      data["modal"]["state"] = authStates.CHANGE_PASSWORD_SUCCESS;
      return data;
    }
    case types.CHANGE_PASSWORD_FAILED: {
      var data = { ...state };
      data["auth"]["error"] = action.data;
      data["modal"]["state"] = authStates.CHANGE_PASSWORD_FAILED;
      return data;
    }
    case types.CREATE_ORDER_ERROR: {
      return {
        ...state,
        pending: false,
        error: action.error
      };
    }
    case types.CREATE_ORDER_PENDING: {
      return {
        ...state,
        pending: true
      };
    }
    case types.CREATE_ORDER_SUCCESS: {
      return {
        ...state,
        pending: false,
        order: action.order
      };
    }
    default: {
      return state;
    }
  }
};
