export const types = {
  CREATE_ORDER_PENDING: "CREATE_ORDER_PENDING"
  ,CREATE_ORDER_SUCCESS: "CREATE_ORDER_SUCCESS"
  ,CREATE_ORDER_ERROR: "CREATE_ORDER_ERROR"
  ,SIGN_IN_SUCCESS: "USER_SIGN_IN_SUCCESS"
  ,SIGN_IN_FAILED: "USER_SIGN_IN_FAILED"
  ,SIGN_OUT_SUCCESS: "USER_SIGN_OUT_SUCCESS"
  ,SIGN_OUT_FAILED: "USER_SIGN_OUT_FAILED"
  ,IS_FETCHING_DATA: "USER_IS_FETCHING_DATA"
  ,NOT_FETCHING_DATA: "USER_NOT_FETCHING_DATA"
  ,CHANGE_PASSWORD: "USER_CHANGE_PASSWORD"
  ,CHANGE_PASSWORD_SUCCESS: "USER_CHANGE_PASSWORD_SUCCESS"
  ,CHANGE_PASSWORD_FAILED: "USER_CHANGE_PASSWORD_FAILED"
  ,FORGOT_PASSWORD_SUBMITTED: "USER_FORGOT_PASSWORD_SUBMITTED"
  ,FORGOT_PASSWORD_SUBMIT_FAILED: "USER_FORGOT_PASSWORD_SUBMIT_FAILED"
  ,FORGOT_PASSWORD_COMPLETED: "USER_FORGOT_PASSWORD_COMPLETED"
  ,FORGOT_PASSWORD_COMPLETE_FAILED: "USER_FORGOT_PASSWORD_COMPLETE_FAILED"
  ,REGISTER_SUCCESS: "USER_REGISTER_SUCCESS"
  ,REGISTER_FAILED: "USER_REGISTER_FAILED"
  ,UPDATE_MODAL_STATUS: "USER_UPDATE_MODAL_STATUS"
  ,SET_MODAL_OPEN: "USER_SET_MODAL_OPEN"
  ,GET_DATA_FROM_DB_SUCCESS: "CALL_DB_SUCCESS"
  ,GET_DATA_FROM_DB_FAILED: "CALL_DB_FAILED"
  ,UPDATE_USER_INFO: "UPDATE_USER_INFO_"
  ,RESET_USER_DATA: "RESET_USER_DATA"
  ,UPDATE_USER_INFO_FAILED: "UPDATE_USER_INFO_FAILED"
  ,UPDATE_DEFAULT_BILLING_ADDRESS_SUCCESS: "UPDATE_DEFAULT_BILLING_ADDRESS_SUCCESS"
  ,UPDATE_DEFAULT_BILLING_ADDRESS_FAILED: "UPDATE_DEFAULT_BILLING_ADDRESS_FAILED"
  ,UPDATE_DEFAULT_SHIPPING_ADDRESS_SUCCESS: "UPDATE_DEFAULT_SHIPPING_ADDRESS_SUCCESS"
  ,UPDATE_DEFAULT_SHIPPING_ADDRESS_FAILED: "UPDATE_DEFAULT_SHIPPING_ADDRESS_FAILED"
  ,SET_SINGLE_COURIER: "SET_SINGLE_COURIER"
  ,ADD_TO_WISHLIST: "ADD_TO_WISHLIST"
  ,REMOVE_FROM_WISHLIST: "REMOVE_FROM_WISHLIST"
  ,UPDATE_VALUES_WISHLIST: "UPDATE_VALUES_WISHLIST"
  ,UPDATE_PRODUCTS_BRAND_DISCOUNTS_USER: "UPDATE_PRODUCTS_BRAND_DISCOUNTS_USER"
  ,SET_PAST_ORDERS: "SET_PAST_ORDERS"
  ,SET_ORDER_HISTORY_COUNT: "SET_ORDER_HISTORY_COUNT"
}

export const setPastOrders = data => ({
  type: types.SET_PAST_ORDERS,
  data
})

export const setOrderHistoryCount = data => ({
  type: types.SET_ORDER_HISTORY_COUNT,
  data
})

export const addToWishList = data => ({
  type: types.ADD_TO_WISHLIST,
  data
});

export const removeFromWishList = data => ({
  type:types.REMOVE_FROM_WISHLIST,
  data
});

export const updateWishlistValues = (items,checkitems) => ({
  type: types.UPDATE_VALUES_WISHLIST,
  payload: items,
  checks: checkitems
});

export function updateProductBrandDiscountsUser(userState, productState) {
  return {
      type: types.UPDATE_PRODUCTS_BRAND_DISCOUNTS_USER,
      userState: userState,
      productState: productState
  }
}



export const isFetchingData = () => ({
  type: types.IS_FETCHING_DATA
});

export const notFetchingData = () => ({
  type: types.NOT_FETCHING_DATA
});

export const updateUserData = (location,state) => ({
  type: types.UPDATE_USER_INFO + location.slice(0, 2).join('_').toUpperCase(),
  data: {
    location,
    state
  }
});

export const resetUserData = () => ({
  type: types.RESET_USER_DATA
});

export const getUserDataSuccess = data => ({
  type: types.GET_DATA_FROM_DB_SUCCESS,
  data: data
});

export const getUserDataFailed = data => ({
  type: types.GET_DATA_FROM_DB_FAILED,
  data
});

export const signInUserSuccess = data => ({
  type: types.SIGN_IN_SUCCESS,
  data
});

export const signInUserFailed = data => ({
  type: types.SIGN_IN_FAILED,
  data
});

export const signOutUserSuccess = data => ({
  type: types.SIGN_OUT_SUCCESS,
  data
});

export const signOutUserFailed = data => ({
  type: types.SIGN_OUT_FAILED,
  data
});

export const registerUserSuccess = data => ({
  type: types.REGISTER_SUCCESS,
  data
});

export const registerUserFailed = data => ({
  type: types.REGISTER_FAILED,
  data
});

export const setSingleCourier = courier => ({
  type: types.SET_SINGLE_COURIER,
  payload: courier
});

export const changePassword = data => ({
  type: types.CHANGE_PASSWORD,
  data
});

export const changePasswordFailed = data => ({
  type: types.CHANGE_PASSWORD_FAILED,
  data
});

export const changePasswordSuccess = data => ({
  type: types.CHANGE_PASSWORD_SUCCESS,
  data
});

export const forgotPasswordSubmitted = data => ({
  type: types.FORGOT_PASSWORD_SUBMITTED,
  data
});

export const forgotPasswordSubmitFailed = data => ({
  type: types.FORGOT_PASSWORD_SUBMIT_FAILED,
  data
});

export const forgotPasswordCompleted = data => ({
  type: types.FORGOT_PASSWORD_COMPLETED,
  data
});

export const forgotPasswordCompleteFailed = data => ({
  type: types.FORGOT_PASSWORD_COMPLETE_FAILED,
  data
});

export const setModalStatus = data => ({
  type: types.UPDATE_MODAL_STATUS,
  data
});

export const setModalOpen = data => ({
  type: types.SET_MODAL_OPEN,
  data
});

export function createOrderPending() {
  return {
    type: "CREATE_ORDER_PENDING"
  };
}

export function createOrderSuccess(data) {
  return {
    type: "CREATE_ORDER_SUCCESS",
    order: data
  };
}

export function createOrderFailure(error) {
  return {
    type: "CREATE_ORDER_ERROR",
    error: error
  };
}

export const mutation = {
  isFetchingData
};
