import React, { useState, useRef, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Link, Grid, Hidden, Button, Typography, Box, Badge } from "@material-ui/core";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { makeStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import { StyledMenu, StyledMenuItem } from "../componentStyles";

import { authStates } from "../../settings";

const useStyles = makeStyles(theme => ({
  // textButton: {
  //   textAlign: "center",
  //   verticalAlign: "center",
  //   fontSize: 15,
  //   margin: "0 auto",
  //   height: "100%",
  //   color: "#101212",
  //   width: "130px",
  //   fontWeight: "fontWeightBold",
  //   backgroundColor: "#fff",
  //   "&:hover": {
  //     backgroundColor: "#009AC7",
  //     color: "#fff"
  //   }
  // },
  // textButtonBlue: {
  //   border: "1px solid #1e97ce",
  //   backgroundColor: "#1e97ce",
  //   color: "#fff",
  //   width: "130px",
  //   '&:hover': {
  //     color: '#1e97ce', // replace with your desired hover text color
  //     backgroundColor: '#fff', // replace with your desired hover background color
  //   },
  // },
  // iconButton: {
  //   textDecoration: "inherit",
  //   color: "gray",
  //   "&:active": {
  //     border: "none !important"
  //   }
  // }
}));

function LoginHeader(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const open = Boolean(anchorEl);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = async () => {
    setAnchorEl(null);
    props.logOut();
  };

  const handleLogIn = loginType => {
    setAnchorEl(null);
    props.handleOpenModal(loginType);
  };

  useEffect(() => {}, [props.isLoggedIn]);

  if(props.isLoggedIn ){

    //add class userloggedin to body
    document.body.classList.add('userloggedin');

    return (
      <div className="headerbuttons headerbuttons-loggedin">
          <div className="btn-headerbuttons btn-headerbuttons-logout" onClick={handleLogOut}>Logout</div>
            <a className="accountLinkIcon" href="/account">
              <AccountCircle />
              </a>

              <a className="cartLinkIcon" href="/cart">
              <Badge badgeContent={props.itemCount} max={9} color="secondary">
                        <ShoppingCartOutlinedIcon />
              </Badge>
              </a>

                
       </div>
    );

  } else {

    //add class userloggedin to body
    document.body.classList.remove('userloggedin');

    return (
      <div className="headerbuttons">
        <div className="btn-headerbuttons btn-headerbuttons-login" onClick={() => handleLogIn(authStates.LOGIN)}>Login</div>
        <div className="btn-headerbuttons btn-headerbuttons-register" onClick={() => handleLogIn(authStates.SIGNUP)}>Register</div>
      </div>
    );

  }
  
}

export default withRouter(LoginHeader);
