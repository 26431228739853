import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import { ButtonBase, Typography, Grid ,Box} from '@material-ui/core';
import { Link } from './Link';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    item: {
        gridGap: theme.spacing(3),
    },
    content: {
        textAlign: 'center',
        '&:hover, &$focusVisible': {
            zIndex: 1,
            '& $imageBackdrop': {
                opacity: 0.1,
            },
            '& $imageMarked': {
                opacity: 0,
            },
            // '& $imageTitle': {
            //     border: '4px solid currentColor',
            // },
        },
    },
    focusVisible: {},
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: '60px',
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'center',
        color: theme.palette.common.black,
        fontFamily: "Termina,sans-serif",
        [theme.breakpoints.up('md')]: {
            '@media (max-width: 1640px)': {
                bottom: '45px',
            },
            '@media (max-width: 1500px)': {
                bottom: '30px',
            },
            '@media (max-width: 1279px)': {
                bottom: '45px',
            },
            '@media (max-width: 1080px)': {
                bottom: '30px',
            },

        },
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0,
        transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(1) + 6}px`,
        fontWeight: 'bold !important',
        textAlign: 'center',
        color: '#000',
        fontFamily: "Termina,sans-serif !important",
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.black,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },
});



class FeaturedButton extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dimensions: {
            }
        }
        this.image = React.createRef()
    }





    render() {
        const { classes } = this.props;
        let lenMD = 12 / this.props.options.numItems ;
        let lenXS = Math.min(lenMD * 2, 12);
        if (lenXS >= 8 && lenXS < 12) {
            lenXS = 12;
        }
        if(this.props.direction === "column") {
            lenXS = 12
            lenMD = 12
        }
        return (

            <Grid 
                key={this.props.indx} 
                item 
                xs={lenXS} 
                md={lenMD} 
                className={classes.item}
                align="center"
                >
                <ButtonBase
                    component={'a'}
                    href={this.props.link}
                    focusRipple
                    className={classes.content}
                    focusVisibleClassName={classes.focusVisible}
                    style={{
                        width: "100%"
                    }}

                >
                    <img
                        ref={this.image}
                        src={this.props.img}
                    />

                    <span className={classes.imageBackdrop} />

                    {this.props.title &&
                        <span className={classes.imageButton}>
                            <Typography
                                component="span"
                                variant="subtitle1"
                                color="inherit"
                                className={classes.imageTitle}
                            >
                                {this.props.title}
                                <span className={classes.imageMarked} />
                            </Typography>
                        </span>
                    }
                </ButtonBase>
            </Grid>

        )
    }
}

class FeaturedLinks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {
                numItems: 1,
                totalWidth: 1,
                evenWidth: 1,
                minHeight: 1
            }
        };
        this.myInput = React.createRef()
    }

    componentDidMount() {
        const numItems = this.props.featuredStubs.length;
        const totalWidth = this.myInput.current.offsetWidth;
        const evenWidth = totalWidth / numItems;

        this.setState({
            ...this.state, options: {
                numItems,
                totalWidth,
                evenWidth,
                minHeight: this.props.minImageHeight
            }
        });
    }


    render() {


        return (
            <Box className={this.props.classes.root} >
                <Grid
                    container
                    spacing={1}
                    justify={this.props.justify || "space-around"}
                    direction={this.props.direction || "row"}
                    alignItems="center"
                    className={this.props.classes.container}
                    ref={this.myInput}
                >
                    {this.props.featuredStubs.map((item, indx) => (
                        <FeaturedButton
                            key={indx}
                            link={item.link}
                            img={item.img}
                            title={item.title}
                            indx={indx}
                            direction={this.props.direction|| "row"}
                            classes={this.props.classes}
                            options={this.state.options}
                        />
                    ))}
                </Grid>
            </Box >
        )
    }
}


export default withStyles(styles)(FeaturedLinks);