//@ts-check

export const stepperFilter = encodeURIComponent(JSON.stringify({
    "subbrand":{
        "type":"in",
        "value":[
           // "E",
          //  "FU",
          //  "FUA",
            "SI",
            //"SA",
            "STS"
        ]
    }
}))

export const stepperKFilter = encodeURIComponent(JSON.stringify({
    "subbrand":{
        "type":"in",
        "value":[            
            "SK"
        ]
    },
    "genderdescription":{
        "type":"in",
        "value":[
            "Child"
        ]
    },
}))

export const convertiblesFilter = encodeURIComponent(JSON.stringify({
    "subbrand":{
        "type":"in",
        "value":[
            "CON",
            "CONTI"
        ]
    }
}))

export const getMicroNavigationPaths = () => ({
       // aboutUs: "/aboutus",
        //contactUs: "/contactus",
        findAStockist: "/findAStockist",
        becomeastockist: "/becomeastockist",    
        shop: `/shop`,    
        returns: "/returns",    
       // faq:"/faq",
        //delivery:"/delivery",
        //terms:"/terms",
        forms:"/forms",
        //privacy:"/privacy",
        //marketingSupport: "/marketingSupport",
        stepperPromo: `/shop/stepperPromo`,
        convertiblesPromo: `/shop/convertiblesPromo`,
        stepperK: `/stepperk`
    })
export const getNavigationPaths = () => ({
    //aboutUs: "/aboutus",
    contactUs: "/contactus",
    findAStockist: "/findAStockist",
    becomeastockist: "/becomeastockist",
   // blog: "/blog",
    marketingSupport: "/marketingSupport",
    shop: "/shop",
    clearance: "/clearance",
    spareParts: "/spareParts",
    newsLetter: "/newsLetter",
    //returns: "/returns",
    //brands: "/brands",
    //accessories: "/accessories",
   // faq:"/faq",
    //delivery:"/delivery",
    //terms:"/terms",
    forms:"/forms",
    //privacy:"/privacy",
    component:"/component",
    account:"/account",
    productDetail:"/productDetail",
    cart:"/cart",
    newsletter:"/newsletter",
    order: '/order',
    territoryManagers: '/territoryManagers',
    authOnly: '/AuthorizedUsersOnly',
  //  brandsStepper: "/brands/Stepper",
   // brandsConvertibles: "/brands/Convertibles",
   // brandsBeat: "/brands/Beat",
    //brandsZeiss: "/brands/Zeiss",
   // brandsAuraFlex: "/brands/AuraFlex",
  //  brandsGemini: "/brands/Gemini",
//    brandsPaparazzi: "/brands/Paparazzi",
    stepperPromo: `/shop?filter=${stepperFilter}`,
    convertiblesPromo: `/shop?filter=${convertiblesFilter}`,
    stepperK: `/stepperk`
})

export const mapNavigationToExternal = (url,getNav = getMicroNavigationPaths) => {
    const paths = getNav()
    return {
        ...Object.keys(paths).reduce((out, x) => {
            out[paths[x]] = `${url}${paths[x]}`
            return out
        }, {}),
        '/shop/stepperPromo': `${url}/shop?filter=${stepperFilter}`,
        '/shop/convertiblesPromo' : `${url}/shop?filter=${convertiblesFilter}`
    }
} 

export const getNavigationStructure = () => {
    const paths = getNavigationPaths()
    return {
        company: {
            label: "The Company",
            links: {
                "About Us": { label: "About Us", path: "/aboutus" },
                "Contact Us": { label: "Contact Us", path: '/contact-the-nz-team/' },
                "Find a Stockist": { label: "Find a Stockist", path: paths.findAStockist },
                "Blog": { label: "Blog", path: "/blog/" },
                "Marketing Support": { label: "Marketing Support", path: "/marketing-support/" }
            }
        },
        product: {
          label: "The Product",
          links: {
            "Online Shop" : { label: "Online Shop", path: paths.shop },
            "Clearance Items" : { label: "Clearance Items", path: paths.clearance },
            "Spare Parts" : { label: "Spare Parts", path: paths.spareParts }
          }
        },
        brands: { 
            label: "The Brands", 
            links: {
                    "Stepper": { label: "Stepper", path: "/brands/stepper"},
                    "Convertibles": { label: "Convertibles", path: "/brands/convertibles"},
                    "Beat": { label: "Mersey Beat", path: "/brands/mersey-beat"},
                    //"Zeiss": { label: "Zeiss", path: "/brands/zeiss"},
                    "AuraFlex": { label: "AuraFlex", path: "/brands/auraflex"},
                    "Gemini": { label: "Gemini", path: "/brands/gemini"},
                    "Paparazzi": { label: "Paparazzi", path: "/brands/paparazzi"},
                    "Accessories": { label: "Accessories", path: "/accessories"}
            }
        }
    }
}


export const getNavigationExternals = () => {
    const paths = getNavigationPaths();
    return {
        //[paths.blog]: "http://blog.optiqueline.com.au/blog",
        [paths.becomeastockist]: "https://www.optiqueline.co.nz/stockist-request-form",
        [paths.marketingSupport]: "https://www.optiqueline.co.nz/marketing-support",
        [paths.contactUs]: "https://www.optiqueline.co.nz/contact-the-nz-team",
        [paths.newsLetter]: "https://www.optiqueline.co.nz/optique-line-newsletter-subscription",
        [paths.spareParts]: "https://www.optiqueline.co.nz/spare-part-nz-order-form",
        [paths.stepperK]: "https://www.optiqueline.co.nz/shop?filter=%7B%22subbrand%22%3A%7B%22type%22%3A%22in%22%2C%22value%22%3A%5B%22SK%22%5D%7D%2C%22genderdescription%22%3A%7B%22type%22%3A%22in%22%2C%22value%22%3A%5B%22Child%22%5D%7D%7D",
        //[paths.accessories]: "https://www.optiqueline.co.nz/accessories-nz",
        //[paths.brands]: "http://info.optiqueline.com.au/optiquelinebrands",
        //[paths.brandsStepper]:"http://info.optiqueline.com.au/stepper_eyewear",
        //[paths.brandsConvertibles]:"http://info.optiqueline.com.au/convertibles_eyewear",
        //[paths.brandsBeat]:"http://info.optiqueline.com.au/mersey-beat-eyewear",
       // [paths.brandsZeiss]:"http://info.optiqueline.com.au/zeiss-eyewear",
       // [paths.brandsAuraFlex]:"http://info.optiqueline.com.au/auraflex",
       // [paths.brandsGemini]:"http://info.optiqueline.com.au/gemini-eyewear",
      //  [paths.brandsPaparazzi]:"http://info.optiqueline.com.au/paparazzi-eyewear"
    }
}

export const getNavigationStatics = () => {
    const paths = getNavigationPaths()
    return {
       // [paths.aboutUs]:"aboutus",
        [paths.findAStockist]:"findstockist",
        //[paths.faq]:"faq",
        //[paths.delivery]:"delivery",
       // [paths.terms]:"terms",
        [paths.forms]:"forms",
        [paths.privacy]:"privacy",
       // [paths.brandsStepper]:"brands/stepper",
       // [paths.brandsConvertibles]:"brands/convertibles"
       // [paths.returns]:"returns",
    }
}