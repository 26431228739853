export const getFilters = () => ({
    /*"bestsellers": {
        label: "Best Sellers",
        type: 'in',
        filter: {
            "bestsellers": {label: "Best Sellers",value: false}
        }
    },*/
    "genderdescription": {
        label: "Gender",
        type: 'in',
        filter: {
            "Kids": {label: "Kids",value: false},
            "Women": {label: "Women",value: false},
            "Men": {label: "Men",value: false},
            "Unisex": {label: "Unisex",value: false}
            // "Child": {label: "Child",value: false},
            // "Female": {label: "Female",value: false},
            // "Male": {label: "Male",value: false},
            // "Unisex": {label: "Unisex",value: false}
        }
    },
    "shapedescription": {
        label: "Shape",
        type: 'in',
        filter: {
            "Aviator": {label: "Aviator",value: false},
            "Cat Eye": {label: "Cat Eye",value: false},
            "Oval": {label: "Oval",value: false},
            "Square": {label: "Square",value: false},
            "Rectangle": {label: "Rectangle",value: false},
            "Round": {label: "Round",value: false},            
            // "Aviator": {label: "Aviator",value: false},
            // "Circular": {label: "Circular",value: false},
            "Other": {label: "Other",value: false},
            // "Oval": {label: "Oval",value: false},
            // "Rectangle": {label: "Rectangle",value: false},
            // "Square": {label: "Square",value: false}
        }
    },
    "productinstance_eyesize": {
        label: "Eye Size",
        type: 'between',
        filter: {
            "lower": {
                label: "Min",
                limit: 43,
                value: 43
            },
            "upper": {
                label: "Max",
                limit: 65,
                value: 65
            }
        }
    },
    "productinstance_depth": {
        label: "Depth",
        type: 'between',
        filter: {
            "lower": {
                label: "Min",
                limit: 30,
                value: 30
            },
            "upper": {
                label: "Max",
                limit: 50,
                value: 50
            }
        }
    },
    "productinstance_templelength": {
        label: "Temple Length",
        type: 'between',
        filter: {
            "lower": {
                label: "Min",
                limit: 100,
                value: 100
            },
            "upper": {
                label: "Max",
                limit: 160,
                value: 160
            }
        }
    },
    "subbrand": {
        label: "Brand",
        type: 'in',
        filter: {
            //"AURA": {label: "Aura Eyewear",value: false},
            "AURFL": {label: "Aura Flex",value: false},
            "AIKO": {label: "AIKO",value: false},
            "CON": {label: "Convertibles Eyewear",value: false},
            "CONTI": {label: "Convertibles Titanium",value: false},
            "GM": {label: "Gemini Collection",value: false},
            "GE": {label: "Gemini Essentials",value: false},
            //"MAD": {label: "Mad in Italy",value: false},
            "MBE": {label: "Mersey Beat Eyewear",value: false},
            "NOS": {label: "NOS Eyewear",value: false},
            "PAP": {label: "Paparazzi",value: false},
            //"E": {label: "Stepper Eclectic",value: false},
            //"FU": {label: "Stepper Fusion",value: false},
           // "FUA": {label: "Stepper Fusion Asian",value: false},
            "SI": {label: "Stepper SI",value: false},
            "SK": {label: "Stepper SK",value: false},
            "SA": {label: "Stepper SA",value: false},
            "STS": {label: "Stepper STS",value: false}//,
            //"ZEI": {label: "Zeiss",value: false},
            //"ARZ": {label: "Zeiss Architekt",value: false}
        }
    },
    "construction": {
        label: "Construction",
        type: 'in',
        filter: {
            "Full Rim": {label: "Full Rim",value: false},
            "Look Over": {label: "Look Over",value: false},
            "Other": {label: "Other",value: false},
            "Rimless": {label: "Rimless",value: false},
            "Semi Rimless": {label: "Semi Rimless",value: false}
        }
    },
    "material": {
        label: "Material",
        type: 'in',
        filter: {
            "Metal": {label: "Metal",value: false},
            "Plastic": {label: "Plastic",value: false},
            "Titanium": {label: "Titanium",value: false},
            "TX5": {label: "TX5",value: false}
        }
    },
    "productinstance_colourgroup": {
        label: "Colour Group",
        type: 'in',
        filter: {
            "Black": {label: "Black",value: false},
            "Blue": {label: "Blue",value: false},
            "Brown": {label: "Brown",value: false},
            "Burgundy": {label: "Burgundy",value: false},
            // "Copper": {label: "Copper",value: false},
            "Gold": {label: "Gold",value: false},
            "Green": {label: "Green",value: false},
            "Grey": {label: "Grey",value: false},
            "Gunmetal": {label: "Gunmetal",value: false},
            "Multi-colour": {label: "Multi-colour",value: false},
            "Neutral": {label: "Neutral",value: false},
            "Orange": {label: "Orange",value: false},
            "Pink": {label: "Pink",value: false},
            "Purple": {label: "Purple",value: false},
            "Red": {label: "Red",value: false},
            "Silver": {label: "Silver",value: false}
            // "White": {label: "White",value: false}
        }
    }
})

// These Are Filters that will be applied for all queries made from this site
// Use the query format not the Filter formate
export const getStaticFilters = () => ({
    productinstance_ordertype: {
        type: "in",
        value: [
          "available",
          "backordable"
        ]
    }
})

export const getLabDirect = () => ({
"CR LABS": [{ id: "16", region: "VIC" }],
ESSILOR: [
    { id: "1", region: "NSW" },
    { id: "3", region: "QLD" },
    { id: "4", region: "SA" },
    { id: "2", region: "VIC" },
    { id: "5", region: "WA" }
  ],
  HOYA: [
    { id: "6", region: "NSW" },
    { id: "8", region: "QLD" },
    { id: "9", region: "SA" },
    { id: "7", region: "VIC" }
  ],
  OPTICARE: [{ id: "17", region: "NSW" }],
  "OSA LAB": [{ id: "18", region: "NSW" }],
//   "JACK CHAPMAN": [{ id: "13", region: "QLD" }],
  "RODENSTOCK": [{ id: "19", region: "NSW" },{ id: "20", region: "VIC" }],
  SHAMIR: [{ id: "15", region: "QLD" }],
  VSP: [{ id: "14", region: "NSW" }],
  //"PRECISION OPTICS": [{ id: "19", region: "NSW" }],
  //SHAAN: [{ id: "20", region: "QLD" }],
  //"WALLACE AND EVERETT": [{ id: "21", region: "QLD" }],
  //"ADVANCED OPTICAL": [{ id: "22", region: "VIC" }],
  ZEISS: [
    { id: "10", region: "NSW" },
    { id: "11", region: "QLD" },
    { id: "12", region: "SA" }
  ],
  OTHER: [{ id: "23", name: "CUSTOMER TO ADD DETAILS" }]
})

export const getTaxRate = () => 0.10;

export const getTerritoryManagers = () => ([
    {
        region: "VICTORIA",
        managers: [
            {
                name: "JOHN NICOLA",
                phone: "0418 322 658",
                email: "john@optiqueline.com.au"
            },
            {
                name: "ANDREW CRAIB",
                phone: "0400 168 754",
                email: "acraib@optiqueline.com.au"
            },
            {
                name: "ROY PARKER",
                phone: "0435 940 775",
                email: "roy@optiqueline.com.au"
            }
        ]
    },
    {
        region: "TASMANIA",
        managers: [
            {
                name: "ANDREW CRAIB",
                phone: "0400 168 754",
                email: "acraib@optiqueline.com.au"
            }
        ]
    },
    {
        region: "NEW SOUTH WALES",
        managers: [
            {
                name: "BETH BIRCH",
                phone: "0413 837 383",
                email: "beth@optiqueline.com.au"
            },
            {
                name: "DAVID BIRCH",
                phone: "0411 622 848",
                email: "david@optiqueline.com.au"
            }
        ]
    },
    {
        region: "SOUTH AUSTRALIA",
        managers: [
            {
                name: "ROY PARKER",
                phone: "0435 940 775",
                email: "roy@optiqueline.com.au"
            }
        ]
    },
    {
        region: "WESTERN AUSTRALIA",
        managers: [
            {
                name: "ANDREW CRAIB",
                phone: "0400 168 754",
                email: "acraib@optiqueline.com.au"
            }
        ]
    },
    {
        region: "QUEENSLAND & NORTHERN TERRITORY",
        managers: [
            {
                name: "DOUG STABE",
                phone: "0418 782 848",
                email: "doug@optiqueline.com.au"
            },
            {
                name: "JASON HANRAHAN",
                phone: "0414 928 787‬",
                email: "jason@optiqueline.com.au"
            }
        ]
    },
])

