import React from "react";
import { useForm } from 'react-hook-form';
import { getFormValidation } from "./helpers";
import {Typography} from "@material-ui/core"
import HiddenIf from "../HiddenIf";



import {mapOutput} from "./helpers"

export const makeForm = ({FormBody,getUatt,shouldMap=true,prefix=""}) => {
    const validation = {
        getFormValidation: getFormValidation(getUatt)
    }
    return buildOldForm({FormBody, Validation: validation, shouldMap,prefix})
}

export function buildOldForm({FormBody,Validation,shouldMap=true,prefix=""}){
    const config = Validation.getFormValidation(prefix);


    const BuiltForm = ({onSubmit=((d)=>null), data, options={}, ...props}) => {
        const form = useForm({
            validationSchema: config.validation,
            ...options
        });

        const handleSuccessfulSubmit = Uatt => data => {
            const output = shouldMap ? mapOutput(data,Uatt) : data
            onSubmit(output)
        }


        return (
            <form onSubmit={form.handleSubmit(handleSuccessfulSubmit(config.Uatt))}>
                <FormBody
                    data={data}
                    validation={form}
                    {...props}
                />
            </form>
        )
    }
    return BuiltForm;
}

export function buildForm({FormBody,Validation,themode='onSubmit'}){


    const BuiltForm = ({onSubmit=((d)=>null), data={}, validationOptions={}, ...props}) => {
        const form = useForm({
            validationSchema: Validation,
            mode: themode,
            ...validationOptions
        });

        const handleSuccessfulSubmit = data => {
            onSubmit(data)
        }
        console.log(form.errors)
        return (
            <form onSubmit={form.handleSubmit(handleSuccessfulSubmit)}>
                <FormBody
                    data={data}
                    validation={form}
                    {...props}
                />
            </form>
        )
    }
    return BuiltForm;
}

export const FieldError = ({error}) => (
    <HiddenIf value={typeof error !== "undefined" }>
        <Typography variant="subtitle2" style={{color: "red", fontSize: "15px"}}>{(error || {}).message}</Typography>
    </HiddenIf>
)