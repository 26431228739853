import API from "@aws-amplify/api";
import Auth from "@aws-amplify/auth";
import { getUserEmail } from "./auth"

// import Storage from "@aws-amplify/storage";

const API_NAME = "amplifyOptiquelineApi";

export const getCurrentUserId = async () => {
  const auth = await Auth.currentAuthenticatedUser();
  return auth.attributes.sub;
};

export const getAuthToken = async () => {
  const session = await Auth.currentSession()
  return `Bearer ${session.getIdToken().getJwtToken()}`
}




const _authorizationHeaders = async () => ({
  // Authorization: store.state.login.authToken ? store.state.login.authToken : "",
  "Content-Type": "application/json",
 // Authorization: await getAuthToken().catch((err) => 'UNAUTH' ),
});

const handleError = (url,err) => {
  console.error(`Api call error to [${url}]` , err);
  throw err;
};



export const getRequest = async (url, headers = _authorizationHeaders()) => {
  let request = {
    headers: await headers
  };
  try {
    return await API.get(API_NAME, url, request);
  } catch (err) {
    handleError(url,err);
  }
};

export const uploadFile = async (
  file_name,
  data,
  contentType,
  progress = update => {}
) => {
  console.debug("Uploading File ", file_name);
  return await Storage.put(file_name, data, {
    level: "protected",
    contentType: JSON.stringify(contentType),
    progressCallback(update) {
      console.info(`Progress: ${update.loaded}/${update.total}`);
      progress(progress);
    }
  });
};

export const downloadFile = async fileName => {
  var image = "";
  await Storage.get(fileName, { expires: 86400 })
    .then(result => {
      image = result;
    })
    .catch(err => handleError(err));
  return image;
};

export const getListRequest = async (
  url,
  headers = _authorizationHeaders()
) => {
  let request = {
    headers: await headers
  };
  try {
    return await API.get(API_NAME, url, request);
  } catch (err) {
    handleError(url,err);
  }
};

export const postRequest = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  let request = {
    body: data,
    headers: await headers
  };
  try {
    let response = await API.post(API_NAME, url, request);
    //console.log("I AM Response " + JSON.stringify(response));
    return response;
  } catch (err) {
    handleError(url,err);
  }
};

export const putRequest = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  let request = {
    body: data,
    headers: await headers
  };
  try {
    let response = await API.put(API_NAME, url, request);
    return response;
  } catch (err) {
    handleError(url,err);
  }
};

export const deleteRequest = async (url, headers = _authorizationHeaders()) => {
  let request = {
    headers: await headers
  };
  try {
    return await API.del(url, API_NAME, request);
  } catch (err) {
    handleError(url,err);
  }
};

export const Api = {
  postRequest,
  putRequest,
  getCurrentUserId,
  getListRequest,
  getRequest,
  downloadFile
};
