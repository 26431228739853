
import * as mutations from './products.mutations';
import { INITIAL_STATE } from './products.state';
import { mapQueryToFilter, applyUserBrandDiscounts } from './products.utils';
import { omitBy, cloneDeep, set , get} from 'lodash';
import { getFilters } from '../../microSiteConfig'
import { mutation } from '../user/user.mutations';
import userReducer from '../user/user.reducer';
import { ConsoleLogger } from '@aws-amplify/core';




const productsReducer = (state = INITIAL_STATE, action) => {
    let data = {}
    switch (action.type) {
        case mutations.SET_STATIC_FILTERS:
            data = { ...state }
            data['query']['statics'] = cloneDeep({ ...data['query']['statics'], ...action.data })
            return data
        case mutations.UNSET_STATIC_FILTERS:
            data = { ...state }
            data['query']['statics'] = omitBy(cloneDeep(data['query']['statics']), (v, key) => action.data.includes(key));
            return data
        case mutations.FETCH_PRODUCTS_PENDING:
            return {
                ...state,
                pending: true
            }
        case mutations.FETCH_PRODUCTS_SUCCESS:
            data = { ...state }
            data['pending'] = false
            data['product'] = action.payload.data
            data['metadata'] = { ...data['metadata'], ...action.payload.metadata }
            return data
        case mutations.UPDATE_PRODUCTS_BRAND_DISCOUNTS:
            data = {...state};
            data["product"]["product"] = applyUserBrandDiscounts(action.userState, data["product"]);
            //console.log('userReducer',getState().userReducer);
            return data
        case mutations.FETCH_PRODUCTS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.data
            }
        case mutations.SET_FILTERS:
            data = { ...state }
            const lookup = [action.data.group,'filter',action.data.key, 'value'].join('.')
            data['filters'] = set(cloneDeep(data['filters']),lookup,action.data.value)
            return data
        case mutations.RESET_FILTERS:
            data = { ...state }
            data['filters'] = getFilters()
            data['metadata']['search'] = ""
            data['metadata']['orderby'] = 0
            return data
        case mutations.SET_ORDER:
            data = { ...state }
            data['metadata']['orderby'] = action.data.index
            return data
        case mutations.SET_LIMIT:
            data = { ...state }
            data['metadata'] = {
                ...data['metadata'],
                limit: action.data
            }
            return data
        case mutations.SET_PAGINATION:
            data = { ...state }
            data['metadata'] = {
                ...data['metadata'],
                offset: action.data
            }
            return data
        case mutations.SET_SEARCH:
            data = { ...state }
            data['metadata'] = { ...data['metadata'], search: action.data ? action.data.value : "" }
            return data
        case mutations.FILL_FILTER:
            data = {...state}
            const {filter,meta} = mapQueryToFilter(action.data,data.filters,data.metadata)
            data['filters'] = cloneDeep(filter)
            data['metadata'] = cloneDeep(meta)
            return data
        default:
            return state;
    }
}

export default productsReducer;