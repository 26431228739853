import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { selectedCartItemsCount } from '../../redux/cart/cart.selectors';
import { selectLogoInstance } from '../../redux/cms/cms.selectors';
import { AppBar, Toolbar, Box, Grid, Button, ButtonGroup, Hidden, useScrollTrigger, Badge, IconButton, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Link, withRouter } from "react-router-dom";
import { getURL, getNavigationPaths, getNavigationStructure, getAssets, getTheme } from '../../microSiteConfig';

import SideNav from './SideNav';


import LoginModal from './LoginModal';
import LoginModalHeader from './LoginModalHeader';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';

import Search from './Search';
import SearchV2 from './SearchV2';
import { createStructuredSelector } from 'reselect';
import HiddenIf from "../HiddenIf";
import { ForEach } from "../ForEach"
import DropDownMenu from "./DropDownMenu";
import MainMenu from "./MainMenu";

const ENDPOINTS = getNavigationPaths()
const structure = getNavigationStructure()
const MICROTHEME = getTheme();
const config = getAssets();

const useStyles = makeStyles(theme => ({
    offset: theme.mixins.toolbar,
    logowrapper: theme.logowrapper
}))

//function to listen for hover event on li.pbmeganav and if occurs then add class "hoveropen" to body and remove it when hover ends
function hoverOpen() {
    var body = document.querySelector('body');
    var pbmeganav = document.querySelectorAll('li.pbmeganav');
    for (var i = 0; i < pbmeganav.length; i++) {
        pbmeganav[i].addEventListener('mouseover', function () {
            body.classList.add('hoveropen');
        });
        pbmeganav[i].addEventListener('mouseout', function () {
            body.classList.remove('hoveropen');
        });
    }
}

function mobileSubnav() {
    var subnavtoggle = document.querySelectorAll('.subnavtoggle');
    for (var i = 0; i < subnavtoggle.length; i++) {
        subnavtoggle[i].addEventListener('click', function () {
            this.parentNode.classList.toggle('subnavopen');
        });
    }
}



function handleMenuToggle() {
    //set body class to "navopen" when menu is open and remove it when menu is closed
    var body = document.querySelector('body');
    body.classList.toggle('navopen');
};


function AppNavBarV2(props) {
    hoverOpen();
    mobileSubnav();
    const classes = useStyles();
    const currURL = getURL();
    const isBatchOfSites1 = (currURL === "www.auraeyewear.com.au" 
  || currURL === "www.geminieyewear.com.au" || currURL === "www.merseybeateyewear.com.au" || currURL === "www.convertibleseyewear.com");
    const isMicroSiteAny = (currURL === "www.steppereyewear.com.au" || isBatchOfSites1);

    const stepperFilter = encodeURIComponent(JSON.stringify({
        "subbrand":{
            "type":"in",
            "value":[
              //  "E",
               // "FU",
              //  "FUA",
                "SI",
                "SA",
                "STS",
                "SK"
            ]
        }
    }));

  //  http://www.steppereyewear.com.au:3000/shop?%7B%22subbrand%22%3A%7B%22type%22%3A%22in%22%2C%22value%22%3A%5B%22SI%22%2C%22SA%22%2C%22STS%22%2C%22SK%22%5D%7D%7D

    
    return (
        <>
            {!isMicroSiteAny ?
            <header className="mainsite">
                <div className="headercol headercol-logo">
                    <a href="/" className="mainlogolink" title="Optique Line">
                    <img className="mainlogo" src="https://d3i7dklp9u03xa.cloudfront.net/wp-media/uploads/2024/01/optiqueline_logo_V2.png" width="256" height="39" alt="Optique Line" />
                    </a>
                </div>
                <div className="headercol headercol-nav">
                    <ul className="mainsitenav">
                        <li className="pbmeganav subnavopen"><span className="subnavtoggle"></span>
                            <span className="meganavtrigger">Our Brands</span>
                            <div className="pbmeganav-content">
                                <ul className="mainsitenav-subnav">
                                    <li><a href="/brands/stepper">Stepper Eyewear</a></li>
                                    <li><a href="/brands/aiko-eyewear">AIKO Eyewear</a></li>
                                    <li><a href="/brands/auraflex">Auraflex Eyewear</a></li>
                                    <li><a href="/brands/convertibles">Convertibles Eyewear</a></li>
                                    <li><a href="/brands/gemini">Gemini Eyewear</a></li>
                                    <li><a href="/brands/nos">NOS Eyewear</a></li>
                                    <li><a href="/brands/paparazzi">Paparazzi Collection</a></li>
                                    <li><a href="/custom-accessories">Custom Accessories</a></li>
                                </ul>
                            </div>
                            <div className="megamenu-featured">
                                    <h3><a href="/marketing-support">Marketing Support</a></h3>
                                    <a href="/marketing-support"><img src="https://d3i7dklp9u03xa.cloudfront.net/wp-media/uploads/2024/01/ol-new-mega-nav-marketing.jpg" alt="Marketing Support" /></a> 
                            </div>
                            <div className="megamenu-background-wrapper">
                                
                            </div>
                            <div className="megamenu-background"></div>
                        </li>
                        <li className="pbmeganav"><span className="subnavtoggle"></span>
                            <a href="/shop"><span className="navlargeonly">Online </span>Catalogue</a>
                            <div className="pbmeganav-content">
                                <ul className="mainsitenav-subnav">
                                    <li><a href="/shop">Online Catalogue</a></li>
                                    <li><a href="/spare-part-order-form">Spare Parts</a></li>
                                    <li><a href="/custom-accessories">Custom Accessories</a></li>
                                    <li><a href="/clearance"><strong>NOW ON SALE</strong></a></li>
                                    
                                </ul>
                            </div>
                            <div className="megamenu-featured">
                            <h3><a href="/marketing-support">Marketing Support</a></h3>
                                    <a href="/marketing-support"><img src="https://d3i7dklp9u03xa.cloudfront.net/wp-media/uploads/2024/01/ol-new-mega-nav-marketing.jpg" alt="Marketing Support" /></a> 
                            </div>
                            <div className="megamenu-background-wrapper">
                                <div className="megamenu-background"></div>
                            </div>
                        </li>
                        <li className="pbmeganav navlargeonly"><span className="subnavtoggle"></span>
                            <a href="/meet-our-team">About Us</a>
                            <div className="pbmeganav-content">
                                <ul className="mainsitenav-subnav">
                                    <li><a href="/supply-fit-partner-program">Supply &amp; Fit</a></li>
                                    <li><a href="/meet-our-team">Meet Our Team</a></li>
                                    <li><a href="/blog/">Blog</a></li>
                                    
                                </ul>
                            </div>
                            <div className="megamenu-featured">
                            <h3><a href="/marketing-support">Marketing Support</a></h3>
                                    <a href="/marketing-support"><img src="https://d3i7dklp9u03xa.cloudfront.net/wp-media/uploads/2024/01/ol-new-mega-nav-marketing.jpg" alt="Marketing Support" /></a> 
                            </div>
                            <div className="megamenu-background-wrapper">
                                <div className="megamenu-background"></div>
                            </div>
                        </li>
                        <li>
                            <a href="/findAStockist">Find a Stockist</a>
                        </li>
                    </ul>
                </div>
                <div className="headercol headercol-utils">
                    <SearchV2 />
                    <LoginModalHeader itemCount={props.itemCount} />
                    <div className="headercol-menutoggle" onClick={handleMenuToggle}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </header>
            :
             <>
                {currURL === "www.steppereyewear.com.au" ? 
            <header className="landingpage">
            <div className="headercol headercol-logo">
                <a href="/" className="mainlogolink" title="Stepper Eyewear">
                <img className="mainlogo" src="https://d3i7dklp9u03xa.cloudfront.net/steppereyewear.com.au/stepper-new-logo-white-on-black.png" width="256" height="39" alt="Stepper Eyewear" />
                </a>
            </div>
            <div className="headercol headercol-nav">
                <ul className="landingmainnav">
                    <li><a href={`/shop?${stepperFilter}`}>Explore Catalogue</a></li>
                </ul>
            </div>
            </header>
            :
            <>
            <AppBar className={' header-rootclass-' +MICROTHEME.rootclass} position="fixed" elevation={4}>
                <Container disableGutters >
                    <Toolbar disableGutters position="fixed">
                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Grid item>
                                <Grid container direction="row" justify="flex-start" alignItems="center">
                                    <Hidden mdUp>
                                        {/* Show on MOBILE */}
                                        <Grid item>
                                            <SideNav></SideNav>
                                        </Grid>
                                    </Hidden>
                                    <Grid className={classes.logowrapper} item>
                                        <Link to="/" className="link" >
                                            <Hidden xsDown>
                                                <img className="img-logo" src={config.logo} />
                                            </Hidden>
                                            <Hidden smUp>
                                                <img className="img-logo-small" src={config.logo} />
                                            </Hidden>
                                        </Link>
                                    </Grid>
                                    <Hidden smDown>
                                        {/* Show on DESKTOP */}
                                        <Grid item>
                                            <ButtonGroup>
                                                <ForEach items={structure} component={MainMenu} />
                                            </ButtonGroup>

                                        </Grid>
                                    </Hidden>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <HiddenIf value={["www.optiqueline.com.au", "www.optiqueline.co.nz", "optiqueline.com.au", "optiqueline.co.nz"].includes(getURL())}>
                                    <Grid container direction="row" justify="flex-start" alignItems="center">
                                        <Hidden smDown>
                                            <Grid item>
                                                <Search />
                                            </Grid>
                                            </Hidden>
                                        <Hidden xsDown>
                                        <Grid item>
                                            <LoginModal />
                                        </Grid>
                                        </Hidden>
                                        <Grid item>
                                            <IconButton aria-label="shopping cart" component={data => <Link to={"/cart"} style={{ fontSize: 14, color: 'inherit', textDecoration: 'inherit', color: 'gray' }}  {...data} />} >
                                                <Badge badgeContent={props.itemCount} max={9} color="secondary">
                                                    <ShoppingCartOutlinedIcon />
                                                </Badge>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </HiddenIf>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </Container>
            </AppBar>
            <div className={classes.offset} />
            </>
            }
            </>
            }   
        </>
    );
}

const mapStateToProps = createStructuredSelector({
    itemCount: selectedCartItemsCount,
    mainlogo: selectLogoInstance
});

export default connect(mapStateToProps)(withRouter(AppNavBarV2));