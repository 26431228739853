import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import { InputBase, TextField, Grid, Button, Collapse } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';



// State Libraries
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

// Redux Store Interface
import { setSearch, refreshProducts } from '../../redux/products/products.action';
import { selectMetadata } from '../../redux/products/products.selectors';
import HiddenIf from '../HiddenIf'


const useStyles = makeStyles(theme => ({
    searchContainer: {
        borderBottom: "1px solid #999",
        width: "147px",
    },
    search: {
        position: 'relative',
       // borderRadius: theme.shape.borderRadius,
        // backgroundColor: fade(theme.palette.common.white, 0.15),
        // '&:hover': {
        //     backgroundColor: fade(theme.palette.common.white, 0.25),
        // },
        marginLeft: 0,
        width: '100%',
        marginTop: "-9px",
        [theme.breakpoints.up('sm')]: {
            // marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    inputRoot: {
        color: 'inherit',
        height: "100%"
    },
    inputLabel: {
        fontSize: "16px",
        marginTop: "-3px",
        color: "#fff"
    },
    inputInput: {
        padding: 0,
        // transition: theme.transitions.create('width'),
        width: 125,
        height: "100%",
        fontSize: "16px",
        color: "#fff",
        [theme.breakpoints.up('sm')]: {
            width: 125,
        },
        //add settings for after 
        '&:after': {
            border:"none"
        }
    },
    textButton: {
        textAlign: 'center',
        fontSize: 15,
        margin: 0,//"0 0 10px",
        height: "100%",
        color: '#fff',
        padding: '6px 0',
        minWidth: 0,
    }
}));

// style={{
//     paddingLeft: "0",
//     paddingRight: "0",
//     color: 'gray',
//     height: "100%",
//     textAlign: 'center',
//     verticalAlign: 'center',
//     fontSize: 15,
//     margin: 'auto',
//     height: "100%",
//     color: 'gray',
//     fontWeight:"fontWeightBold"
// }}
const SearchV2 = (props) => {
    const classes = useStyles();
    const { toggle = true } = props;

    let setStateTimeout = null;
    let setTmpVal = '';
    let setRefreshTimeout = null;

    const [checked, setChecked] = React.useState(!toggle);

    const updateSearch = (ev) => {
        setTmpVal = ev.target.value;
        clearTimeout(setStateTimeout);
        setStateTimeout = setTimeout(()=> {
            props.setSearch(setTmpVal)
        }, 500);
        clearTimeout(setRefreshTimeout);
        setRefreshTimeout = setTimeout(()=> {
            props.refreshProducts(true,true);

        }, 1500);

    };

    const doSearch = (ev) => {
        if (ev.key === 'Enter') {
           // clearTimeout(setStateTimeout);
           // ev.preventDefault();
            setTimeout(()=> {
            //props.refreshProducts(true,true);
            if(!props.location || props.location.pathname != '/shop'){
                props.history.push("/shop");
                props.refreshProducts(true,true);
                
            }

            if(typeof props.closeParentNav === 'function') { 
                props.closeParentNav();
            }

            //remove navopen class from body
            document.body.classList.remove("navopen");

            },500);
        }
    }

    const clickSearch = () => {
        clearTimeout(setStateTimeout);
        props.refreshProducts(true,true);

        if(!props.location || props.location.pathname != '/shop'){
            props.history.push("/shop");
        }
        if(!toggle){
            if(typeof props.closeParentNav === 'function') { 
                props.closeParentNav();
            }
        }

        //remove navopen class from body
        document.body.classList.remove("navopen");
        
    }

    const toggleSearch = (ev) => {
        if (checked && props.metadata.search !== "" ) {
            props.setSearch("")
            props.refreshProducts(true,true);

        }
        setChecked(!checked)
        ev.preventDefault();
    }


    return (

        <div className={classes.margin}>
            <Grid container spacing={0} className={classes.searchContainer} alignItems="flex-end">
               {/*} <Collapse in={checked}>*/}
                    <Grid item>
                        <TextField
                            defaultValue={props.metadata.search}
                            className={classes.search}
                            label="Search Products"
                            onChange={(ev) => { updateSearch(ev) }}
                            onKeyPress={(ev) => { doSearch(ev) }}
                            InputLabelProps={{
                                className: classes.inputLabel
                            }}
                            InputProps={{
                                className: classes.inputInput,
                            }}
                             />
                    </Grid>
                {/*</Collapse>*/}
                <HiddenIf value={toggle}>
                    <Grid item >
                        <Button onClick={clickSearch} className={classes.textButton} >{/*(ev) => toggleSearch(ev)*/}
                            <SearchIcon style={{fontSize:"20px"}} />
                        </Button>
                    </Grid>
                </HiddenIf>
                <HiddenIf value={!toggle}>
                    <Grid style={{padding: "4px 0 17px", marginLeft: "5px"}} item >
                        <Button onClick={clickSearch} className={classes.textButton} style={{padding: "0 3px", border: "1px solid #999"}} >{/*(ev) => toggleSearch(ev)*/}
                            GO
                        </Button>
                    </Grid>
                </HiddenIf>
            </Grid>
        </div>

    )
}

const mapStateToProps = createStructuredSelector({
    metadata: selectMetadata,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    setSearch,
    refreshProducts
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchV2));
