//@ts-check

import React, { Component, useEffect } from "react";

// Import Navigation Elements
import { Switch, Route } from "react-router-dom";
import AppNavBar from "./components/navigation/AppNavBar";
import AppNavBarV2 from "./components/navigation/AppNavBarV2";
import Footer from "./components/Footer";
import FooterV2 from "./components/FooterV2";

// Import core
import { CssBaseline, Container } from "@material-ui/core";
// Theme and colour over-rides
import "./app.scss";

import { withStyles } from "@material-ui/core/styles";

import { Hub } from "@aws-amplify/core";

// State Libraries
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";

import { withSnackbar } from "notistack";

import { checkUserAuth } from "./redux/user/user.actions";
import { getCMSvals } from "./redux/cms/cms.actions";
import { setNotificationHandler } from "./redux/app/app.actions";

import PrivateRoute from "./components/PrivateRoute";
import AutoScrollTop from "./components/AutoScrollTop";

import Redirect from "./pages/Redirect";

// Import Pages
import HomePage from "./pages/HomePage";
import FindStockist from "./pages/FindStockist";
import Stockists from "./pages/Stockists";
import StaticPage from './pages/StaticPage';
import CMSPage from './pages/CMSPage';
import CMSPageV2 from './pages/CMSPageV2';
import CartPage from "./pages/cartpage/Cartpage.component";
import Shop from "./pages/shop/Shop";
import Account from "./pages/Account";
import ProductDetail from "./pages/shop/ProductDetail";
import CompletedOrder from "./pages/CompletedOrder";

import { setModalStatus, setLoginModalOpen } from "./redux/user/user.actions";
import { authStates } from "./settings";
import {
  getNavigationPaths,
  getNavigationExternals,
  getNavigationStatics,
  getTheme,
  getURL
} from "./microSiteConfig";
import { ForEachView } from "./components/ForEach";
import { TerritoryManagers } from './pages/territory-managers/TerritoryManagers';
const ENDPOINTS = getNavigationPaths();
const EXTERNALS = getNavigationExternals();
const STATICS = getNavigationStatics();
const MICROTHEME = getTheme();

// Done Name
// [ ] Forms // No link
// [-] Find a Stockist
// [ ] Delivery // No link
// [-] FAQ
// [-] Privacy
// [-] Newletter
// [-] T & C

const styles = theme => ({
  appContainer: {
    background: "white",
    paddingBottom: theme.spacing(3)
  }
});

class App extends Component {
  state = {
    user: null
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {

    this.props.getCMSvals();
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          console.log("HUB SIGNED IN");
          this.setState({ user: data });
          break;
        case "signOut":
          console.log("HUB SIGNED OUT");
          this.setState({ user: null });
          break;
      }
    });

    this.props.checkUserAuth();
    this.props.setNotificationHandler((msg, variant) => {
      this.handleNotification(msg, variant);
    });
  }

  handleNotification(msg, theOpts) {
    this.props.enqueueSnackbar(msg, theOpts);
  }

  render() {
  var currURL = getURL();
    var isBatchOfSites1 = (currURL === "www.auraeyewear.com.au" 
  || currURL === "www.geminieyewear.com.au" || currURL === "www.merseybeateyewear.com.au" || currURL === "www.convertibleseyewear.com");
    var isStepper = currURL === 'www.steppereyewear.com.au';

    return (
      <>
        <CssBaseline />
        <AppNavBarV2 />
        <AutoScrollTop />
       {/* <Container className={this.props.classes.appContainer + ' rootclass-' +MICROTHEME.rootclass} fixed> */}
          <Route
            exact path="/:anypage/login"
            component={() => {
              this.props.setLoginModalOpen(true);
              this.props.setModalStatus(authStates.LOGIN);
              return null;
            }}
          />
          <Route
            exact path="/:anypage/signup"
            component={() => {
              this.props.setLoginModalOpen(true);
              this.props.setModalStatus(authStates.SIGNUP);
              return null;
            }}
          />
          <Route
            exact path="/:anypage/forgot"
            component={() => {
              this.props.setLoginModalOpen(true);
              this.props.setModalStatus(authStates.FORGOT_PASSWORD);
              return null;
            }}
          />
          <Switch>
            {/* Redirect Pages for external links*/}
            {Object.keys(EXTERNALS).map( (key) => {
                let value = EXTERNALS[key];
                //console.log("Redirect ",key, value)
                return (
                  <Route
                  exact
                  key={key}
                  path={key}
                  component={() => <Redirect loc={value} />}
                />
                )
            })}
            
            {/* Static Pages Using Mark Down */}
            <Route
              exact
              path="/blog/"
              component={() => <Container className={this.props.classes.appContainer + ' rootclass-' +MICROTHEME.rootclass} fixed><CMSPage page="blogindex" /></Container>}
            />
            <Route
              exact
              path="/blog/:path1"
              component={() => <Container className={this.props.classes.appContainer + ' rootclass-' +MICROTHEME.rootclass} fixed><CMSPage page="blogsingle" /></Container>}
            />
            <Route
              exact
              path="/blog/category/:path1"
              component={() => <Container className={this.props.classes.appContainer + ' rootclass-' +MICROTHEME.rootclass} fixed><CMSPage page="blogcategory" /></Container>}
            />
            {/*
            <Route
              exact
              path={ENDPOINTS.aboutUs}
              component={() => <StaticPage page="aboutus" />}
            />
            */}
            <Route
              exact
              path={ENDPOINTS.findAStockist}
              component={Stockists}
            />
            <Route
              path={ENDPOINTS.forms}
              component={() => <StaticPage page="forms" />}
            />

            {/* Designed paged using react*/}
            <PrivateRoute path="/account" component={Account} />
            <PrivateRoute path="/cart" component={CartPage} />
            <PrivateRoute path="/order" component={CompletedOrder} />
            {/* <Route exact path="/" component={HomePage} /> */}
            <Route exact path="/" component={() => isBatchOfSites1 ? <HomePage /> : <CMSPageV2 page="custompath" /> } />
            <Route path="/productDetail" component={ProductDetail} />
            <Route path="/territory-managers" component={TerritoryManagers} />

            {/* Modal paged using react*/}

            {/* <Route exact path="/shop" component={Shop} /> */}
            {/* {"path":"/shop","url":"/shop","isExact":true,"params":{}}location */}
            <Route
              exact
              path="/shop"
              render={props => (
                <div key={props.match.path}>
                  <Shop />
                </div>
              )}
            />
            <Route exact path="/shop:query" component={Shop} />

            {/* <Route exact path="/clearance" component={Shop} /> */}
            <Route
              exact
              path="/clearance"
              render={props => (
                <div key={props.match.path}>
                  <Shop />
                </div>
              )}
            />
            {/* <Route exact path='/territory' component={TerritoryManagers} /> */}

            

            {/* 404 Page */}

            <Route
              path="/brands/v2_:path2"
              component={() => <CMSPageV2 page="custompath2" />}
            />

            <Route
              path="/v2_:path1"
              component={() => <CMSPageV2 page="custompath1" />}
            />
            
            <Route
              exact
              path="/:path1"
              component={() => <CMSPageV2 page="custompath1" />}
            />

          <Route
              exact
              path="/:path1/:path2"
              component={() => <CMSPageV2 page="custompath2" />}
            />

            <Route component={() => <CMSPageV2 page="404" />} />

          </Switch>
         {/* </Container> */}
        <FooterV2 />
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      checkUserAuth,
      setModalStatus,
      setLoginModalOpen,
      setNotificationHandler,
      getCMSvals
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withSnackbar(App)));

// <Route exact path={ENDPOINTS.spareParts} component={() => <Redirect loc={EXTERNALS.spareParts} />} />
// <Route exact path={ENDPOINTS.newsLetter} component={() => <Redirect loc={EXTERNALS.newsLetter} />} />
// <Route exact path={ENDPOINTS.blog} component={() => <Redirect loc={EXTERNALS.blog} />} />
// <Route exact path={ENDPOINTS.contactUs} component={() => <Redirect loc={EXTERNALS.contactUs} />} />
// <Route exact path={ENDPOINTS.marketingSupport} component={() => <Redirect loc={EXTERNALS.marketingSupport} />} />
// <Route exact path={ENDPOINTS.brand} component={() => <Redirect loc={EXTERNALS.brand} />} />
// <Route exact path={ENDPOINTS.brandsStepper} component={() => <Redirect loc={EXTERNALS.brandsStepper} /> }/>
// <Route exact path={ENDPOINTS.brandsConvertibles} component={() => <Redirect loc={EXTERNALS.brandsConvertibles} /> }/>
// <Route exact path={ENDPOINTS.brandsBeat} component={() => <Redirect loc={EXTERNALS.brandsBeat} /> }/>
// <Route exact path={ENDPOINTS.brandsZeiss} component={() => <Redirect loc={EXTERNALS.brandsZeiss} /> }/>
// <Route exact path={ENDPOINTS.brandsAuraFlex} component={() => <Redirect loc={EXTERNALS.brandsAuraFlex} /> }/>
// <Route exact path={ENDPOINTS.brandsGemini} component={() => <Redirect loc={EXTERNALS.brandsGemini} /> }/>
// <Route exact path={ENDPOINTS.brandsPaparazzi} component={() => <Redirect loc={EXTERNALS.brandsPaparazzi} /> }/>
