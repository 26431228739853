import React, { useState, useRef, useEffect } from "react";
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { StyledMenu, StyledMenuItem } from '../componentStyles';
import { Link } from "react-router-dom";
import "./mainmenu.scss";

function MainMenu({
    label,
    links
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [mouseOverButton, setMouseOverButton] = useState(false);
    const [mouseOverMenu, setMouseOverMenu] = useState(false);

    const timeoutLength = 50;
    const handleClick = event => {        
        setAnchorEl(event.currentTarget);
        setOpen(true)
    };

    const handleClose = (event) => {        
        setAnchorEl(null);
        setMouseOverButton(false);
        setMouseOverMenu(false);
    };

    const enterButton = (event) => {        
        setMouseOverButton(true);
    }

    const leaveButton = (event) => {                
        setTimeout(() => {
            setMouseOverButton(false);
        }, timeoutLength);
    }

    const enterMenu = (event) => {        
        setMouseOverMenu(true);
    }

    const leaveMenu = (event) => {                
        setTimeout(() => {
            setMouseOverMenu(false);
        }, timeoutLength);
    }

    useEffect(() => {
        setOpen(mouseOverButton || mouseOverMenu);
    }, [mouseOverButton, mouseOverMenu]);

    return (
        <div>
            <div
                aria-controls={open ? "customized-menu" : null}
                aria-haspopup="true"
                onClick={handleClick}
                onMouseOver={enterButton}
                onMouseOut={leaveButton}
                className="menu-button"
            >
                {label}
                <ArrowDropDownIcon className="arrow_icon"/>
            </div>
            {open && <div
                id="customized-menu"
                className="customized-menu"
                onMouseEnter={enterMenu}
                onMouseLeave={leaveMenu}
            >
                {Object.values(links).map((link, index) => (
                    <Link to={link && link.path ? link.path : null} key={index}>
                        <div
                            href=""
                            className="customized-menu-item"
                            onClick={() => handleClose()}
                        >
                            {link && link.label ? link.label : null}
                        </div>
                    </Link>
                ))}
            </div>}
        </div>
    );
}

export default MainMenu;