import React from "react";

const OnInputChange = WrappedComponent => {
  const EnhancedComponent = ({
    placeholder,
    setRedux,
    classes,
    makeChange,
    value
  }) => {
    const handleChange = event => {
      makeChange(true);
      return setRedux(event.target.value);
    };
    return (
      <WrappedComponent
        className={classes.addressInput}
        onChange={handleChange}
        placeholder={placeholder}
        value={value}
      />
    );
  };
  return EnhancedComponent;
};
export default OnInputChange;
