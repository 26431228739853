
import * as mutation from './app.mutations'
import * as App from '../../services/app';

const handleError = err => {
  console.log("Error in app action : ", err);
};

let notificationHandler;

export const refresh = () => async dispatch => {
  dispatch(mutation.isLoading())
  let data = await App.getApp();
  dispatch(mutation.setAppConfig(data))
  dispatch(mutation.notLoading())
};

export const setNotificationHandler = (func) => async dispatch => {
  notificationHandler = func
}

export const notify = (msg, thevariant='success', preventdups = false) => async dispatch => {
  notificationHandler(msg, { variant:thevariant, preventDuplicate: preventdups })
  if(notificationHandler){
    
  }else{
    console.warn("No notification handler set!")
  }
}

export const updateComponentState = (location, state) => async (dispatch) => {
  dispatch(mutation.updateComponentState(location, state))
}


export const setComponentState = component => componentKey => valueKey => value => async dispatch => {
  dispatch(updateComponentState([component,componentKey,valueKey], value))
}







