import React , {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles({
    root: {
        width: "98%",
        height:100,
        paddingTop:50,
        paddingLeft:5
    },
});

function valuetext(value) {
    return `${value}`;
}

export default function RangeSlider(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState([props.filter.lower.limit, props.filter.upper.limit]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleCommit = (event,newValue) => {
        if(newValue[1] !== props.filter.upper.value){
            props.handleCommit('upper')(newValue[1])
        }
        if(newValue[0] !== props.filter.lower.value){
            props.handleCommit('lower')(newValue[0])
        }
    }

    useEffect(() => {
        setValue([props.filter.lower.value,props.filter.upper.value])
    }, [props.filter]);


    return (
        <div className={`${classes.root} sliderwrapmin${props.filter.lower.limit}`}>
            <Slider
                value={value}
                onChange={handleChange}
                onChangeCommitted={handleCommit}
                valueLabelDisplay="on"
                aria-labelledby="range-slider"
                getAriaValueText={valuetext}
                step={1}
                marks
                min={props.filter.lower.limit}
                max={props.filter.upper.limit}
            />
        </div>
    );
}
