export const getTheme = () => {
  return {
    rootclass: 'auraeyewearcomau',
    overrides: {
      MuiButton: {
        text: {
          outline: "none"
        },
        root: {
          outline: "none",
          "&:visited": {
            outline: "none"
          },
          "&:active": {
            outline: "none"
          },
          "&:hover": {
            outline: "none"
          },
          "&:focus": {
            outline: "none"
          }
        }
      }
    },
    palette: {
      primary: {
        main: "#00accb",
        color: "#ffffff",
        text: "#ffffff",
        contrastText: "#fff"
      }, // blue
      secondary: {
        main: "#00accb",
        color: "#ffffff",
        text: "#ffffff",
        contrastText: "#ffffff"
      }, // gold

      background: {
        default: "#333"
      }
    },
    logowrapper: {
      padding: '10px 10px 17px 0',
      '& img': {
        maxWidth: '220px',
        '&.img-logo-small': {
          maxWidth: '190px',
        }
      }
    },
    micrositefooter: {
      paddingBottom: 20
    }
  };
};
