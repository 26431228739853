import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { ManagerUnit } from "./ManagerUnit";

const useStyles = makeStyles({
  formHeading: {
    padding: "5px 5px",
    borderRadius: "3px",
    backgroundColor: "#212121",
    marginBottom: "10px",
    width: "100%",
    fontSize: "25px"
  },
  formHeadingText: {
    margin: "auto",
    textAlign: "center",
    width: "100%",
    fontSize: "18px",
    color: "#fff"
  },
  edit: {
    color: "#34b3d2",
    fontSize: "0.875em",
    "&:hover": {
      color: "#34b3d2"
    }
  },
  formField: {
    padding: "8px"
  },
  formInput: {
    marginBottom: "10px",
    padding: "10px",
    textAlign: "center",
    width: "100%"
  }
});

export const ManagerBlock = ({ title, managers }) => {
  const classes = useStyles();
  return (
    <Box className={classes.formField}>
      <Box align="center" className={classes.formHeading}>
        <Typography
          variant="h6"
          align="center"
          className={classes.formHeadingText}
        >
          {title.toUpperCase()}
        </Typography>
      </Box>
      {managers.map(profile => (
        <ManagerUnit {...profile} />
      ))}
    </Box>
  );
};

