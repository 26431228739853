import React from "react";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import "../../pages/cartpage/cartpage.styles.scss";
import CheckoutItem from "../checkout-item/CheckoutItem.component";
import { OrderItem } from "../order-item/OrderItem.component";
import { ForEach } from "../ForEach";
import HiddenIf from "../HiddenIf";
import  WishListItem  from "../wishList-item/WishListItem";
import { properRounding } from "../../utils/helpers";

const useStyles = makeStyles({
  table: {
    minWidth: 400
  },
  lab: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  }
});

export const AdjustableTable = ({
  isCartPage = false,
  isOrderTable = false,
  isWishListTable = false,
  headerTitles = [],
  rowItems = {},
  subTotal = 0,
  validation,
  formCart
}) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="spanning table">
        <TableHead>
          <TableRow>
            {isCartPage ? (
              <TableCell align="center">
                <div className={classes.lab}>
                  Lab Direct
                </div>
              </TableCell>
            ) : null}
            {headerTitles.map((title, id) => (
              <TableCell key={id} align="center">
                {title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <HiddenIf value={isOrderTable === true}>
            <ForEach
              items={rowItems}
              component={item => <OrderItem key={item.index} item={item} />}
            />
          </HiddenIf>
          <HiddenIf value={isWishListTable === true}>
            <ForEach
              items={rowItems}
              component={item => <WishListItem key={item.index} item={item} />}
            />
            {/* {JSON.stringify(rowItems) + 'dsdsadsa'} */}
          </HiddenIf>
          <HiddenIf value={isCartPage === true}>
            <ForEach
              safe
              items={rowItems}
              component={item => <CheckoutItem validation={validation} formCart={formCart} {...item} />}
            />
            <TableRow>
              <TableCell colSpan={5} />
              <TableCell align="center" colSpan={2}>
                TOTAL: ${properRounding(subTotal,2,1)}
              </TableCell>
            </TableRow>
          </HiddenIf>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
