import React, { Component } from "react";
import {  withRouter } from "react-router-dom";
import { Grid,makeStyles } from "@material-ui/core/";
import {   withStyles } from '@material-ui/styles'


const styles = theme => ({
  Page: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: '80vh'
  }
})

export class Redirect extends Component {
  constructor( props ){
    super();
    this.state = { ...props };
  }
  componentWillMount(){
    const loc = typeof this.state.route !== "undefined" ? this.state.route.loc : this.props.loc;
    if(this.props.history.action == "PUSH" || loc.indexOf('://') !==-1){  //indexof to handle pops to external urls
      window.location = loc;
    }else{
      this.props.history.goBack()
    }
  }
  render(){
    return (
          <Grid className={this.props.classes.Page} container spacing={1}>
            { this.props.history.action == "PUSH" ? (
                <p>Redirecting ...</p>
            ) : (
                <p>Loading ...</p>
            )}
          </Grid>
            
          );
  }
}

export default withRouter(withStyles(styles)(Redirect));