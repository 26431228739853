/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "amplifyOptiquelineApi",
            "endpoint": "https://vndcfsq0w6.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-2:d65842d0-b337-422b-969d-a949c7014ff8",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_IZWHjhu2y",
    "aws_user_pools_web_client_id": "59dfkj8ng6m44o8jra0cn2ik57",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "amplify-optiqueline-hosting-production",
    "aws_content_delivery_bucket_region": "ap-southeast-2",
    "aws_content_delivery_url": "https://d3pitzuk075hmt.cloudfront.net",
    "aws_dynamodb_all_tables_region": "ap-southeast-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "amplifyOptiquelineCarts-production",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "Coupons-production",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "amplifyOptiquelineCustomerInfoDB-production",
            "region": "ap-southeast-2"
        },
        {
            "tableName": "amplifyOptiquelineOrders-production",
            "region": "ap-southeast-2"
        }
    ]
};


export default awsmobile;
