import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import htmlParser from 'react-markdown/plugins/html-parser';
import HtmlToReact from 'html-to-react';
import { withRouter } from 'react-router-dom';
import { Link } from '../components/Link'
import queryString from 'query-string';
import { pushUrlQuery } from "react-url-query";

import { Grid, Hidden } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

import Breadcrumb from '../components/navigation/Breadcrumb'

import LoginInline from '../components/navigation/LoginInline';

import FeaturedLinks from '../components/FeaturedLinks'
import { getAssets } from '../microSiteConfig'
import HiddenIf from "../components/HiddenIf"

const config = getAssets()

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  textContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  }
});

var processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
const parseHtml = htmlParser({
  isValidNode: node => node.type !== 'script',
  processingInstructions: [
    {
      shouldProcessNode: function (node) {
        return node.name && node.name === 'link';
      },
      processNode: function (node, children, index) {
        return React.createElement(Link, { to: node.attribs.to }, node.attribs.val);
      },
    },
    {
      shouldProcessNode: function (node) {
        return true;
      },
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ]
})

const AuthOnly = "<div class='noauthinfo'>\n\n##### ![icon](https://d3i7dklp9u03xa.cloudfront.net/icon-login-required.png) Independent Optical Practice Access Only*Please log in to view this page*\n\nThis page is only available to independent optical practices. Please log in with your username and password to view this page.\n\nIf you do not have a username or password, please get in touch with sales@optiqueline.com.au to request access.\n\n</div>";

class StaticPage extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props)

    let qs_params = queryString.parse(this.props.location.search);

    if(qs_params.brandreset){
        delete qs_params.brandreset;
        pushUrlQuery(qs_params);                             
        window.location.reload();
    }

    this.state = { terms: null }
  }

  componentWillMount() {
    this._isMounted = true;
    if (this.props.page === "authonly") {
      if (this._isMounted) {
        this.setState({ terms: AuthOnly })
      }
    } else {
      fetch('/config/' + this.props.page + '.md', {
        cache: 'no-cache',
        method: 'GET',
        headers: {
          'Content-Type': 'text/markdown'
        }
      }).then((response) => response.text()).then((text) => {
        if (this._isMounted) {
          this.setState({ terms: text })
        }
      }).catch(err => {
        if (this._isMounted) {
          this.setState({ terms: "### 404 Not Found" })
        }
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div className={`StaticPage-root-wrapper ${this.props.classes.root}`}>
        <Grid container spacing={3}>
          {/*
          <Grid item xs={12}>
            <Breadcrumb />
          </Grid>
          */}
          <HiddenIf value={typeof config.sideColumnLinks1 !== "undefined" && typeof config.sideColumnLinks1 !== "undefined"}>
            <Hidden smDown>
              <Grid item md={3}>
                <FeaturedLinks
                  featuredStubs={[...config.sideColumnLinks1, ...config.sideColumnLinks2]}
                  direction='column'
                />
              </Grid>
            </Hidden>
          </HiddenIf>
          <Grid item md={9} sm={12} >
            <ReactMarkdown
              className={`StaticPage-md-wrapper ${this.props.classes.textContainer}`}
              source={this.state.terms}
              escapeHtml={false}
              astPlugins={[parseHtml]}
            />
            <LoginInline />
          </Grid>
        </Grid>

      </div>
    )
  }
}


export default withRouter(withStyles(styles)(StaticPage));