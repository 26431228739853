import React, { useState, useEffect } from "react";
import CustomButton from "../custom-button/custom-button.component";
import { makeStyles, Box, Typography, Grid,Checkbox } from "@material-ui/core";
import { Controller } from "react-hook-form";
import HiddenIf from "../HiddenIf";
import {FieldError, buildForm} from "./BaseForm";
import * as yup from 'yup';


const useStyles = makeStyles({
  formHeading: {
    padding: "10px",
    borderRadius: "3px",
    backgroundColor: "#212121",
    marginBottom: "10px",
    width: "100%"
  },
  formHeadingText: {
    paddingLeft: "20px",
    margin: "auto",
    textAlign: "center",
    width: "100%",
    fontSize: "25px",
    color: "#fff",
  },
  edit: {
    color: "#34b3d2",
    fontSize: "0.875em",
    "&:hover": {
      color: "#34b3d2"
    }
  },
  formField: {
    padding: "8px",
  },
  formInput: {
    marginBottom: "10px",
    padding: "10px",
    textAlign: "center",
    width: "100%"
  }
});

export const att  = {
  firstName: { key: "firstname",  label: "First Name" },
  middleName: { key: "middlename",  label: "Middle Name" },
  lastName: { key: "lastname",  label: "Last Name" },
  newsletter: { key: "newsletter", label: "Newsletter" },
};

export const Validation  = yup.object().shape({
  [att.firstName.key]:  yup.string().required().label(att.firstName.label), 
  [att.middleName.key]:  yup.string().label(att.middleName.label), 
  [att.lastName.key]:  yup.string().required().label(att.lastName.label), 
  [att.newsletter.key]:  yup.string().required().label(att.newsletter.label), 
});


export const UserInfoBody = ({ 
    data = {}
  , title
  , disabled = false
  , display = true 
  , saveAble = true
  , validation: {
    reset,
    register,
    errors,
    control
  } = {
    reset: ((e) => null),
    register: null,
    errors: {}
  }
  ,...props 
}) => {
    const classes = useStyles();
    const [ isDisplay , setIsDisplay  ] = useState(display)
    const [ checked   , setChecked ] = useState(false)

    useEffect(_ => {
      setChecked(data.newsletter)
    },[data.newsletter,isDisplay])
  
  
  
    const handleEdit = (event) => {
      if(display){
        setIsDisplay(false)
      }
      event.preventDefault()
    }
    const handleCancel = (event) => {
      if(display){
        setIsDisplay(true)
      }
      reset(event)
    }
    return (
      <Box className={classes.formField} >
        <Typography variant="h6">{title}</Typography>
         <Box className={classes.spanContainer}>
        <HiddenIf value={isDisplay && !disabled && display}>
          <span className={classes.edit} onClick={(event) => handleEdit(event)}>
            Edit
          </span>
         
        </HiddenIf>
        &nbsp;&nbsp;
        <HiddenIf value={!isDisplay && !disabled}>
          <span className={classes.edit} onClick={(event) => handleCancel(event)}>
            {display ? "Close" : "Reset"}
         </span>
        </HiddenIf>
      </Box>
      <HiddenIf value={!isDisplay}>
          <>
              <FieldError error={errors[att.firstName.key]}/>
              <input
                className={classes.formInput}
                type="text"
                defaultValue={data.firstname}
                disabled={disabled}
                name={att.firstName.key}
                placeholder={att.firstName.label}
                ref={register}
              />
               <FieldError error={errors[att.middleName.key]} />
              <input
                className={classes.formInput}
                type="text"
                defaultValue={data[att.middleName.key]}
                disabled={disabled}
                name={att.middleName.key}
                placeholder={att.middleName.label}
                ref={register}
              />
              <FieldError error={errors[att.lastName.key]}/>
              <input
                className={classes.formInput}
                type="text"
                defaultValue={data[att.lastName.key]}
                disabled={disabled}
                name={att.lastName.key}
                placeholder={att.lastName.label}
                ref={register}
              />
              <Grid>
                <Controller
                  as={Checkbox}
                  name={att.newsletter.key}
                  control={control}
                  defaultValue={Boolean(data[att.newsletter.key])}
                  />
                  Subscribe to Newsletter
                </Grid>
              <span>*Required Fields</span>
              <HiddenIf value={!disabled && saveAble}>
                <CustomButton type="submit" >Save</CustomButton>
              </HiddenIf>
              <Grid>
              </Grid>
          </>
        </HiddenIf>
        <HiddenIf value={isDisplay}>
          <Typography className={classes.info}>
            Name: {data[att.firstName.key]}{" "}
            {data[att.middleName.key]}{" "}
            {data[att.lastName.key]}
            <br />
            Email: {data.email}
            <br />
            Newsletter:{" "}
            {data[att.newsletter.key] ? "Subscribed" : "Unsubscribed"}
            </Typography>
        </HiddenIf>
      </Box>
  
    )
}


export const UserInfoForm = buildForm({
  FormBody: UserInfoBody,
  Validation
})
  