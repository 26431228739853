export const FETCH_PRODUCTS_PENDING = 'FETCH_PRODUCTS_PENDING';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const UPDATE_PRODUCTS_BRAND_DISCOUNTS = 'UPDATE_PRODUCTS_BRAND_DISCOUNTS';
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR';

export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const UPDATE_PAGINATION = 'UPDATE_PAGINATION';
export const CONSTRUCT_QUERY = 'CONSTRUCT_QUERY';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_META = 'UPDATE_META';

export const SET_STATIC_FILTERS = 'SET_STATIC_FILTERS';
export const UNSET_STATIC_FILTERS = 'UNSET_STATIC_FILTERS'; 

export const SET_ORDER = 'SET_ORDER';
export const SET_FILTERS = 'SET_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_LIMIT = 'SET_LIMIT';
export const FILL_FILTER = 'FILL_FILTER';


export function setStaticFilters(data) {
    return{
        type:SET_STATIC_FILTERS,
        data
    }
}

export const fillUrlFilter = (data) => ({
    type: FILL_FILTER,
    data
})

export function unsetStaticFilters(data) {
    return{
        type:UNSET_STATIC_FILTERS,
        data
    }
}

export function fetchProductsPending() {
    return {
        type: FETCH_PRODUCTS_PENDING
    }
}

export function fetchProductsSuccess(data,metadata) {
    return {
        type: FETCH_PRODUCTS_SUCCESS,
        payload: {data,metadata}
    }
}

export function updateProductBrandDiscounts(userState) {
    return {
        type: UPDATE_PRODUCTS_BRAND_DISCOUNTS,
        userState: userState
    }
}



export function fetchProductsError(error) {
    return {
        type: FETCH_PRODUCTS_ERROR,
        error: error
    }
}

export function updateProduct(query) {
    return {
        type: UPDATE_PRODUCT,
        query: query
    }
}

// initlal filters from API
export function setFilters(data) {
    return {
        type: SET_FILTERS,
        data
    }
}
export function resetFilters(){
    return {
        type: RESET_FILTERS,
    }
}

export function setOrder(data) {
    return {
        type: SET_ORDER,
        data
    }
}
export function setLimit(data) {
    return {
        type: SET_LIMIT,
        data
    }
}
export function setSearch(data) {
    return {
        type: SET_SEARCH,
        data
    }
}

// initial pagination from API
export function setPaginations(data) {
    return {
        type:SET_PAGINATION,
        data
    }
}

export function updateFilters(filters) {
    return {
        type: UPDATE_FILTERS,
        filters: filters
    }
}

export function updatePagination(limit) {
    return {
        type: UPDATE_PAGINATION,
        limit: limit
    }
}



