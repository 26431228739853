import React from "react";
import { ManagerBlock } from "./ManagerBlock";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import HiddenIf from "../../components/HiddenIf";
import { selectUserAuthStatus } from "../../redux/user/user.selectors";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import AuthOnly from '../../components/AuthOnly';
import StaticPage from '../StaticPage';

import { getTerritoryManagers } from "../../microSiteConfig";
const theTMs = getTerritoryManagers();

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(5)
  }
}));

export const TerritoryManagers = ({ isLoggedIn }) => {
  const classes = useStyles();
  const data = getTerritoryManagers();
  return (
    <>
      <AuthOnly>
        <Grid container spacing={3} className={classes.root}>
          {Object.keys(data).map(location => (
            <Grid item xs={12} md={4} lg={4}>
              <ManagerBlock
                title={location}
                managers={data[location].managers}
              />
            </Grid>
          ))}
        </Grid>
      </AuthOnly>
      <AuthOnly showiffail={1}>
        <StaticPage page="AuthOnly" />
      </AuthOnly>
    </>
  );
};

export default TerritoryManagers;
