import  React  from 'react';
import { Link } from "react-router-dom";
import { removeFromWishList } from '../../redux/user/user.actions';
import {
    TableRow,
    TableCell,
    Typography
  } from "@material-ui/core";
  import { BackupImageLoader } from "../BackupImageLoader";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { properRounding, productFilterNameMatch } from "../../utils/helpers";

const WishListItem = props => {
  const { image,title,brand,subbrand, subbrandname,model,productid,productinstanceid, imageBackup, imageDefault } = props.item;
  return (
    <>
      <TableRow key={image}>
        <TableCell align="center" style={{width: "200px"}}>
        <Link className="link" to={'/productDetail?productId='+productid}>
          <BackupImageLoader
            images={[image, imageBackup, imageDefault]}
            style={{ width: "200px", height: "75px" }}
          />
          </Link>
        </TableCell>
        <TableCell align="left">
        <Link className="link" to={'/productDetail?productId='+productid}>
          <Typography>
          {productFilterNameMatch(subbrand, subbrandname)} {model}
          </Typography>
          <Typography variant="body2">          
            Price: ${properRounding(props.item.price,2,1)}{" "}+GST
          </Typography>
          </Link>
        </TableCell>
   
        <TableCell
          align="center"
          style={{ cursor: "pointer" }}
          className="remove-button"
          onClick={() => props.removeFromWishList(props.item)}
        >
          &#10005;
        </TableCell>
      </TableRow>
    </>
  );
};
 
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
        removeFromWishList
    },
    dispatch
  );



export default connect(null,mapDispatchToProps)(WishListItem);
