//@ts-check
import { CMSActionTypes } from "./cms.types";

export const clearCMS = () => ({
  type: CMSActionTypes.CLEAR_CMS
});

export const isFetchingData = () => ({
  type: CMSActionTypes.IS_FETCHING
});

export const notFetchingData = () => ({
  type: CMSActionTypes.NOT_FETCHING
});

export const updateLogo = data => ({
  type: CMSActionTypes.UPDATE_LOGO,
  data: data
});