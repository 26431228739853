import "./polyfills"


import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from '@aws-amplify/core';
import {store, storagePersistor} from './redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import API from '@aws-amplify/api';

import { ThemeProvider, makeStyles } from "@material-ui/styles";
import { createMuiTheme } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { SnackbarProvider } from 'notistack';
import { Router } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import reduxHistory from "./redux/browserHistory";
import * as config from "./config"

import { getTheme } from "./microSiteConfig"



import App from './App';

import aws_exports from './aws-exports';



const theme = createMuiTheme(getTheme());


Amplify.configure(aws_exports);
API.configure(aws_exports);

const withStyles = makeStyles({
    success: {
        backgroundColor: '#4CAF50',
        color: 'white'
    },
    error: { color: 'white' },
    warning: { color: 'white' },
    info: { color: 'white' },
});

const ProvidedApp = () => {
    const classes = withStyles()
    const notistackRef = React.createRef();
    const onClickDismiss = key => () => {
        notistackRef.current.closeSnackbar(key);
    }
    return (
        <Provider store={store}>
        <PersistGate persistor={storagePersistor}>
            <Router history={reduxHistory}>
                
                    <ThemeProvider theme={theme}>
                        <SnackbarProvider
                            ref={notistackRef}
                            maxSnack={2}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            classes={{
                                variantSuccess: classes.success,
                                variantError: classes.error,
                                variantWarning: classes.warning,
                                variantInfo: classes.info,
                            }}
                            action={(key) => (
                                <Button onClick={onClickDismiss(key)} style={{ color: 'white' }} size="small">
                                    <CloseIcon />
                                </Button>
                            )}
                        >
                            <App />
                        </SnackbarProvider>
                    </ThemeProvider>
                
            </Router>
            </PersistGate>
        </Provider>
    )
}

ReactDOM.render(<ProvidedApp />, document.getElementById('root'));