import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { properRounding } from "../../utils/helpers";

export const OrderItem = ({ item }) => {
  const { orderId, date, shipTo, orderTotal } = item;
  return (
    <>
      <TableRow key={orderId}>
        <TableCell align="center">{orderId}</TableCell>
        <TableCell align="center">{date}</TableCell>
        <TableCell align="center">{shipTo}</TableCell>
        <TableCell align="center">
          {`$${properRounding(orderTotal,5,1)}`}
        </TableCell>
        <TableCell align="center"></TableCell>
        <TableCell
          align="center"
          //   onClick={() => removeItem(item)}
        >
          <VisibilityIcon />
        </TableCell>
      </TableRow>
    </>
  );
};
