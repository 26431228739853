import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from './Link'
import {
    ListItemText,
    Typography,
    MenuItem,
    Menu
} from '@material-ui/core'




export const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        backgroundColor: "#e9e9e9"
    },
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        PaperProps={{
            style: {
                // left: '50%',
                border: 0,
                borderRadius: '0px 0px 5px 5px'
            }
        }}
        MenuListProps={{
            style: {
                paddingTop: "12.5px"
            }
        }}
        {...props}

    />
));

const CustomStyledMenuItem = withStyles(theme => ({
    root: {
        
    },
}))(MenuItem);

export const StyledMenuItem = (props) => (
    <Link to={props.to} style={{ color: 'inherit', textDecoration: 'inherit' }} onClick={props.onClick} >
        <MenuItem >
            <Typography style={{
                fontSize: "16px"
            }}>{props.children}</Typography>
        </MenuItem>
    </Link>
)
