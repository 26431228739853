import { getFilters } from "../microSiteConfig";
const theFilters = getFilters();

export function properRounding(num,dec = 2, returnToFixed = 0) {
    dec = dec || 0;
    var  s=Number(num).toFixed(dec+1);
    if(num%1){
      s= s.replace(/5$/, '6');
    }
    if(returnToFixed){
        return (+s).toFixed(dec);
    } else {
        return Number((+s).toFixed(dec));
    }
  }

  export function productFilterNameMatch(subbrand, subbrandname) {

    var thisfilter = theFilters.subbrand.filter;
    if( thisfilter.hasOwnProperty(subbrand) ) {
        var filteritem = thisfilter[subbrand];
        if(filteritem.label){
            return filteritem.label;
        }
    }

    return subbrandname;
};


export const removeBlankStringItemsFromJSON = obj => {
    const newObj = {};
  
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === "object") {
        newObj[key] = removeBlankStringItemsFromJSON(obj[key]); // recurse
      } else if (obj[key] != "") {
        newObj[key] = obj[key]; // copy value
      }
    });
  
    return newObj;
  };

  export const errorMessageSummaryToArray = obj => {
    var toRet = [];

    Object.keys(obj).forEach(key => {
      if(obj[key].message){
        toRet.push(obj[key].message);
      }
       else {
        var toRetM = toRet.concat(errorMessageSummaryToArray(obj[key]));
        toRet = toRetM;
      }
    });

    return toRet;
  }
