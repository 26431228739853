import  React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AuthOnly from "../AuthOnly";
import HiddenIf from "../HiddenIf";
import { addToWishList, removeFromWishList } from '../../redux/user/user.actions';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { createStructuredSelector } from "reselect";
import { selectUserAuthStatus } from "../../redux/user/user.selectors";
import { notify } from "../../redux/app/app.actions";
import {
    selectWishList
  } from "../../redux/user/user.selectors";



const WishlistButton = (props) => {
        const added = Object.keys(props.wishList).includes(props.product.productid)
        const toggleState = (item) => {
            if(added){
                props.removeFromWishList(item);
            } else{
                props.addToWishList(item);
            }
        }
        
        return (
            <AuthOnly>
                <HiddenIf value={added}>
               <FavoriteIcon className="wishlistButton" onClick={() => toggleState({ ...props.currentItem, ...props.product })} style={{ fontSize: 18, color: '#de9dac' }} />
               </HiddenIf>
               <HiddenIf value={!added}>
               <FavoriteBorderIcon className="wishlistButton" onClick={() => toggleState({ ...props.currentItem, ...props.product })} style={{ fontSize: 18, color: '#de9dac' }} />
               </HiddenIf>
            </AuthOnly>
        );
}

const mapStateToProps = createStructuredSelector({
    wishList:selectWishList,
  });

const mapDispatchToProps = dispatch => bindActionCreators({
    addToWishList,
    removeFromWishList
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(WishlistButton);