import { createSelector } from "reselect";
import { selectCourier, selectUserData } from "../user/user.selectors";
import { getSiteType, SITE_TYPES, getTaxRate } from "../../microSiteConfig";

import { mapValues } from "lodash";
import { validateOrder } from "../validations";
import { properRounding } from "../../utils/helpers";
const selectCart = state => state.cart;

const addComputedFieldsToItem = item => {
  item.state.quantity = item.state.labDirectNum + item.state.storeDirectNum;
  item.state.totalPrice = item.state.quantity * item.data.price;
  return item;
};

const selectSingleCartItem = (state, props) => {
  return props.value
    ? state.cart.cartItems[props.value]
    : { data: {}, state: {} };
};
export const createCartItemSelector = () =>
  createSelector(selectSingleCartItem, cartItem =>
    addComputedFieldsToItem(cartItem)
  );

export const selectCouriers = createSelector(selectCart, cart => cart.couriers);

export const selectStoreDirectDetailRef = createSelector(
  selectCart,
  cart => cart.storeDirectDetailReference
);

export const selectLabDirectKey = createSelector(
  [selectCart],
  cart => cart.labDirectKey
);
export const selectLabDirectValue = createSelector(
  [selectCart],
  cart => cart.labDirectValue
);
export const selectShippingOption = createSelector(
  selectCart,
  cart => cart.storeDirectState.shippingInfo
);

export const selectOptionItems = createSelector(selectCart, cart => {
  return mapValues(cart.cartItems, x => addComputedFieldsToItem(x));
});

export const selectCartItems = createSelector(selectCart, cart => {
  return mapValues(cart.cartItems, x => addComputedFieldsToItem(x));
});

export const selectCartItemKeys = createSelector([selectCartItems], items =>
  Object.keys(items)
);

export const selectCheckoutItemStoreDirectQuantity = createSelector(
  [selectCartItems],
  cartItems =>
    Object.values(cartItems).reduce(
      (totalStoreDirectNum, item) =>
        totalStoreDirectNum + item.state.storeDirectNum,
      0
    )
);

export const selectCoupon = createSelector(selectCart, cart => cart.coupon);
// export const selectSingleCourier = createSelector(
//   selectCart,
//   cart => cart.chosen_courier
// );
export const selectedCartItemsCount = createSelector(
  [selectCartItems],
  cartItems =>
    Object.values(cartItems).reduce(
      (accumulatedQuantity, cartItem) =>
        accumulatedQuantity + cartItem.state.quantity,
      0
    )
);

export const selectLabDirectEnabled = createSelector(
  [selectCartItems],
  cartItems =>
    Object.values(cartItems).reduce((enabled, cartItem) => {
      return (
        (typeof cartItem.state.labDirectEnabled === "undefined"
          ? false
          : cartItem.state.labDirectEnabled) || enabled
      );
    }, false)
);

export const selectCartSubTotal = createSelector([selectCartItems], cartItems =>
  Object.values(cartItems).reduce((accumulatedTotal, cartItem) => {
    return accumulatedTotal + cartItem.state.totalPrice;
  }, 0)
);
export const selectTotalWeight = createSelector([selectCartItems], cartItems =>
  Object.values(cartItems).reduce((accumulatedWeight, cartItem) => {
    if (typeof cartItem.data.subbrandname !== "undefined") {
      if (cartItem.data.subbrandname.includes("Convertibles")) {
        return accumulatedWeight + 0.2 * cartItem.state.storeDirectNum;
      } else {
        return accumulatedWeight + 0.1 * cartItem.state.storeDirectNum;
      }
    }
    return 0.2;
  }, 0)
);
export const selectExpressShippingFee = createSelector(
  selectTotalWeight,
  selectCourier,
  (weight, courier) => {
    if (getSiteType() === SITE_TYPES.AU) {
      if (courier === "OCS PrePaid") {
          if (weight < 0.1) return 0;
          else if (weight < 0.5) return 11.95;
          else if (weight < 3) return 16.65;
          else return 24.5;
      } else { 
          if (weight < 0.1) return 0;
          else if (weight < 0.5) return 11.95;
          else if (weight < 3) return 16.65;
          else return 24.5;   
      }
    } else {
      if (courier === "OCS PrePaid") {
        if (weight < 0.1) return 0;
        else return 5.75;
      } else {
        if (weight < 0.1) return 0;
        else if (weight < 0.6) return 6.75;
        else if (weight < 2) return 8.5;
        else return 18.5;
      }
    }
  }
)
export const selectShippingFee = createSelector(
  selectTotalWeight,
  selectCourier,
  selectShippingOption,
  (weight, courier, option) => {
    if (option === "Standard") {

      if (getSiteType() === SITE_TYPES.AU) {
        if (courier === "OCS PrePaid") {
            if (weight < 0.1){
              return 0;
            } else {
              return 5.75;
            }
        } else if(courier === "N.O.D. PRE-PAID") {
            if (weight < 0.1){
              return 0;
            } else if (weight <= 0.6){
              return 9;
            } else if (weight <= 2){
              return 12;
            } else {
              return 19.5;
            }
        } else {
          return 0;
        }
      } else {//is NZ
        
        if (weight === 0){
          return 0;
        } else if (weight <= 0.6){ 
          return 6.75;
        } else if (weight > 0.6 && weight <=2){
          return 8.5;
        } else {
          return 18.5;
        }

      }
    } else {
      return 0;
    }



   /* if (getSiteType() === SITE_TYPES.AU) {
      if (courier === "OCS PrePaid") {
        if (option === "Standard") {
          if (weight < 0.1) return 0;
          else return 5.75;
        } else if (option === "Express") {
          if (weight < 0.1) return 0;
          else if (weight < 0.5) return 11.95;
          else if (weight < 3) return 16.65;
          else return 24.5;
        } else {
          return 0;
        }
      } else {
        if (option === "Standard") {
          if (weight < 0.1) return 0;
          else if (weight <= 0.6) return 9;
          else if (weight <= 2) return 12;
          else return 19.5;
        } else if (option === "Express") {
          if (weight < 0.1) return 0;
          else if (weight < 0.5) return 11.95;
          else if (weight < 3) return 16.65;
          else return 24.5;
        } else {
          return 0;
        }
      }
    } else {//is NZ
      
        if (weight === 0){
          return 0;
        } else if (weight <= 0.6){
          return 6.75;
        } else if (weight > 0.6 && weight <=2){
          return 8.5;
        } else {
          return 18.5;
        }

    }*/
  }
);



export const selectStandardShippingFee = createSelector(
  selectTotalWeight,
  selectCourier,
  (weight, courier) => {
    if (getSiteType() === SITE_TYPES.AU) {
      if (courier === "OCS PrePaid") {
          if (weight < 0.1){
            return 0;
          } else {
            return 5.75;
          }
      } else if(courier === "N.O.D. PRE-PAID") {
          if (weight < 0.1){
            return 0;
          } else if (weight <= 0.6){
            return 9;
          } else if (weight <= 2){
            return 12;
          } else {
            return 19.5;
          }
      } else {
        return 0;
      }
    } else {//is NZ
      
      if (weight === 0){
        return 0;
      } else if (weight <= 0.6){ 
        return 6.75;
      } else if (weight > 0.6 && weight <=2){
        return 8.5;
      } else {
        return 18.5;
      }

    }
  }
);

export const selectCartDiscount = createSelector(
  selectCartSubTotal,
  selectCoupon,
  (cartTotal, coupon) => {
    if (
      typeof coupon.data !== "undefined" &&
      typeof coupon.data.Type !== "undefined" &&
      coupon.data.Type !== null &&
      coupon.data.Discount !== 0
    ) {
      switch (coupon.data.Type) {
        case "percent":
          return cartTotal * (coupon.data.Discount / 100) * -1;
        case "amount":
          return Math.min(coupon.data.Discount, cartTotal) * -1;
        default:
          return 0;
      }
    } else {
      return 0;
    }
  }
);

export const selectCartTax = createSelector(
  selectCartSubTotal,
  selectShippingFee,
  selectCartDiscount,
  (cartTotal,shipping,discount) => (cartTotal + shipping + discount) * getTaxRate()
);


export const selectLabDirectOptions = createSelector(
  selectCart,
  cart => cart.labDirectOptions
);

//totalLabDirectNum for Lab Direct Detail show and hide
export const selectCheckoutItemLabDirectQuantity = createSelector(
  [selectCartItems],
  cartItems =>
    Object.values(cartItems).reduce(
      (totalLabDirectNum, item) => totalLabDirectNum + item.state.labDirectNum,
      0
    )
);

export const selectLabDirectState = createSelector(
  selectCart,
  cart => cart.labDirectState
);

export const selectLabDirectDetails = createSelector(
  [selectLabDirectEnabled, selectLabDirectState, selectLabDirectOptions],
  (enabled, state, options) => ({
    enabled,
    state,
    options
  })
);

export const selectStoreDirectDetails = createSelector(
  selectCart,
  cart => cart.storeDirectState
);

export const selectPromotionDetails = createSelector(
  selectCart,
  cart => cart.promotionState
);

export const selectOrderObject = createSelector(
  [
    selectStoreDirectDetails,
    selectLabDirectDetails,
    selectCartItems,
    selectShippingFee,
    selectCartTax,
    selectCartDiscount,
    selectCartSubTotal,
    selectCheckoutItemStoreDirectQuantity,
    selectCheckoutItemLabDirectQuantity,
    selectUserData,
    selectCouriers
  ],
  (
    storeDirect,
    labDirect,
    cartItems,
    shippingFee,
    tax,
    discount,
    subTotal,
    storeDirectQuantity,
    labDirectQuantity,
    userData,
    courier
  ) => {
    const payload = {
      billingAddress: userData.billingAddress,
      shippingAddress: userData.shippingAddress,
      recipients: [userData.email],
      order: {
        id: "",
        date: "",
        subtotal: properRounding(subTotal,2,1),
        shipping: properRounding(shippingFee,2,1),
        tax: properRounding(tax,2,1),
        grandtotal: properRounding(Number(subTotal + shippingFee + tax - discount),2,1),
        shippingMethod: courier + " ",
        //+ storeDirect.shippingInfo,
        labDirectKey: labDirect.state,
        labDirectValue: labDirect.region,
        storeDirectRef: storeDirect.reference,
        storeDirectComments: storeDirect.comments
      },
      details: {
        labDirectQuantity: labDirectQuantity,
        storeDirectQuantity: storeDirectQuantity
      },
      items: cartItems,
      UserId: userData.email
    };
    //console.log("second payload " + JSON.stringify(payload));
    return payload;
    //const validation = validateOrder(payload);
    //.log("validation " + JSON.stringify(validation));
    //return validation;
  }
);

export const selectCartTotal = createSelector(
  selectCartTax,
  selectCartDiscount,
  selectCartSubTotal,
  selectShippingFee,
  (tax, discount, subTotal, shipping) => discount + tax + subTotal + shipping
);
