import React, { useState } from "react";
import { Grid , Hidden} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { pushUrlQuery } from "react-url-query";
import {
  getURL
} from "../microSiteConfig";

const styles = theme => ({
  root: {
      paddingTop: theme.spacing(3),
  },
  textContainer:{
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  }
});

class Stockists extends React.Component {
  constructor(props) {
      super(props)
  
      let qs_params = queryString.parse(this.props.location.search);

      if(qs_params.brandreset){
          delete qs_params.brandreset;
          pushUrlQuery(qs_params);                             
          window.location.reload();
      }
      window.scrollTo(0, 0);

      let thisCountry = (getURL() === 'www.optiqueline.co.nz')?'nz':'au';

      this.state = { terms: null, stockistbrand:qs_params.stockistbrand?qs_params.stockistbrand:null, country:thisCountry }
    }




    render() {
      return (
        <div className={`stockists-root-wrapper`}>
          <h1>Find a Stockist</h1>
          <div className={`iframe-wrapper`}>
            <iframe name={this.state.country} src={`/stockistmap-v2.html?stockistbrand=`+this.state.stockistbrand}></iframe>
          </div>
        </div>
      )
    }
}


export default  withRouter(withStyles(styles)(Stockists));