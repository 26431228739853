import { Api } from "./aws/request";
import { getUserEmail, getUser } from "./aws/auth";

export const getApp = () => {
  return Api.getAppConfig();
};

export const getSingleCoupon = code => {
  return Api.getRequest(`/coupons/check/${code}`);
};

export const createOrder = order => {
  return Api.postRequest("/orders/order", order);
};

export const getOrder = (id) => {
  return Api.getRequest(`/orders/order/${id}`)
}

export const getPastOrders = async () => {
  const userEmail = await getUserEmail()
  return Api.getRequest(`/orders/order/${userEmail}`, {
    //return Api.getRequest(`/orders/order/jmopticalreception@gmail.com`,{//${userEmail}`, {//
    "Content-Type": "application/json"
  })

  // return Api.getRequest(`/orders/order/${userEmail}`, {
  //  "Content-Type": "application/json"
  // })

  // let response = await fetch('http://localhost:8888/reactorders/', {
  //   method: 'POST', // or 'PUT'
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  //   body: JSON.stringify({'getOrderHistory':1,'email':userEmail}),
  // });
  // return await response.json();



}


export const getOrderHistoryCount = async () => {
  const userEmail = await getUserEmail()

  let response = await fetch('https://cms.optiqueline.com.au/reactorders/', {
    method: 'POST', // or 'PUT'
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({'getOrderCount':1,'email':userEmail}),
  });
  return await response.json();
}


export const getOrderHistoryRecent = async () => {
  const userEmail = await getUserEmail()

  let response = await fetch('https://cms.optiqueline.com.au/reactorders/', {
    method: 'POST', // or 'PUT'
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({'getOrderHistoryRecent':1,'email':userEmail}),
  });
  return await response.json();
}


export const Order = {
  getApp,
  getSingleCoupon,
  getOrder,
  getPastOrders,
  createOrder
};
