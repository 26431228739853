import React from "react"
import { buildForm } from "./BaseForm";
import { Controller } from "react-hook-form";

import * as yup from "yup"
import {
  Grid,
  Typography,
  TextField,
  DialogContent,
} from "@material-ui/core";


export const attStepOne = {
  emailAddress: {key: "emailAddress" , label: "Email Address"}
}

export const ValidationStepOne = yup.object().shape({
  [attStepOne.emailAddress.key]: yup.string().email().required().label(attStepOne.emailAddress.label), 
})

export const attStepTwo = {
    code: {key: "code" , label: "Code"},
    newPassword: {key: "newPassword" , label: "New Password"},
    confirmPassword: {key: "confirmPassword" , label: "Confirm Password"},
}

export const ValidationStepTwo = yup.object().shape({
    [attStepTwo.code.key]: yup.string().required().label(attStepTwo.code.label), 
    [attStepTwo.newPassword.key]: yup.string().required().label(attStepTwo.newPassword.label), 
    [attStepTwo.confirmPassword.key]: yup.string().equalTo(yup.ref(attStepTwo.newPassword.key)).required().label(attStepTwo.confirmPassword.label), 
  })


  


export const ForgotPasswordStepOneFormBody = ({ 
  data = {}
,  footerProps = {}
, Footer
, validation: {
  errors,
  control
} = {
  errors: {}
}
,...props 
}) => {
    return (
      <>
      <DialogContent dividers={true}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Typography variant="subtitle1">Retrieve your password here</Typography>
        <Typography>
          Please enter your email address below. You will receive a link to reset
          your password.
        </Typography>
        <Grid item xs={12}>
        <Controller
            as={<TextField 
              error={typeof errors[attStepOne.emailAddress.key] !== "undefined"}
              className={props.classes.textNarrowField}
              type="text"
              margin="normal"
              label={attStepOne.emailAddress.label}
              helperText={(errors[attStepOne.emailAddress.key]||{message: ""}).message}
            />}
            name={attStepOne.emailAddress.key}
            control={control}
            defaultValue=""
            />
         
        </Grid>
      </Grid>
      </DialogContent>
      <Footer {...footerProps} />
      </>
    );
}

export const ForgotPasswordStepTwoFormBody = ({ 
    data = {}
  ,  footerProps = {}
  , Footer
  , validation: {
    errors = {},
    control
  } = {
    errors: {}
  }
  ,...props 
  }) => {
      return (
        <>
        <DialogContent dividers={true}>
            <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
        >
            <Typography variant="subtitle1">
            We have sent you a email with a code to reset your password.
            </Typography>
            <Grid item xs={12}>
            <Controller
            as={<TextField 
              error={typeof errors[attStepTwo.code.key] !== "undefined"}
              className={props.classes.textNarrowField}
              type="text"
              margin="normal"
              label={attStepTwo.code.label}
              helperText={(errors[attStepTwo.code.key]||{message: ""}).message}
            />}
            name={attStepTwo.code.key}
            control={control}
            defaultValue=""
            />
            </Grid>
            <Grid item xs={12}>
            <Controller
            as={<TextField 
              error={typeof errors[attStepTwo.newPassword.key] !== "undefined"}
              className={data.textNarrowField}
              type="password"
              margin="normal"
              label={attStepTwo.newPassword.label}
              helperText={(errors[attStepTwo.newPassword.key]||{message: ""}).message}
            />}
            name={attStepTwo.newPassword.key}
            control={control}
            defaultValue=""
        />
        </Grid>
        <Grid item xs={12}>
        <Controller
            as={<TextField 
              error={typeof errors[attStepTwo.confirmPassword.key] !== "undefined"}
              className={data.textNarrowField}
              type="password"
              margin="normal"
              label={attStepTwo.confirmPassword.label}
              helperText={(errors[attStepTwo.confirmPassword.key]||{message: ""}).message}
            />}
            name={attStepTwo.confirmPassword.key}
            control={control}
            defaultValue=""
        />
            </Grid>
        </Grid>
        </DialogContent>
        <Footer {...footerProps} />
        </>
      );
}
  

export const ForgotPasswordStepOneForm = buildForm({ FormBody: ForgotPasswordStepOneFormBody, Validation: ValidationStepOne })
export const ForgotPasswordStepTwoForm = buildForm({ FormBody: ForgotPasswordStepTwoFormBody, Validation:  ValidationStepTwo })
