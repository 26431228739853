import * as mutation from "./cms.mutations";

import { notify } from "../app/app.actions";
import reduxHistory from "../browserHistory";

const handleError = e => {
  console.log("CMS ACTION ERROR");
  console.log(e);
};


export const clearCMS = () => async dispatch => {
    dispatch(mutation.clearCMS());
  };

  export const getCMSvals = () => async (dispatch,getState) => {
    dispatch(mutation.isFetchingData());
    fetch('https://info.optiqueline.com.au/reactcms/olau-main-react.json', {//https://prod-optiqueline-258565051107-site-assets.s3-ap-southeast-2.amazonaws.com/cms
        cache: 'no-cache',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function (response) {
        // The API call was successful!
        if (response.ok) {
            return response.json();
        } else {
            return Promise.reject(response);
        }
    }).then(function (data) {
        // This is the JSON from our response
        console.log(data);
        dispatch(mutation.updateLogo(data));

    }).catch(err => {
        handleError(err);
      })
      .finally(() => {
        dispatch(mutation.notFetchingData());
      });
/*
      .then(user => {
        dispatch(mutation.signInUserSuccess(user));
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          dispatch(mutation.setModalStatus(authStates.CHANGE_PASSWORD_REQUIRED));
          dispatch(mutation.setModalOpen(true));
        }
        return User.getUserInfoFromDB()
          .then(data => {
              return checkIsOnCorrectSite(data,dispatch);
          })
          .then(data => {
            dispatch(mutation.getUserDataSuccess(data));
            dispatch(mutation.updateProductBrandDiscountsUser(data,getState()['product']));
            if(data.wishList){
              dispatch(checkWishlistItemData(data.wishList))
            }
          })
          .catch(error => {
            handleError(error);
            dispatch(mutation.getUserDataFailed(error));
          });
      })
      .catch(error => {
        handleError(error);
        dispatch(mutation.signInUserFailed(error));
      })
      .finally(() => {
        dispatch(mutation.notFetchingData());
      });
      */
  };