export const authStates = {
  SIGNUP: "Sign up",
  SIGNUP_FAILED: "Failed Failed To Submit Registration Request",
  SIGNUP_SUCCESS: "Submitted Registration Request",
  SIGN_OUT: "Sign out",
  SIGN_OUT_FAILED: "Sign out Failed",
  SIGN_OUT_SUCCESS: "Sign out Success",
  LOGIN: "Log in",
  LOGIN_FAILED: "Log in Failed",
  LOGIN_SUCCESS: "Log in Success",
  FORGOT_PASSWORD: "Forgot Password",
  FORGOT_PASSWORD_SUBMIT_FAILED: "Forgot Password Submit Failed",
  FORGOT_PASSWORD_SUBMIT_SUCCESS: "Forgot Password Submit Success",
  FORGOT_PASSWORD_COMPLETED_FAILED: "Forgot Password Failed",
  FORGOT_PASSWORD_COMPLETED_SUCCESS: "Forgot Password Success",
  CHANGE_PASSWORD: "Change Password",
  CHANGE_PASSWORD_REQUIRED: "Change Password Required",
  CHANGE_PASSWORD_FAILED: "Change Password Failed",
  CHANGE_PASSWORD_SUCCESS: "Change Password Success"
}
