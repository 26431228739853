import React from "react";

import { makeStyles } from "@material-ui/styles";

import { Grid, Typography, Button, Container, Hidden } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import PinterestIcon from "@material-ui/icons/Pinterest";
import YouTubeIcon from "@material-ui/icons/YouTube";
import PublicIcon from '@material-ui/icons/Public';
import { Link } from "./Link";
import { getURL } from "../microSiteConfig";
import { getAssets, getNavigationPaths } from "../microSiteConfig";
const config = getAssets();
const ENDPOINTS = getNavigationPaths();

const useStyles = makeStyles(theme => ({
  icon: {
    color: "#ffffff"
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    "@media screen and (max-width:800px)": {
      display: "flex",
      flexDirection: "column",
      width: "inherit",
      "& :first-child": {
        padding: "0 auto"
      }
    }
  },
  content: {
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto"
    },
    display: "flex",
    flexDirection: "row",
  },
  copyright: {
    color: "#c7f0ff",
    padding: "3px"
  },
  separator: {
    color: "#c7f0ff",
    paddingLeft: "5px",
    paddingRight: "4px",
    paddingTop: 0,
    paddingBottom: 0
  },
  socials: {
    paddingTop: "1px",
  },
  links: {
    color: "white",
    margin: 0,
    padding: 0,
    fontSize: 16
  },
  root: {
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    zIndex: 1,
    color: "white",
  },
  break: {
    color: "white",
    margin: 0,
    padding: 0,
    fontSize: 16,
    [theme.breakpoints.down("sm")]: { textAlign: "center" }
  },
  micrositefooter: theme.micrositefooter,
  footerinfo: {
    paddingTop: 20,
  },
  footerlogo: {
    float: "right",
    bottom: 65,
    position: "relative",
    maxWidth: 211,   
    maxHeight: 107,
    objectFit: "contain"     
  },
  findsocket: {
    position:'relative', 
    bottom: 30,
    right: 20,
    textDecoration:'inherit !important',
    color: 'inherit !important',
    paddding: 0,
    margin: 0,    
  }
}));

const Separator = () => {
  const classes = useStyles();
  return (
    <Typography
      component={"div"}
      className={classes.separator}
      display="inline"
    >
      •
    </Typography>
  );
};

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {(getURL() === "www.steppereyewear.com.au" || getURL() === "www.auraeyewear.com.au" 
        || getURL() === "www.geminieyewear.com.au" || getURL() === "www.merseybeateyewear.com.au" || getURL() === "www.convertibleseyewear.com") ?
        <Container className={classes.micrositefooter}>
          <Grid container direction="row">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container direction="column" className={classes.footerinfo}>
                <Grid item>
                  <Grid container className={classes.socials} direction="row">
                    <h6 className={classes.content}>
                      INFORMATION
                  </h6>
                  </Grid>
                </Grid>
                <Grid item>
                  <div className={classes.break}>
                    <Typography className={classes.break}>
                      {getURL() === "www.steppereyewear.com.au" && "© 2019 Stepper Eyewear Australia. All Rights Reserved."}
                      {getURL() === "www.auraeyewear.com.au" && "© 2019 Aura Eyewear Australia. All Rights Reserved."}
                      {getURL() === "www.geminieyewear.com.au" && "© 2019 Gemini Eyewear Australia. All Rights Reserved."}
                      {getURL() === "www.merseybeateyewear.com.au" && "© 2019 Merseybeat Eyewear Australia. All Rights Reserved."}
                      {getURL() === "www.convertibleseyewear.com" && "© 2019 Convertibles Eyewear Australia. All Rights Reserved."}
                  </Typography>
                    <Typography className={classes.links}>
                      <Link className="link" to="/becomeastockist">
                        Become A Stockist
                    </Link>{" "}
                    /{" "}
                    <Link className="link" to="/faq?brandreset=1">
                        FAQs
                    </Link>{" "}
                    /{" "}
                    <Link className="link" to="/privacy?brandreset=1">
                        Privacy
                    </Link>{" "}
                    /{" "}
                    <Link className="link" to="/terms?brandreset=1">
                        T & C
                    </Link>
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {(getURL() === "www.steppereyewear.com.au" ) &&
            <Hidden smDown>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container direction="column" alignItems="flex-end">
                <img className={classes.footerlogo} src={config.footerLogo} />                
                  <Link to={`/findAStockist`} className={classes.findsocket}>
                    <h6><PublicIcon style={{ fontSize: 18, color: '#fff', marginBottom: '-2px', display: 'inline-block' }} /> Find a Stockist</h6>
                  </Link>              
              </Grid>
            </Grid>
            </Hidden>
           }
           {(getURL() === "www.auraeyewear.com.au" || getURL() === "www.geminieyewear.com.au" || getURL() === "www.convertibleseyewear.com"  || getURL() === "www.merseybeateyewear.com.au") &&
            <Hidden smDown>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container justify="flex-end" alignItems="flex-end" style={{height:"100%"}}>                
                  <Link to={`/findAStockist`} className="link">
                    <h6><PublicIcon style={{ fontSize: 18, color: '#fff', marginBottom: '-2px', display: 'inline-block' }} /> Find a Stockist</h6>
                  </Link>              
              </Grid>
            </Grid>
            </Hidden>
           }
          </Grid>
        </Container>
        :
        <Container>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container direction="column">
                <Grid item>
                  <Grid container className={classes.socials} direction="row">
                    <div className={classes.content}>
                      <Grid item className={classes.social} align="center">
                        <Button href={"https://www.facebook.com/optique.line"}>
                          <FacebookIcon
                            className={classes.icon}
                            fontSize="large"
                          />
                        </Button>
                      </Grid>
                      <Grid item className={classes.social} align="center">
                        <Button
                          href={
                            "https://www.youtube.com/channel/UCg6KvHKoKd7uMWzwrXnRNkw/videos"
                          }
                        >
                          <YouTubeIcon
                            className={classes.icon}
                            fontSize="large"
                          />
                        </Button>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <Grid item>
                {getURL() === 'www.optiqueline.co.nz' ?
                  <div className={classes.break}>
                  
                    <Typography className={classes.break}>
                    P.O BOX 7146, Sydenham, Christchurch, 8240
                    </Typography>

                    <Typography className={classes.break}>
                      Call Us on <a className={classes.break} href="tel:+0508678478">
                      0508 678 478
                    </a>
                    </Typography>
                    <Typography className={classes.break}>
                      <a
                        className={classes.break}
                        href="mailto:sales@optiqueline.com.au"
                      >
                        sales@optiqueline.com.au
                    </a>
                    </Typography>
                  </div>
                  :
                  <div className={classes.break}>

                    <Typography className={classes.break}>
                      Ground Floor, 1 Derrick Street Kew 3101
                    </Typography>

                    <Typography className={classes.break}>
                      Call Us on <a className={classes.break} href="tel:+61 3 9853 0796">
                        +61 3 9853 0796
                    </a>{" "}
                    or <a className={classes.break} href="tel:1800 649 527 ">
                        1800 649 527
                    </a>
                    </Typography>
                    <Typography className={classes.break}>
                      <a
                        className={classes.break}
                        href="mailto:sales@optiqueline.com.au"
                      >
                        sales@optiqueline.com.au
                    </a>
                    </Typography>

                    
                  </div>
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container direction="column">
                <div className={`footer-special-links`}>
                <Typography component={"div"} className={classes.links}>

                    <Link className="link" to="/aboutus">
                      About
                  </Link>
                  <Separator />
                  {getURL() === 'www.optiqueline.co.nz' ?
                    <Link className="link" to="/contact-the-nz-team/">
                      Contact
                  </Link>
                  :
                  <Link className="link" to="/contact-the-team/">
                      Contact
                  </Link>}
                  <Separator />
                  <Link className="link" to="/territory-managers">
                      Territory Managers
                    </Link>
                    </Typography>
                  <Typography component={"div"} className={classes.links}>
                    <Link className="link" to="/faq">
                      FAQs
                  </Link>
                    <Separator />
                    <Link className="link" to="/forms">
                      Forms
                  </Link>
                    <Separator />
                    <Link className="link" to="/delivery">
                      Delivery
                  </Link>
                  <Separator />
                    <Link className="link" to="/newsletter">
                      Newsletter
                  </Link>
                  </Typography>
                  <Typography component={"div"} className={classes.links}>
                    <Link className="link" to="/terms" style={{fontSize: '13px'}}>
                      Terms & Conditions
                  </Link>
                    <Separator />
                    <Link className="link" to="/privacy" style={{fontSize: '13px'}}>
                      Privacy Policy
                  </Link>
                    
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <div align="center" className={classes.copyright}>
            <Typography
              component={"div"}
              className={classes.copyright}
              variant="caption"
            >
              &copy; {new Date().getFullYear()}{" "}
              <span className="link">
                Optique Line Pty Ltd
            </span>
            </Typography>
          </div>
        </Container>
      }
    </div>
  );
};

export default Footer;
