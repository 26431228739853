//@ts-check

import React, { useState, useRef, useEffect, Component } from "react";
import './accordion.scss';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RangeSlider from './RangeSlider'
import {Grid, Button, Chip, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails} from '@material-ui/core';
import { ForEach } from '../ForEach'
import { omit } from 'lodash'
import HiddenIf from "../HiddenIf";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const filterTypes = {
    in: "in",
    between: "between"
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    chipSelected: {
        backgroundColor:"#c3d7aa !important",
        color:"#000 !important",
        margin:"2px",
        borderRadius: "10px",
   "&:focus":{
    backgroundColor: "#c3d7aa !important",
    color:"white"
  },
   "&:hover":{
    backgroundColor: "#c3d7aa !important",
    color:"white"
  }
    },
    chip: {
        margin:"2px",
        backgroundColor:"white",
        color:"#575b6e",
        borderRadius: "10px"
    }
}));

const ExpansionPanelStyle = withStyles({
    root: {
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        // '&:not(:last-child)': {
        //     borderBottom: 0,
        // },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(ExpansionPanel);

const ExpansionPanelSummaryStyle = withStyles(theme => ({
    root: {
        // backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 40,
        padding: "0 10px 0 10px",
        '&$expanded': {
            minHeight: 40,
        },
        [theme.breakpoints.up('md')]: {
            minHeight: 56,
        },
    },
    content: {
        margin: "4px 0",
        '&$expanded': {
            margin: '4px 0',
        },
        [theme.breakpoints.up('md')]: {
            margin: "12px 0",
            '&$expanded': {
                margin: '12px 0',
            },
        }
    },
    expandIcon: {
        padding: 0,
    },
    expanded: {},
}))(ExpansionPanelSummary);

const ExpansionPanelDetailsStyle = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(ExpansionPanelDetails);

const TriangleDownIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path d="M7 10l5 5 5-5z" />
    </svg>
  );

const createFilterGroupType = FilterGroup => {
    return (props) => (
        <ExpansionPanelStyle square  expanded={props.expanded === 'panel'+props.index} onChange={props.handlePanelChange} key={props.index}>
            <ExpansionPanelSummaryStyle expandIcon={<TriangleDownIcon />} aria-controls="panel1d-content" id={"panel"+props.index+"-header"}>
                <Typography variant="button" style={{ fontFamily: 'Termina, sans-serif', fontSize: '0.7rem' }}>
  {props.label}
</Typography>
                <HiddenIf value={props.active}>
                &emsp;
                {/* <CheckCircleIcon style={{ fontSize: 22, color: '#c3d7aa', marginTop: '4px', background: '#000', borderRadius: '10px' }} /> */}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="22" height="22" style={{marginTop: '4px'}}>
                    <circle cx="25" cy="25" r="24" fill="rgb(195, 215, 170)" />
                    <polyline points="15,25 22,32 35,19" stroke="rgb(0, 0, 0)" strokeWidth="3" fill="none" />
                </svg>
                </HiddenIf>
            </ExpansionPanelSummaryStyle>
            <ExpansionPanelDetailsStyle>
                <FilterGroup {...props}/>
            </ExpansionPanelDetailsStyle>
        </ExpansionPanelStyle>
    )
}

const ChipGroup = createFilterGroupType((props) => {
    return (
        <>
            <Grid item xs={12}>
                <ForEach items={props.filter} component={(data) => (
                    <Chip
                        label={data.label}
                        onClick={() => {props.handleFilterChange(data.index)(!data.value)}}
                        className={(data.value ? props.classes.chipSelected : props.classes.chip) }
                        variant="outlined"
                    />
                )} />
            </Grid>
        </>
    )
})

const RangeGroup = createFilterGroupType((props) => {
    return (
        <>
            <Grid item xs={12}>
                <RangeSlider filter={props.filter} handleCommit={props.handleFilterChange}/>
            </Grid>
        </>
    )
})

const FilterGroup = (props) => {
    const handleFilterChange = props.handleFilterChange(props.index)
    switch(props.type){
        case filterTypes.between:
            return (<RangeGroup {...props} handleFilterChange={handleFilterChange}/>)
        case filterTypes.in:
            return (<ChipGroup {...props} handleFilterChange={handleFilterChange}/>)
        default:
            console.warn(`Unknown filter type ${props.type}`)
            return (<p></p>)
    }
}



export default (props) => {

    const classes = useStyles();

    const [expanded, setExpanded] = useState('panel');
    const [filtersChosen, setFiltersChosen] = useState({}); 

    const handleChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handlePanelChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    //console.log(props.activeFilterGroups)


    useEffect(() => {
    }, [filtersChosen]);

    const handleInFilter = type => group => key => value => {
        console.log(`Handing change ${type} ${group} ${key} ${value}`)
        props.handleFilterChange({
            type,
            group,
            key,
            value
        })
    }

    const handleBetweenFilter = type => group => key => value => {
        console.log(`Handing change ${type} ${group} ${key} ${value}`)
        props.handleFilterChange({
            type,
            group,
            key,
            value
        })
    }

    const handleFilterChange = type => {
        switch(type){
            case filterTypes.between:
                return handleBetweenFilter(type)
            case filterTypes.in:
                return handleInFilter(type)
            default:
                console.warn(`Unknown Filter Type ${type}`)
                const nestedCall = (value) => {
                    console.log(`Calling undefined Filter Type ${type} ${value}`)
                    return nestedCall
                }
                return nestedCall
        }
    }

    const handleChipClick = (searchValue,searchLabel, groupLabel,groupKey,groupType) => {
        props.handleFilterChange({searchValue,searchLabel, groupLabel,groupKey,groupType})

        var remove = false;
        let filtersChosenEdit = {};
        for (var key in filtersChosen) {
            if (key === searchValue) {
                filtersChosenEdit = omit(filtersChosen, searchValue);
                remove = true;
            }
        }
        if(!remove){
            var filter = {"searchValue":searchValue, "groupLabel": groupLabel,"searchLabel": searchLabel, "groupKey": groupKey,"groupType":groupType};
            setFiltersChosen(oldValues => ({
                    ...oldValues,
                    [searchValue]: filter
            }));
        }else{
            setFiltersChosen(filtersChosenEdit);
        }

    };

    const handRangeChange = ( groupLabel,groupKey,groupType) => (searchValue) => {
        props.handleFilterChange({searchValue, groupLabel,groupKey,groupType})
    };


    const handleClearAllClick = () => {
        setFiltersChosen({});
        props.handleFilterReset();
    };

    return (
        <Grid container direction="column" justify="space-between" alignItems="flex-start" spacing={3} >
            <Grid item xs={12} style={{width:"100%"}}>
                <Grid container style={{marginLeft:"-30px"}} justify="space-between" spacing={8}>
                    <Grid item>
                        <h4 className="filterheading">
                            Filter by
                        </h4>
                    </Grid>
                    <Grid item >
                        <Button onClick={()=>handleClearAllClick()} size="small" variant="outlined" color="secondary" className={`${classes.button} filterclear`}>
                            Clear all
                        </Button>
                    </Grid>

                </Grid>
            </Grid>


            <Grid item xs={12}>
                <ForEach items={props.filters} component={(data) => (
                    <FilterGroup {...data} 
                        expanded={expanded}
                        classes={classes}
                        active={props.activeFilterGroups[data.index]}
                        handleFilterChange={handleFilterChange(data.type)} 
                        handlePanelChange={handlePanelChange('panel'+data.index)}
                    />
                )}/>
               
            </Grid>

        </Grid>
    );
}


// {props.filters.map( (filterItem, filterIndex) => (
//     <ExpansionPanelStyle square  expanded={expanded === 'panel'+filterIndex} onChange={handleChange('panel'+filterIndex)} key={filterIndex}>
//         <ExpansionPanelSummaryStyle expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id={"panel"+filterIndex+"-header"}>
//             <Typography variant="button">{filterItem.label}</Typography>
//         </ExpansionPanelSummaryStyle>
//         <ExpansionPanelDetailsStyle>
//             {
//                 (filterItem.type === 'between') ?
//                     <Grid item xs={12}>
//                         <RangeSlider theRange={filterItem.filter} handleCommit={handRangeChange(filterItem.label,filterItem.search_key,filterItem.type)}/>
//                     </Grid>
//                     :
//                     <Grid item xs={12}>
//                         {filterItem.filter.map((item, itemIndex) => (
//                             <Chip
//                                 key={itemIndex}
//                                 label={item.label}
//                                 onClick={() => {handleChipClick(item.value,item.label, filterItem.label,filterItem.search_key,filterItem.type)}}
//                                 className={ (filtersChosen[item.value] ? classes.chipSelected : classes.chip) }
//                                 variant="outlined"
//                             />
//                         ))}
//                     </Grid>
//             }
//         </ExpansionPanelDetailsStyle>
//     </ExpansionPanelStyle>
// ))}

// const mapStateToProps = createStructuredSelector({
//     state: selectAccordionState,
// });

// const mapDispatchToProps = dispatch => bindActionCreators({
//     resetFilters,
//     setFilters,
// }, dispatch)

// export default connect(mapStateToProps, mapDispatchToProps)(Accordion);

