//@ts-check

import * as mutation from "./user.mutations";
import * as User from "../../services/user";
import * as Order from "../../services/order";
import * as selectors from "./user.selectors";
import * as Product from "../../services/product";
import { clearCart } from '../cart/cart.actions'
import { notify } from "../app/app.actions"
import { authStates } from "../../settings";
import { getSiteType, getURL, development } from "../../microSiteConfig";

import { removeBlankStringItemsFromJSON } from "../../utils/helpers";

import { selectUserAuth } from "./user.selectors";

import { refreshProducts } from "../products/products.action";

import queryString from 'query-string';
import { pushUrlQuery } from "react-url-query";

export const updateInfo = (payloadDB, payloadEmail) => async dispatch => {
  dispatch(mutation.isFetchingData());
  await User.updateUser(payloadDB)
    .then(async data => {
      await User.sendEmailToUser(payloadEmail);
      await User.sendEmailToPetar(payloadEmail);
      dispatch(mutation.getUserDataSuccess(data));
    })
    .catch(error => {
      handleError(error);
      dispatch(mutation.getUserDataFailed(error));
    })
    .finally(() => {
      dispatch(mutation.notFetchingData());
    });
};

// export const updateDefaultBillingAddres = payload => async dispatch => {
//   dispatch(mutation.isFetchingData());
//   User.updateBillingAddres(payload)
//   .then(data => {
//     dispatch(mutation.updateDefaultBillingAddresSuccess(data));
//   })
//   .catch(error => {
//     handleError(error);
//     dispatch(mutation.updateDefaultBillingAddresFail(error));
//   })
//   .finally(() => {
//     dispatch(mutation.notFetchingData());
//   })
// }

// export const update

export const constructOrders = (payload, isfirst, islast) => async dispatch => {
 
  if(isfirst){
    dispatch(createOrderPending());
  }
  
  Order.createOrder(payload)
    .then(data => {
      if(islast){
        dispatch(clearCart())
        dispatch(createOrderSuccess(data));
      }
    })
    .catch(error => {
      dispatch(createOrderFailure(error));
    })
    .finally(() => {
      if(islast){
        dispatch(mutation.notFetchingData());
      }
    });
};

export const createOrderFailure = error => async dispatch => {
  dispatch(mutation.createOrderFailure(error));
};

export const createOrderPending = () => async dispatch => {
  dispatch(mutation.createOrderPending());
};

export const createOrderSuccess = data => async dispatch => {
  dispatch(mutation.createOrderSuccess(data));
};

export const forgotPasswordSubmit = email => async dispatch => {
  dispatch(mutation.isFetchingData());
  User.forgotPasswordSubmit(email)
    .then(data => {
      dispatch(mutation.forgotPasswordSubmitted(data));
    })
    .catch(error => {
      handleError(error);
      dispatch(mutation.forgotPasswordSubmitFailed(error));
    })
    .finally(() => {
      dispatch(mutation.notFetchingData());
    });
};

export const forgotPasswordComplete = (
  email,
  newPassword,
  code
) => async dispatch => {
  dispatch(mutation.isFetchingData());
  User.forgotPasswordComplete(email, newPassword, code)
    .then(data => {
      dispatch(mutation.forgotPasswordCompleted(data));
    })
    .catch(error => {
      handleError(error);
      dispatch(mutation.forgotPasswordCompleteFailed(error));
    })
    .finally(() => {
      dispatch(mutation.notFetchingData());
    });
};

export const loginUser = (username, password) => async dispatch => {
  dispatch(mutation.isFetchingData());
  return User.loginUser(username, password)
    .then(user => {
      dispatch(mutation.signInUserSuccess(user));
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        dispatch(mutation.setModalStatus(authStates.CHANGE_PASSWORD_REQUIRED));
      }else{
        dispatch(mutation.setModalStatus(authStates.LOGIN_SUCCESS))
        dispatch(mutation.setModalOpen(false))
        dispatch(notify(authStates.LOGIN_SUCCESS))
      }
      return dispatch(loadUserData())
      
    })
    .catch(error => {
      handleError(error);
      dispatch(mutation.signInUserFailed(error));
    })
    .finally(() => {
      console.log(JSON.stringify(selectors.selectUser));
      dispatch(mutation.notFetchingData());
    });
};

export const loadUserData = () => async (dispatch,getState) => {
  dispatch(mutation.isFetchingData());
  return User.getUserInfoFromDB()
    .then(data => {
        return checkIsOnCorrectSite(data,dispatch);
    })
    .then(data => {
      dispatch(mutation.getUserDataSuccess(data));
      dispatch(mutation.updateProductBrandDiscountsUser(data,getState()['product']));
      if(data.wishList){
        dispatch(checkWishlistItemData(data.wishList))
      }
    })
    .catch(error => {
      handleError(error);
      dispatch(mutation.getUserDataFailed(error));
    })
    .finally(() => {
      dispatch(mutation.notFetchingData())
    })
}

export const logoutUser = () => async dispatch => {
  dispatch(mutation.isFetchingData());
  await User.logoutUser()
    .then(user => {
      dispatch(clearCart())
      dispatch(mutation.signOutUserSuccess(user));
    })
    .catch(error => {
      handleError(error);
      dispatch(mutation.signOutUserSuccess(error));
    })
    .finally(() => {
      dispatch(mutation.notFetchingData());
    });
};

export const registerUser = payload => async dispatch => {
  dispatch(mutation.isFetchingData());
  User.registerUser(payload)
    .then(data => {
      dispatch(mutation.registerUserSuccess(data));
      dispatch(mutation.setModalStatus(authStates.SIGNUP_SUCCESS))
      dispatch(mutation.setModalOpen(false))
        dispatch(notify(authStates.SIGNUP_SUCCESS))
    })
    .catch(error => {
      handleError(error);
      dispatch(mutation.registerUserFailed(error));
      dispatch(mutation.setModalStatus(authStates.SIGNUP_FAILED))
    })
    .finally(() => {
      dispatch(mutation.notFetchingData());
    });
};

export const changePassword = (oldPassword, newPassword) => async (
  dispatch,
  getState
) => {
  dispatch(mutation.isFetchingData());
  User.changePassword(selectUserAuth(getState()), oldPassword, newPassword)
    .then(data => {
      dispatch(mutation.changePasswordSuccess(data));
      dispatch(mutation.setModalOpen(false))
      dispatch(notify("Successfully Updated Password"))
      dispatch(loadUserData())
    })
    .catch(error => {
      handleError(error);
      dispatch(mutation.changePasswordFailed(error));
    })
    .finally(() => {
      dispatch(mutation.notFetchingData());
    });
};

export const changePasswordAccountPage = (oldPassword, newPassword) => async (
  dispatch,
  getState
) => {
  dispatch(mutation.isFetchingData());
  await User.changePasswordAccountPage(
    selectUserAuth(getState()),
    oldPassword,
    newPassword
  )
    .then(data => {
      dispatch(mutation.changePasswordSuccess(data));
    })
    .catch(error => {
      handleError(error);
      dispatch(mutation.changePasswordFailed(error));
    })
    .finally(() => {
      dispatch(mutation.notFetchingData());
    });
};

export const setModalStatus = data => async dispatch => {
  dispatch(mutation.setModalStatus(data));
};

export const setLoginModalOpen = data => async dispatch => {
  dispatch(mutation.setModalOpen(data));
};

export const checkUserAuth = () => async (dispatch,getState) => {
  dispatch(mutation.isFetchingData());
  User.getUser()
    .then(user => {
      dispatch(mutation.signInUserSuccess(user));
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        dispatch(mutation.setModalStatus(authStates.CHANGE_PASSWORD_REQUIRED));
        dispatch(mutation.setModalOpen(true));
      }
      return User.getUserInfoFromDB()
        .then(data => {
            return checkIsOnCorrectSite(data,dispatch);
        })
        .then(data => {
          dispatch(mutation.getUserDataSuccess(data));
          dispatch(mutation.updateProductBrandDiscountsUser(data,getState()['product']));
          if(data.wishList){
            dispatch(checkWishlistItemData(data.wishList))
          }
        })
        .catch(error => {
          handleError(error);
          dispatch(mutation.getUserDataFailed(error));
        });
    })
    .catch(error => {
      handleError(error);
      dispatch(mutation.signInUserFailed(error));
    })
    .finally(() => {
      dispatch(mutation.notFetchingData());
    });
};


export const updateUserData = (location,state) => async dispatch => {
  dispatch(mutation.updateUserData(location,state))
}

export const resetUserData = () => async dispatch => {
  dispatch(mutation.resetUserData())
}

export const saveUserData = (data, {
  success = "Successfully Updated Account"
  , failed = "Failed To Update Account"} 
= {
  success: "Successfully Updated Account",
  failed: "Failed To Update Account"
}) => async dispatch => {

  //clean data by removing any blank values
 data = removeBlankStringItemsFromJSON(data);

//  data.iswishlistupdate = 0;

//  if(success.indexOf('wishlist') !==-1){
//   data.iswishlistupdate = 1;
//  }
 
  dispatch(mutation.isFetchingData())
  await User.updateUser(data)
  .then((response) => {
    dispatch(notify(success))
    dispatch(mutation.getUserDataSuccess(data))
  })
  .catch((err) => {
    handleError(err)
    dispatch(notify(failed, 'error'))
    dispatch(mutation.getUserDataFailed(err))
    dispatch(mutation.resetUserData())
  })
  .finally((outcome) => {
    dispatch(mutation.notFetchingData())
  })
}

// export const loadPastOrders = () => async dispatch => {
//   dispatch(mutation.isFetchingData())
//   Order.getPastOrders()
//   .then((order) => {
//     dispatch(mutation.setPastOrders(order))
//   })
//   .catch((error) => {
//     handleError(error)
//   })
//   .finally(() => {
//     dispatch(mutation.notFetchingData())
//   })
// }

export const loadPastOrders = () => async dispatch => {
  dispatch(mutation.isFetchingData())
  Order.getOrderHistoryRecent()
  .then((order) => {
    dispatch(mutation.setPastOrders(order))
  })
  .catch((error) => {
    handleError(error)
  })
  .finally(() => {
    dispatch(mutation.notFetchingData())
  })
}


export const loadOrderHistoryCount = () => async dispatch => {
  dispatch(mutation.isFetchingData())

   Order.getOrderHistoryCount()
  //getOrderHistoryCountNew(userEmail)
  .then((order) => {
    dispatch(mutation.setOrderHistoryCount(order))
  })
  .catch((error) => {
    handleError(error)
  })
  .finally(() => {
    dispatch(mutation.notFetchingData())
  })
}

// const getOrderHistoryCountNew = async (userEmail) => {
//   //const userEmail = await getUserEmail()

//   let response = await fetch('https://cms.optiqueline.com.au/reactorders/', {
//     method: 'POST', // or 'PUT'
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({'getOrderCount':1,'email':userEmail}),//userEmail
//   });
//   return await response.json();
// }






export const checkIsOnCorrectSite = (data, dispatch) => {
  var siteType = getSiteType(),
      theurl = getURL(),
      doRedirect = 0,
      pathtogoto = [development?'//':'https://','',window.location.pathname,''],
      qs_params = queryString.parse(window.location.search);
     
  if( data.customerCode.toLowerCase().indexOf('n') === 0 && siteType !== 'NZ' && (!theurl || (theurl && theurl.indexOf('optiqueline')!=-1))){
    doRedirect = 1;
    pathtogoto[1] = development?window.location.host:'www.optiqueline.co.nz';
    pathtogoto[3] = (development?'?siteType=optiqueline.co.nz&':'?')+'redir=1';

  } else if(data.customerCode.toLowerCase().indexOf('n') !== 0 && siteType === 'NZ' && (!theurl || (theurl && theurl.indexOf('optiqueline')!=-1))){
    doRedirect = 1;
    pathtogoto[1] = development?window.location.host:'www.optiqueline.com.au';
    pathtogoto[3] = (development?'?siteType=optiqueline.com.au&':'?')+'redir=1';
  }

  if(doRedirect){
    window.location.replace(pathtogoto.join(''));
  } else {

    if(qs_params.redir){
        dispatch(clearCart());
        delete qs_params.redir;
        pushUrlQuery(qs_params);    
    }

  }

  return data;
}

export const updateProductBrandDiscountsUser = () => (dispatch,getState) => {
  dispatch(mutation.updateProductBrandDiscountsUser(getState()['user'],getState()['product']));
};

export const checkWishlistItemData = (thewishlist) => async dispatch => {
  //dispatch(mutation.isFetchingData())
  var prodids = [];
    Object.keys(thewishlist).map(function(key, index) {
      prodids.push(thewishlist[key].productid);
    });

    let thisQuery = {
      attributes: {
      productid: {
          type: "in",
          value: prodids
      }
      },
      sort: {},
      limit: {}
    }
  await Product.queryProduct(thisQuery)
  .then(payload => {
      dispatch(mutation.updateWishlistValues(thewishlist,payload.data));
  })
  .catch(error => {
    handleError(error)
  })
  .finally(() => {
    //dispatch(mutation.notFetchingData());
  });
}

export const saveUser = (notifyMsgs = null) => async (dispatch,getState) => {
  const userData = selectors.selectUserData(getState())
  return dispatch(saveUserData(userData,notifyMsgs))
} 


export const addToWishList = data => dispatch => {
  dispatch(mutation.addToWishList(data))
  dispatch(saveUser({
    success: "Added item to wishlist",
    failed: "Could not add item to wishlist."
  }))
}

export const removeFromWishList = data => dispatch => {
  dispatch(mutation.removeFromWishList(data))
  dispatch(saveUser({
    success: "Removed item from wishlist",
    failed: "Could not remove item to wishlist."
  }))
}


const handleError = err => {
  console.log("Error in user action : ", err);
  //alert(err);
};
