//@ts-check
import { CartActionTypes } from "./cart.types";

export const addItem = item => ({
  type: CartActionTypes.ADD_ITEM,
  payload: item
});

export const reAddItem = (items,checkitems) => ({
  type: CartActionTypes.RE_ADD_ITEM,
  payload: items,
  checks: checkitems
});

export const minusItem = item => ({
  type: CartActionTypes.MINUS_ITEM,
  payload: item
});



export const addItemToLab = item => ({
  type: CartActionTypes.ADD_LAB_DIRECT_ITEM,
  payload: item
});

export const minusItemToLab = item => ({
  type: CartActionTypes.MINUS_LAB_DIRECT_ITEM,
  payload: item
});

export const removeItem = item => ({
  type: CartActionTypes.REMOVE_ITEM,
  payload: item
});

export const clearCart = () => ({
  type: CartActionTypes.CLEAR_CART
});

export const setJobNum = (itemChosen, jobNum) => ({
  type: CartActionTypes.SET_JOB_NUM,
  payload: { itemChosen, jobNum }
});

export const setReference = (itemChosen, reference) => ({
  type: CartActionTypes.SET_REFERENCE,
  payload: { itemChosen, reference }
});

export const labDirectEnabled = (itemChosen, checked) => ({
  type: CartActionTypes.SET_CART_ITEM_LAB_ENABLED,
  payload: {
    itemChosen,
    checked
  }
});

export const setStoreDirectDetialReferene = ref => ({
  type: CartActionTypes.SET_STORE_DIRECT_DETAIL_REFERENCE,
  payload: ref
});

export const setStoreDirectDetailComments = comments => ({
  type: CartActionTypes.SET_STORE_DIRECT_DETAIL_COMMENTS,
  payload: comments
});

export const setLabDirectKey = value => ({
  type: CartActionTypes.SET_LAB_DIRECT_KEY,
  payload: value
});

export const setLabDirectValue = value => ({
  type: CartActionTypes.SET_LAB_DIRECT_VALUE,
  payload: value
});
export const updateQuantity = (itemChosen, quantity) => ({
  type: CartActionTypes.UPDATE_QUANTITY,
  payload: { itemChosen, quantity }
});

export const updateCartItemLabQuantity = (itemChosen, quantity) => ({
  type: CartActionTypes.UPDATE_QUANTITY_LAB,
  payload: { itemChosen, quantity }
});

export const setSingleCourier = courier => ({
  type: CartActionTypes.SET_SINGLE_COURIER,
  payload: courier
});

export const setShippingOption = option => ({
  type: CartActionTypes.SET_SHIPPING_OPTION,
  payload: option
});

export const setLabDirectState = details => ({
  type: CartActionTypes.SET_LAB_DIRECT_STATE,
  payload: details
});

export const setStoreDirectState = data => ({
  type: CartActionTypes.SET_STORE_DIRECT_STATE,
  data
})

export const setPromotionState = data => ({
  type: CartActionTypes.SET_PROMOTION_STATE,
  data
});



export const redeemCoupon = coupon => ({
  type: CartActionTypes.PUT_COUPON,
  payload: coupon
});

export const fetchCouponSuccess = data => ({
  type: CartActionTypes.FETCH_COUPON_SUCCESS,
  data
});

export const fetchCouponError = data => ({
  type: CartActionTypes.FETCH_COUPON_ERROR,
  data
});

export const isFetchingData = () => ({
  type: CartActionTypes.IS_FETCHING
});

export const notFetchingData = () => ({
  type: CartActionTypes.NOT_FETCHING
});
