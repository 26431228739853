import React, { Component } from "react";
import { Grid, Hidden, ButtonGroup, Button, Box } from "@material-ui/core/";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

function ButtonRow(props) {
    return (
        <>
        {props.btns?
        props.btns.map((btn,index) => (
            <>
                <Grid item sm={false} md={props.btns.length>1?1:4}></Grid>
                <Grid item xs={12} md={4}>
                    <Link to={btn.link} style={{ color: 'inherit', textDecoration: 'inherit' }}>
                        <Button
                        variant="contained"
                        color="secondary"

                       className={props.btnclass}
                        >
                        <Typography>{btn.label}</Typography>
                        </Button>
                    </Link>
                </Grid>
                <Grid item sm={false} md={props.btns.length>1?1:4}></Grid>
            </>
            ))
:null}

        </>
        );
}


export default ButtonRow;