import Auth from "@aws-amplify/auth";

const handleError = err => {
  console.error("Api call error in services -> auth.js : ");
  console.error(JSON.stringify(err.message, null, 2));
};

export const getCurrentUserId = async () => {
  try {
    const auth = await Auth.currentAuthenticatedUser();
    return auth.attributes.sub;
  } catch (e) {
    handleError(e);
    throw e;
  }
};

export const getUser = async () => {
  try {
    const userAuth = await Auth.currentAuthenticatedUser();
    return userAuth;
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const getUserAttributes = async () => {
  try {
    const userAuth = await Auth.currentAuthenticatedUser();
    const userData = userAuth.attributes;
    return userData;
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const getUserEmail = async () => {
  try {
    const userAuth = await Auth.currentAuthenticatedUser();
    const userData = userAuth.attributes.email;
    return userData;
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const loginUser = async (username, password) => {
  try {
    const user = await Auth.signIn(username, password);
    // if(user.challengeName === "NEW_PASSWORD_REQUIRED") {
    //     throw new Error('PasswordResetRequiredException')
    // }
    return user;
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const logoutUser = async () => {
  try {
    const data = await Auth.signOut();
    return data;
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const changePassword = async (userAuth, oldPassword, newPassword) => {
  try {
    return Auth.completeNewPassword(
      userAuth,
      newPassword,
      userAuth.challengeParam.userAttributes
    );
  } catch (e) {
    handleError(e);
    throw e;
  }
};

export const changePasswordAccountPage = async (
  userAuth,
  oldPassword,
  newPassword
) => {
  try {
    const data = await Auth.changePassword(userAuth, oldPassword, newPassword);
    return data;
  } catch (e) {
    handleError(e);
    throw e;
  }
};
export const forgotPasswordSubmit = async username => {
  try {
    return await Auth.forgotPassword(username);
  } catch (e) {
    handleError(e);
    throw e;
  }
};

export const forgotPasswordComplete = async (username, newPassword, code) => {
  try {
    return await Auth.forgotPasswordSubmit(username, code, newPassword);
  } catch (e) {
    handleError(e);
    throw e;
  }
};

export const updateAttribute = async (key, value) => {
  try {
    const data = await Auth.signOut();
    return data;
  } catch (err) {
    handleError(err);
    throw err;
  }
};
