import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger'
import { persistStore } from 'redux-persist';
import reduxHistory from "./browserHistory";
import ReduxQuerySync from 'redux-query-sync'

import { selectQueryFilter } from "./products/products.selectors";
import { setUrlFilter } from "./products/products.action";
import { getFilters } from "../microSiteConfig"



import thunk from 'redux-thunk';
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from './root-reducer';


// TODO: change for prod
//import { devToolsEnhancer } from "redux-devtools-extension/logOnlyInProduction";


const logger = createLogger({
    predicate: (getState, action) => {
        if (!action.type) {
            return false
        }
        return true
    },
    collapsed: true
});


const composeEnhancers = composeWithDevTools({ realtime: true, port: 8000 });





const middlewares = [logger, thunk];




export const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(...middlewares)
)); // Creates the store from the State received from the reducer(s)


export const storagePersistor = persistStore(store);
// export const historyPersistor = ReduxQuerySync({
//     store,
//     params: {        
//       filter: {
//         selector: state => selectQueryFilter(state),
//         action: (data) => data ? setUrlFilter(data) : { type: "NULL" },        
//         stringToValue: string => {
//             return JSON.parse(string)
//         },
//         valueToString: value => `${JSON.stringify(value)}`,
//         defaultValue: {}
//       }
//     },
//     replaceState: true,
//     initialTruth: 'location',
//     reduxHistory,
// });


//let store = createStore(rootReducer,applyMiddleware(...middlewares));




