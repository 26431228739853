import { createSelector } from "reselect";
export const selectUser = state => state.user;
export const selectUserData = createSelector(
  [selectUser],
  data => data.user.data
);

export const selectUserPastOrdersCount = createSelector(
  [selectUser],
  data => data.pastOrders.length
);

export const selectUserOrderHistoryCount = createSelector(
  [selectUser],
  data => data.orderHistoryCount
);

export const selectUserAccountCode = createSelector(
  [selectUserData],
  data => data.customerCode
);

export const selectShippingAddress = createSelector(
  [selectUserData],
  data => data.shippingAddress
);

export const selectBillingAddress = createSelector(
  [selectUserData],
  data => data.billingAddress
);
export const selectUserDataError = createSelector(
  [selectUser],
  data => data.user.error
);

export const selectCourier = createSelector(
  [selectUserData],
  data => data.courier
);

export const selectUserEmail = createSelector(
  [selectUserData],
  data => data.email
);

export const selectWishList = createSelector(
  [selectUserData],
  user => user.wishList || []
)

export const selectBrands = createSelector(
  [selectUserData],
  user => user.brands || []
)

export const selectPastOrders = createSelector(
  [selectUser],
  user => {
    if(user.pastOrders){
      return user.pastOrders
    }else{
      return []
    }
  })

export const selectOrderPending = state => state.user.pending;

export const selectUserAuthStatus = createSelector([selectUser], data => {
  try {
    const username = data.auth.data.username;
    return typeof username !== "undefined" && username !== null;
  } catch (e) {
    return false;
  }
});
export const selectUserAuth = createSelector(
  [selectUser],
  data => data.auth.data
);
export const selectUserAuthError = createSelector(
  [selectUser],
  data => data.auth.error.message
);

export const selectUserFetching = createSelector(
  [selectUser],
  data => data.isFetching
);

export const selectLoginModalOpen = createSelector(
  [selectUser],
  data => data.modal.open
);

export const selectUpdateInfoState = createSelector(
  [selectUser],
  data => data.state
);
export const selectLoginModalStatus = createSelector(
  [selectUser],
  data => data.modal.state
);

export const selectRegisterStatus = createSelector(
  [selectUser],
  data => data.auth
);

export const selectLoginStatus = createSelector(
  [selectUser],
  data => data.auth
);

export const selectResetStatus = createSelector(
  [selectUser],
  data => data.auth
);

export const selectUserNewsletter = createSelector(
  [selectUserData],
data => data
);

export const selectBillingInfo = createSelector(
  [selectUserData],
  (user = {}) => user.billingAddress || {}
);

export const selectShippingInfo = createSelector(
  [selectUserData],
  (user = {}) => user.shippingAddress || {}
);

export const selectUserOrderHistory = createSelector(
  [selectUserData],
  data => data.order
);
