import { createSelector } from 'reselect';
import { cloneDeep } from 'lodash'


const selectProduct = state => state.product;

export const selectClearanceQuery = createSelector([selectProduct],product => product.clearanceQuery);

export const selectProductInstances = createSelector(
    [selectProduct],
    product => product.product
);

export const selectPagination = createSelector([selectProduct], product => product.pagination); // initial pagination
export const selectFilters = createSelector([selectProduct],product => product.filters);
export const selectMetadata = createSelector([selectProduct], product => product.metadata);




export const selectProductInstancesOverview = createSelector(
    [selectProduct],
    product => Object.keys(product).map(key => product[key])
);

export const selectProductInstancesId = id => createSelector(
    [selectProduct],
    product => product[id]
);
export const selectIsPending = createSelector([selectProduct],product => product.pending);
export const selectQueryStatics = createSelector([selectProduct],product =>product.query.statics);
export const selectQueryFilter = createSelector([selectFilters], (filters) => {
    let query = {}
    Object.keys(filters).map(colKey => {
        const col = filters[colKey] 
        switch(col.type){
            case "in":
                const selected = []
                Object.keys(col.filter).map(filterKey => {
                    const filter = col.filter[filterKey]
                    if(filter.value){
                        selected.push(filterKey)
                    }
                })
                if(selected.length > 0){
                    query[colKey] = {
                        type: col.type,
                        value: selected
                    }
                }
                break
            case "between":
                if(col.filter.lower.value !== col.filter.lower.limit || col.filter.upper.value !== col.filter.upper.limit ){
                    query[colKey] = {
                        type: col.type,
                        value: {
                            upper: col.filter.upper.value,
                            lower: col.filter.lower.value
                        }
                    }
                }
        } 
    })
    return query
});
export const selectQuerySearch = createSelector([selectMetadata], meta => {
    if((meta.search || "") !== ""){
        return {
            searcher: {
                type: "search",
                value: meta.search
            }
        }
    }
})
export const selectQueryOrderby = createSelector([selectMetadata,selectPagination], (meta,paging) => {
    const orderBy = paging.orderBy[meta.orderby]
    return {
        [orderBy.value]: orderBy.sort
    }
});
export const selectQueryLimit = createSelector([selectMetadata], meta => ({
    size: meta.limit || 6,
    page: meta.offset || 0
}));
export const getQuery = createSelector(
    [selectQueryFilter,
    selectQuerySearch,
    selectQueryOrderby,
    selectQueryLimit,
    selectQueryStatics],
    (attributes,search,sort,limit,statics) => {
        return {
            attributes: {
                ...cloneDeep(attributes),
                ...cloneDeep(search),
                ...cloneDeep(statics),
            },
            sort,
            limit
        }     
    }
)//getQuery construct the query object when the product state change

const groupSelectedHandlers = {
    in: filters => {
        return Object.keys(filters).reduce((value,key) => {
            return value || filters[key].value
        },false)
    },
    between: filter => {
        return (filter.upper.value !== filter.upper.limit || filter.lower.value !== filter.lower.limit)
    }
}
export const selectActiveFilterGroups = createSelector([selectFilters], filters => {
        let selected = {}
        Object.keys(filters).map((key) => {
            selected[key] = groupSelectedHandlers[filters[key].type](filters[key].filter)
        })
        return selected
})



export const getProductsPending = state => state.pending;
export const getProductsError = state => state.error;
