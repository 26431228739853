import React, { Component } from "react";
import { Grid, Hidden, ButtonGroup, Button, Box } from "@material-ui/core/";
import Typography from "@material-ui/core/Typography";
import { withStyles } from '@material-ui/core/styles';
import HiddenIf from '../components/HiddenIf'

import ReactMarkdown from 'react-markdown';
import htmlParser from 'react-markdown/plugins/html-parser';
import HtmlToReact from 'html-to-react';

import ImageCarousel from "../components/ImageCarousel";
import FeaturedLinks from "../components/FeaturedLinks";

import ButtonRow from "../components/ButtonRow";


import { Link } from "react-router-dom";

import { getAssets, getNavigationPaths, getURL } from "../microSiteConfig";
const config = getAssets()
const ENDPOINTS = getNavigationPaths()

const styles = theme => ({
  button: {
    variant: "contained",
    textDecoration: "none",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "100%"

  },
  catalogBox: {
    background: 'rgb(153,153,153)',
    alignItems: 'center',
    height: '120px'
  },
  catalogTextBox: {
    alignItems: 'center',
    textAlign: 'center',
    margin: 'auto',
    transform: "translateY(-90px)",
    color: 'white',
    fontSize: '29px',


  },
  catalogImageBox: {
    alignItems: 'center',
    transform: "translateY(-100px)",
    //zIndex: 3000,
    background: 'white',
    height: "150px",
    width: "150px",
    borderRadius: '50%',
    margin: 'auto',
    position: 'relative',
    zIndex: 1,

  },
  catalogImage: {
    paddingTop: "55px",
    margin: 'auto',
    width: '140px'
  },
  optional_text: {    
    margin: '50px auto',
    maxWidth: 700
  }
});

var processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
const parseHtml = htmlParser({
  isValidNode: node => node.type !== 'script',
  processingInstructions: [
    {
      shouldProcessNode: function (node) {
        return node.name && node.name === 'link';
      },
      processNode: function (node, children, index) {
        return React.createElement(Link, { to: node.attribs.to }, node.attribs.val);
      },
    },
    {
      shouldProcessNode: function (node) {
        return true;
      },
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ]
})


class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carouselImages: config.carousel,
      featuredGlasses: config.glasses,
      collectionLinks: config.homeGridRow1,
      featuredLinks1: config.homeGridRow2,
      collectionImage: config.homeGridRow3,
      featuredLinks2: config.homeGridRow4,
      homeMiddleSentenceText: config.homeMiddleSentenceText,
      optional_text: ""
    };

    if (typeof config.optional_text !== "undefined") {      
      fetch('/config/' + config.optional_text + '.md', {
        cache: 'no-cache',
        method: 'GET',
        headers: {
          'Content-Type': 'text/markdown'
        }
      }).then((response) => response.text()).then((text) => {
        this.setState({ optional_text: text })
      }).catch(err => {
        this.setState({ optional_text: "" })
      })
    }
  }

  render() {
    return (
      <Grid className="homeroot" container spacing={1}>
        <Grid item xs={12}>
          <ImageCarousel images={this.state.carouselImages} />
        </Grid>

        <HiddenIf value={typeof this.state.featuredGlasses !== "undefined"}>
          <Grid item xs={12}>
            <FeaturedLinks
              featuredStubs={this.state.featuredGlasses}
              minImageHeight="150px"
              justify="center"
            />
          </Grid>
        </HiddenIf>

        <ButtonRow btns={config.landingButtonRow} btnclass={this.props.classes.button} />

        <HiddenIf value={typeof this.state.collectionLinks !== "undefined"}>
          <Grid item xs={12}>
            <FeaturedLinks
              featuredStubs={this.state.collectionLinks}
              minImageHeight="555px"
            />
          </Grid>
        </HiddenIf>

        <HiddenIf value={typeof this.state.featuredLinks1 !== "undefined"}>
          <Grid item xs={12}>
            <FeaturedLinks
              featuredStubs={this.state.featuredLinks1}
              minImageHeight="286px"
            />
          </Grid>
        </HiddenIf>
        <HiddenIf value={typeof this.state.collectionImage !== "undefined"}>
          <Hidden smDown>
            <Grid item xs={12}>

              <FeaturedLinks
                featuredStubs={this.state.collectionImage}
                minImageHeight="576px"
              />
              <Box className={this.props.classes.catalogBox}>

                <Box className={this.props.classes.catalogImageBox}>
                  <img className={this.props.classes.catalogImage} src="logo.png" />
                </Box>
                <Typography className={this.props.classes.catalogTextBox} fontWeight="fontWeightBold">
                  {this.state.homeMiddleSentenceText.label}
                </Typography>
              </Box>
            </Grid>
          </Hidden>
        </HiddenIf>

        <HiddenIf value={typeof this.state.featuredLinks2 !== "undefined"}>
          <Grid item xs={12}>
            <FeaturedLinks
              featuredStubs={this.state.featuredLinks2}
              minImageHeight="389px"
            />
          </Grid>
        </HiddenIf>

        <HiddenIf value={typeof config.optional_text !== "undefined"}>
          <ReactMarkdown          
            className={this.props.classes.optional_text}
            source={this.state.optional_text}
            escapeHtml={false}
            astPlugins={[parseHtml]}
          />
        </HiddenIf>

      </Grid>
    );
  }
}

export default withStyles(styles)(HomePage);
