import * as mutation from "./products.mutations";
import * as Product from "../../services/product";
import { getQuery } from "./products.selectors";

const handleError = e => {
    console.log("PRODUCT ERROR");
    console.log(e);
};

export const setClearanceItemFilter = () => dispatch => {
    dispatch(setStaticFilters("clearanceFilter"));
};
export const unSetClearanceItemFilter = () => dispatch => {
    dispatch(unSetStaticFilters("clearanceFilter"));
};

export const setStaticFilters = key => (dispatch, getState) => {
    // Create static filters here product.statics
    dispatch(mutation.setPaginations(0))
    const filters = getState()["product"]["statics"][key];
    dispatch(mutation.setStaticFilters(filters));
};
export const unSetStaticFilters = key => (dispatch, getState) => {
    // Create static filters here product.statics
    dispatch(mutation.setPaginations(0))
    const filters = Object.keys(getState()["product"]["statics"][key]);
    dispatch(mutation.unsetStaticFilters(filters));
};

export const refreshProducts = (shouldResetPaging = true, isFromSearch = false) => async (
    dispatch,
    getState
) => {
        dispatch(mutation.fetchProductsPending());
        console.log({shouldResetPaging})
        if(shouldResetPaging === true){
            await dispatch(mutation.setPaginations(0))
        }
        const query = getQuery(getState());
        await Product.queryProduct(query)
        .then(payload => {
            dispatch(mutation.fetchProductsSuccess(payload.data, payload.metadata));
            dispatch(mutation.updateProductBrandDiscounts(getState()['user']));
            
            //handle searches on models with 5 char limit where user searchers on longer version of name.
            if(isFromSearch && payload.metadata.count == "0" && query.attributes.searcher && query.attributes.searcher.value && query.attributes.searcher.value.length > 5){

                dispatch(
                    mutation.setSearch({
                        type: "search",
                        value: query.attributes.searcher.value.substring(0,5)
                    })
                );

                const query2 = getQuery(getState());
                (async () => {
                    await Product.queryProduct(query2)
                    .then(payload => {
                        dispatch(mutation.fetchProductsSuccess(payload.data, payload.metadata));
                        dispatch(mutation.updateProductBrandDiscounts(getState()['user']));

                    })
                    .catch(err => {
                        handleError(err)
                        dispatch(mutation.fetchProductsError(err));
                    })
                    .finally((data) => {
                        //dispatch(mutation.notFetching());
                    })
                })();
            } 
        })
        .catch(err => {
            handleError(err)
            dispatch(mutation.fetchProductsError(err));
        })
        .finally((data) => {
            //dispatch(mutation.notFetching());
        })
};

export const setUrlFilter = (query) => async dispatch => {
    dispatch(mutation.fillUrlFilter(query))
} 

export const fetchProductsPending = () => async dispatch => {
    dispatch(mutation.fetchProductsPending());
};

export const fetchProductsError = data => async dispatch => {
    dispatch(mutation.fetchProductsError(data));
};

export const fetchProductsSuccess = (data, metadata) => async dispatch => {
    dispatch(mutation.fetchProductsSuccess(data, metadata));
};

export const updateProductBrandDiscounts = () => (dispatch,getState) => {
    dispatch(mutation.updateProductBrandDiscounts(getState()['user']));
};

export const setFilters = ({type,group,key,value}) => async dispatch => {
    dispatch(mutation.setFilters({
        type,
        group,
        key,
        value
    }));
};

export const resetFilters = () => async dispatch => {
    dispatch(mutation.resetFilters());
};

export const setPaginations = data => async dispatch => {
    dispatch(mutation.setPaginations(data));
};
export const setOrder = data => async dispatch => {
    dispatch(mutation.setOrder(data));
};
export const setSearch = data => async dispatch => {
    if (data === "") {
        dispatch(mutation.setSearch());
    } else {
        dispatch(
            mutation.setSearch({
                type: "search",
                value: data
            })
        );
    }
};
export const setLimit = data => async dispatch => {
    dispatch(mutation.setLimit(data));
};
