import React, { useState, useRef, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Link, Grid, Hidden, Button, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import { StyledMenu, StyledMenuItem } from "../componentStyles";

import { authStates } from "../../settings";

const useStyles = makeStyles(theme => ({
  textButton: {
    textAlign: "center",
    verticalAlign: "center",
    fontSize: 15,
    margin: "auto",
    height: "100%",
    color: "gray",
    fontWeight: "fontWeightBold"
  },
  iconButton: {
    textDecoration: "inherit",
    color: "gray",
    "&:active": {
      border: "none !important"
    }
  }
}));

function Login(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const open = Boolean(anchorEl);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = async () => {
    setAnchorEl(null);
    props.logOut();
  };

  const handleLogIn = loginType => {
    setAnchorEl(null);
    props.handleOpenModal(loginType);
  };

  useEffect(() => {}, [props.isLoggedIn]);

  return (
    <Grid container>
      
        {props.isLoggedIn ? (
          <>
            <Grid item>
              <Button className={classes.textButton} style={{fontSize: "12px"}} onClick={handleLogOut}>
                LOGOUT
              </Button>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-login"
                component="div"
                aria-haspopup="true"
                onClick={() => props.history.push("./account")}
                classes={{
                  root: classes.iconButton,
                  label: classes.iconButton
                }}
              >
                <AccountCircle />
              </IconButton>
            </Grid>
          </>
        ) : (
          <>
            <Grid item>
              <Button
                className={classes.textButton}
                onClick={() => handleLogIn(authStates.LOGIN)}
              >
                LOGIN
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.textButton}
                onClick={() => handleLogIn(authStates.SIGNUP)}
              >
                SIGN-UP
              </Button>
            </Grid>
          </>
        )}
      
    </Grid>
  );
}

export default withRouter(Login);
