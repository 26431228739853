import styled from 'styled-components';

const CenteredOverlay = styled.div`
  height: 60vh;
  width: 100%;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  opacity:0.2;
`;

export default CenteredOverlay;