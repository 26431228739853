export const initialState = {
  user: {
    data: {
      firstname: "",
      middlename: "",
      lastname: "",
      email: "",
      courier: "",
      newsletter: false,
      shippingAddress: {
        firstname: "",
        middlename: "",
        lastname: "",
        street1: "",
        street2: "",
        city: "",
        region: "",
        postcode: "",
        phonenumber: "",
        country: "",
        company: ""
      },
      billingAddress: {
        firstname: "",
        middlename: "",
        lastname: "",
        street1: "",
        street2: "",
        city: "",
        region: "",
        postcode: "",
        phonenumber: "",
        country: "",
        company: ""
      },
      wishList: {}
    },
    error: {}
  },
  auth: {
    data: {},
    error: {}
  },
  register: {
    success: null,
    data: null
  },
  modal: {
    state: "Log in",
    open: false
  },
  isFetching: false,
  pastOrders: []
};
