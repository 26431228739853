import * as localNav from "../default/navigation";
import { SITE_TYPES } from "..";
import { getStaticFilters as defaultStaticFilters } from '../default/states'

export { getFilters, getLabDirect, getTaxRate } from "../default/states";


export { getTheme } from "./theme";
export { getPaging } from "../default/paging"
export { getAssets } from "../default/assets"
export { getTerritoryManagers } from "../default/assets"

export const navigation = localNav;

export const getSiteType = () =>  SITE_TYPES.AU

export const getStaticFilters = () => ({
    ...defaultStaticFilters(),
    productinstance_priceaud : {
        type: "isnull",
        value: false
    },
    productinstance_priceclearanceaud : {
        type: "isnull",
        value: false
    }
});
