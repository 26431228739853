
const stepperFilter = encodeURIComponent(JSON.stringify({
    "subbrand":{
        "type":"in",
        "value":[
           // "E",
           // ,
          //  "FUA",
            "SI",
            //"SA",
            "STS"
        ]
    }
}))

const convertiblesFilter = encodeURIComponent(JSON.stringify({
    "subbrand":{
        "type":"in",
        "value":[
            "CON",
            "CONTI"
        ]
    }
}))

export const AssetBucket = "https://prod-optiqueline-258565051107-site-assets.s3-ap-southeast-2.amazonaws.com"


export const getTerritoryManagers = () => ({
    "New Zealand": {
        managers: [
          {
            name: "",
            phoneNumber: "0508 678 478",
            phoneNumberLink: "0508 678 478",
            email: "sales@optiqueline.com.au"
          },
          {
            name: "VICKI EVANS",
            phoneNumber: "027 220 2954 ",
            phoneNumberLink: "+64272202954",
            email: "vicki@optiqueline.com.au"
          },
        ]
      }
});

export const getAssets = () => ({
    logo: "/ol-main-logo.png",
    carousel: [
        /*{
          src:`${AssetBucket}/homepage/NZ-GETAWAY-SLIDER-OCTOBER-1.jpg`,
          link: '/luxurygetaway_nz/'
        },
        {
          src:`${AssetBucket}/homepage/NZ-GETAWAY-SLIDER-OCTOBER-2.jpg`,
          link: '/luxurygetaway_nz/'
        },*/
        {
          src:`${AssetBucket}/homepage/a.png`,
          link: null
        },
        {
          src:`${AssetBucket}/homepage/b.png`,
          link: null
        },
        /*{
          src:`${AssetBucket}/homepage/c.png`,
          link: null
        },*/
        {
          src:`${AssetBucket}/homepage/d.png`,
          link: null
        },
        /*{
          src:`${AssetBucket}/homepage/e.png`,
          link: null
        },
        {
          src:`${AssetBucket}/homepage/f.png`,
          link: null
        },
        {
          src:`${AssetBucket}/homepage/h.png`,
          link: null
        },*/
        {
          src:`${AssetBucket}/homepage/i.png`,
          link: null
        }
    ],
    glasses: [
        {
            img:  `${AssetBucket}/homepage/aura-911-C1@2x.jpg`,
            link: "/productDetail?productId=911-54-17"
        },
        {
            img: `${AssetBucket}/homepage/convertibles-92016-A@2x.jpg`,
            link: "/productDetail?productId=92016-51-16"
        },
        {
            img: `${AssetBucket}/homepage/essentials-163-B-1@2x.jpg`,
            link: "/productDetail?productId=163-54-17"
        },
        {
            img: `${AssetBucket}/homepage/gemini-517-A@2x.jpg`,
            link: "/productDetail?productId=517-50-16"
        },
        {
            img: `${AssetBucket}/homepage/stepper-SI-30168-F580@2x.jpg`,
            link: "/productDetail?productId=30168-54-14"
        },
        {
            img: `${AssetBucket}/homepage/stepper-sts-30051-F391@2x.jpg`,
            link: "/productDetail?productId=30051-53-14"
        }
    ],
    homeGridRow1: [
        {
            //img: `${AssetBucket}/homepage/NZ-GETAWAY-OCTOBER.gif`,
            //link: "/luxurygetaway_nz/"
            ////img: `${AssetBucket}/homepage/stepper-2021-home-block-img.jpg`,
            ////link: `/shop?filter=${stepperFilter}`
            //img: `${AssetBucket}/homepage/WEBSITE-xmas-block-v3.png`,
            //link: "/contact-the-nz-team/"
            img: `${AssetBucket}/homepage/1.jpg`,
            link: `/shop?filter=${convertiblesFilter}`
        },
        {
            img: `${AssetBucket}/homepage/2.jpg`,
            link: `/shop?filter=${stepperFilter}` //"/csr/"
        }
    ],
    homeGridRow2: [
        {
            img: `${AssetBucket}/homepage/home-row2-marketingsupport.jpg`,
            title: "MARKETING SUPPORT",
            link: "/marketing-support/"
        },
        {
            img: `${AssetBucket}/homepage/Brands_SMALL_BLOCKS2.jpg`,
            title: "BRANDS",
            link: "/brands/"
        },
        {
            img: `${AssetBucket}/homepage/accessories_SMALL_BLOCKS.jpg`,
            title: "CASES & CLOTHS",
            link: "/accessories/"
        },
        {
            img: `${AssetBucket}/homepage/Clearance_SMALL_BLOCKS4.jpg`,
            title: "CLEARANCE ITEMS",
            link: "/clearance"
        }
    ],
    homeGridRow3: [
        {
            img: `${AssetBucket}/homepage/3.jpg`,
            link: "/shop"
        }
    ],
    homeMiddleSentenceText: [
        {
            label: 'The Brands With Vision'
        }
    ],
    homeGridRow4: [
        {
            img: `${AssetBucket}/homepage/home-row4-partnerprogram.jpg`,
            title: "INVENTORY MANAGEMENT SYSTEM",
            link: '/inventory-management-system/'
        },
        {
            img: `${AssetBucket}/homepage/home-row4-seeandsave.jpg`,
            title: "FRAME & LENS",
            link: '/frame-and-lens-packages/'
        },
        {
            img: `${AssetBucket}/homepage/home-row4-labdirect.jpg`,
            title: "LAB DIRECT",
            link: '/lab-direct/'
        },
        {
            img: `${AssetBucket}/homepage/home-row4-newsletter.jpg`,
            title: "NEWSLETTER",
            link: "/newsletter"
        }
    ],
    sideColumnLinks1: [
        {
            img: `${AssetBucket}/miscimages/supply-fit.jpg`,
            title: "Supply & Fit",
            link: '/supply-fit-partner-program'
        },
        {
            img: `${AssetBucket}/miscimages/lab-direct.jpg`,
            title: "Lab Direct",
            link: '/lab-direct'
        }
    ],
    sideColumnLinks2: [
        // {
        //     img: `${AssetBucket}/homepage/1.jpg`,
        //     link: `/shop?filter=${convertiblesFilter}`
        // },
        // {
        //     img: `${AssetBucket}/homepage/2.jpg`,
        //     link: `/shop?filter=${stepperFilter}`
        // }
    ],
    description: [
        {
            type: "h1",
            body: "helkle"
        }
    ],
    landingButtonRow: [
        {
            label: 'Contact Us',
            link: `/contact-the-nz-team/`
        },
        {
            label: 'Subscribe to Newsletter',
            link: `/newsletter`
        }
    ]
})
