import React from "react";

import "./custom-button.styles.scss";

const CustomButton = ({
  children,
  fixedWidth = false,
  discount = false,
  disabled = false,
  checkout = false,
  ...otherProps
}) => (
  <button
    className={`${fixedWidth ? "fixed-width" : ""} ${
      discount ? "discount" : ""
    } ${disabled ? "disabled" : ""} ${checkout ? "btn-checkout" : ""}  custom-button`}
    {...otherProps}
  >
    {children}
  </button>
);

export default CustomButton;
