import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import { CssBaseline, Container, Box, Grid } from "@material-ui/core";
import { getTaxRate } from "../../microSiteConfig";
import HiddenIf from "../../components/HiddenIf";
import { ForEach } from "../../components/ForEach";
import { properRounding } from "../../utils/helpers";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 400,
    padding: theme.spacing(3, 2)
  },
  grid: {
    padding: theme.spacing(3, 2)
  },
  tableHeader: {
    fontStyle: "bold"
  },
  space: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3)
  }
}));

export default props => {
  const classes = useStyles();

  return (
    <Paper className={classes.space}>
      <Container>
        <Grid container className={classes.grid} spacing={5}>
          <HiddenIf value={props.labDirectQuantity != 0}>
            <Grid item xs={12}>
              <LabDirectTable
                {...props}
                classes={classes}
                title="Lab Direct Orders"
              />
            </Grid>
          </HiddenIf>
          <HiddenIf value={props.storeDirectQuantity != 0}>
            <Grid item xs={12}>
              <StoreDirectTable
                {...props}
                classes={classes}
                title="Store Direct Orders"
              />
            </Grid>
          </HiddenIf>
          <Grid item xs={12}>
            <OrderSummary {...props} classes={classes} />
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

const BoldTableCell = props => (
  <TableCell {...props}>
    <Typography component="div">
      <Box fontWeight={props.fontWeight || "fontWeightBold"}>
        {props.children}
      </Box>
    </Typography>
  </TableCell>
);

const TableHeader = props => (
  <TableHead>
    <TableRow>
      <BoldTableCell {...props}>Product</BoldTableCell>
      <BoldTableCell {...props} align="right">
        Qty
      </BoldTableCell>
      <BoldTableCell {...props} align="right">
        Subtotal
      </BoldTableCell>
    </TableRow>
  </TableHead>
);

const LabDirectTable = props => (
  <TableContainer>
    <Typography variant="h6">{props.title}</Typography>
    <Table className={props.classes.table} aria-label="spanning table">
      <TableHeader />
      <TableBody>
        <ForEach
          items={props.rows}
          component={row => (
            <HiddenIf value={row.state.labDirectNum != 0}>
              <TableRow key={row.data.productinstanceid}>
                <TableCell colSpan={0}>
                  <Typography>
                    {row.data.brand} {row.data.model}
                  </Typography>
                  <Typography variant="body2">
                    Colour: {row.data.colour===null?(row.data.productinstanceid.split("-").pop()):row.data.colour} {row.data.title}
                    {(row.state.customstockmessagelab?
          <div dangerouslySetInnerHTML={{__html: row.state.customstockmessagelab}}  style={{color: "red", fontSize: "13px"}} />
          :
          null
          )}
                  </Typography>
                  <Typography variant="caption">
                    {'Job Number: "' +
                      `${row.state.jobNum ? row.state.jobNum : ""}` +
                      '" Reference Number: "' +
                      `${row.state.reference ? row.state.reference : ""}` +
                      '"'}
                  </Typography>
                </TableCell>
                <TableCell align="right">{row.state.labDirectNum}</TableCell>
                <TableCell align="right">
                  ${properRounding(Number(row.state.labDirectNum * row.data.price),2,1)}
                </TableCell>
              </TableRow>
            </HiddenIf>
          )}
        />
      </TableBody>
    </Table>
    <Typography variant="body2" className={props.classes.grid}>
      {props.labDirect && (props.labDirect.state === "" || props.labDirect.region === "") ? (
        <>Please select Laboratory and Region</>
      ):  props.labDirect ? (
        <>
          <strong>These will be dispatched to: </strong> <br />
          {props.labDirect.state} - {props.labDirect.region}
        </>
      ) : null }
    </Typography>
  </TableContainer>
);

export const StoreDirectTable = props => (
  <>
    <TableContainer>
      <Typography variant="h6" component="h2" fontWeight="fontWeightMedium">
        {props.title}
      </Typography>
      {props.storeDirectDetailRef ? (
        <Typography component="span">
          Reference: "{props.storeDirectDetailRef}"
        </Typography>
      ) : null}
      {props.storeDirectDetailComments ? (
        <Typography component="span">
          &ensp;Comments: "{props.storeDirectDetailComments}"
        </Typography>
      ) : null}

      <Table className={props.classes.table} aria-label="spanning table">
        <TableHeader />
        <TableBody>
          <ForEach
            items={props.rows}
            component={row => (
              <HiddenIf
                key={row.state.productinstanceid}
                value={row.state.storeDirectNum != 0}
              >
                <TableRow key={row.data.productinstanceid}>
                  <TableCell colSpan={0}>
                    <Typography>
                      {row.data.brand} {row.data.model}
                    </Typography>
                    <Typography variant="body2">
                      Colour: {row.data.colour===null?(row.data.productinstanceid.split("-").pop()):row.data.colour} {row.data.title}
                      {(row.state.customstockmessage?
          <div dangerouslySetInnerHTML={{__html: row.state.customstockmessage}}  style={{color: "red", fontSize: "13px"}} />
          :
          null
          )}
                    </Typography>
                  </TableCell>
                  <TableCell align="right" width="40%">
                    {row.state.storeDirectNum}
                  </TableCell>
                  <TableCell align="right">
                    $
                    {properRounding(Number(row.state.storeDirectNum * row.data.price),2,1)}
                  </TableCell>
                </TableRow>
              </HiddenIf>
            )}
          />
        </TableBody>
      </Table>
    </TableContainer>
    {props.shippingCheckbox ? (
      <Typography variant="body2" className={props.classes.grid}>
        <strong>These will be dispatched to: </strong> <br />
        {props.billingAddress.street1}
        
        {props.billingAddress.street2 && props.billingAddress.street2.length?(
          <br />
          ):null }
        {props.billingAddress.street2}
        <br />
        {props.billingAddress.city}
        <br />
        {props.billingAddress.region}
        <br />
        {props.billingAddress.postcode}
      </Typography>
    ) : (
      <Typography variant="body2" className={props.classes.grid}>
        <strong>These will be dispatched to: </strong> <br />
        {props.shippingAddress.street1}
        
        {props.shippingAddress.street2?(
          <br />
          ):null }
        {props.shippingAddress.street2}
        <br />
        {props.shippingAddress.city}
        <br />
        {props.shippingAddress.region}
        <br />
        {props.shippingAddress.postcode}
      </Typography>
    )}
  </>
);

const OrderSummary = props => (
  <TableContainer>
    <Table className={props.classes.table} aria-label="spanning table">
      <TableBody>
        <SummaryRow title="Subtotal" sub="" value={properRounding(props.subTotal,2,1)} />
        {props.coupon && props.discount !== 0 ? (
          <SummaryRow
            title="Coupon"
            sub={`${props.coupon.Discount} %`}
            value={properRounding(props.discount,2,1)}
          />
        ) : null}
        <SummaryRow
          title="Shipping"
          sub=""
          value={properRounding(props.shippingFee,2,1)}
        />
        <SummaryRow
          title="GST"
          sub={`${properRounding((getTaxRate() * 100),0,1)} %`}
          value={properRounding(props.tax,2,1)}
        />
        <SummaryRow title="Total" sub="" value={properRounding(props.total,2,1)} />
      </TableBody>
    </Table>
  </TableContainer>
);

const SummaryRow = props => (
  <TableRow>
    <TableCell>{props.title}</TableCell>
    <TableCell align="right">{props.sub}</TableCell>
    <TableCell align="right">${props.value}</TableCell>
  </TableRow>
);
