import * as localNav from "./navigation";
import { SITE_TYPES } from "..";

export { getFilters, getStaticFilters, getLabDirect, getTaxRate } from "../default/states";
export { getTheme } from "./theme";
export { getPaging } from "../default/paging";
export { getAssets } from "./assets";
export { getTerritoryManagers } from "../default/assets";
export const navigation = localNav;

export const getSiteType = () => SITE_TYPES.AU;
