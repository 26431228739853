import * as Api from "./aws/request";
import * as Auth from "./aws/auth";

export const getUser = () => {
  return Auth.getUser();
};

export const registerUser = payload => {
  return Api.postRequest("/register", payload);
};

export const getUserAttributes = () => {
  return Auth.getUserAttributes();
};
export const loginUser = (username, password) => {
  return Auth.loginUser(username, password);
};
export const logoutUser = () => {
  return Auth.logoutUser();
};
export const changePassword = (userAuth, oldPassword, newPassword) => {
  return Auth.changePassword(userAuth, oldPassword, newPassword);
};

export const changePasswordAccountPage = async (
  userAuth,
  oldPassword,
  newPassword
) => {
  return await Auth.changePasswordAccountPage(
    userAuth,
    oldPassword,
    newPassword
  );
};
export const forgotPasswordSubmit = username => {
  return Auth.forgotPasswordSubmit(username);
};
export const forgotPasswordComplete = (username, newPassword, code) => {
  return Auth.forgotPasswordComplete(username, newPassword, code);
};
export const updateAttribute = (key, value) => {
  return Auth.updateAttribute(key, value);
};

export const updateUser = async payload => {
  return await Api.postRequest("/customers", payload);
};
export const sendEmailToUser = async payload => {
  console.log(JSON.stringify(payload));
  return await Api.postRequest("/customers/email/user", payload);
};
export const sendEmailToPetar = async payload => {
  return await Api.postRequest("/customers/email/petar", payload);
};
export const getUserInfoFromDB = async () => {
  let email = await Auth.getUserEmail();
  return Api.getRequest(`/customers/object/${email}`);
};

export const User = {
  getUser,
  registerUser,
  updateUser,
  getUserInfoFromDB,
  changePasswordAccountPage,
  sendEmailToUser,
  sendEmailToPetar
};
