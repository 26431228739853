import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Link, Typography, Breadcrumbs , Paper} from '@material-ui/core';
import { Link as RouterLink,  } from '../Link';
import {Route, MemoryRouter } from 'react-router';

const breadcrumbNameMap = {
    '/': 'Home',
    '/aboutUs': 'About Us',
    '/blog': 'Blog',
    '/contactus': 'Contact Us',
    '/findastockist': 'Find a Stockist',
    '/marketingsupport': 'Marketing Support',
    '/shop': 'Shop',
    '/clearance': 'Clearance',
    '/NewsletterSubscription': 'Newsletter Subscription',
    '/account': 'Account',
    '/logout': 'Logout',
    '/cart': 'Cart',
    '/faq': 'FAQ',
    '/terms': 'Terms & Conditions',
    '/privacy': 'Privacy Policy',
    '/forms' : 'Forms',
    '/delivery' : 'Delivery',
    '/returns' : 'Returns'

};


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: "100%",
        background: "#f5f5f5",
        paddingLeft: theme.spacing(4),
        paddingBottom: theme.spacing(1)
    },
    crumb: {

    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    paths: {
        color: '#777',
        fontWeight: 500,
        fontSize: "14px",
    },
    finalPath: {
        color: '#333',
        fontWeight: 700,
        fontSize: "14px",
       
    }
}));

const LinkRouter = props => <Link {...props} component={RouterLink} />;

export default function Breadcrumb(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(prevOpen => !prevOpen);
    };

    return (
        <Paper className={classes.root} elevation={0}  >
            <Breadcrumbs className={classes.crumb}>
            <RouterLink to="/">
                <Typography className={classes.paths}>Home</Typography>
            </RouterLink>
                <Route>
                    {({ location }) => {
                        const pathnames = location.pathname.split('/').filter(x => x);
                        return (
                            <>
                                {pathnames.map((value, index) => {
                                    const last = index === pathnames.length - 1;
                                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                                    return last ? (
                                            <Typography className={classes.finalPath} key={to}>
                                                {breadcrumbNameMap[to]}
                                            </Typography>
                                    ) : (
                                        <RouterLink to={to} key={to}>
                                            <Typography className={classes.paths} key={to}>
                                                {breadcrumbNameMap[to]}
                                            </Typography>
                                        </RouterLink>
                                    );
                                })}
                            </>
                            
                        );
                    }}
                </Route>
            </Breadcrumbs>
        </Paper>
    );
}