import {AssetBucket} from '../default/assets'
import { getNavigationPaths } from './navigation';


export const getAssets = () => {
    const paths = getNavigationPaths()
    return {
    logo: `${AssetBucket}/geminieyewear.com.au/brand-logo.png`,
    carousel: [
        {
            src:`${AssetBucket}/geminieyewear.com.au/SLIDE6.png`,
            link: null
        },
        {
            src:`${AssetBucket}/geminieyewear.com.au/SLIDE7.png`,
            link: null
        }       
    ],  
    glasses: [
        {
            img: `${AssetBucket}/geminieyewear.com.au/geminicollection.jpg`,
            link: paths.shopCollection
        },
        {
            img: `${AssetBucket}/geminieyewear.com.au/geminiessentials.jpg`,
            link: paths.shopEssentials
        }
    ],     
    sideColumnLinks1: [
        {
            img: `${AssetBucket}/homepage/home-row4-partnerprogram.jpg`,
            title: "INVENTORY MANAGEMENT SYSTEM",
            link: '/inventory-management-system/'
        },
        {
            img: `${AssetBucket}/homepage/home-row4-labdirect.jpg`,
            title: "LAB DIRECT",
            link: '/lab-direct/'
        },
        {
            img: `${AssetBucket}/homepage/home-row2-marketingsupport.jpg`,
            title: "MARKETING SUPPORT",
            link: paths.marketingSupport
        }
    ],
    sideColumnLinks2: [
        {
            img: `${AssetBucket}/homepage/1.jpg`,
            link: paths.convertiblesPromo
        },
        {
            img: `${AssetBucket}/homepage/2.jpg`,
            link: paths.stepperPromo
        }
    ],
    homeMiddleSentenceText: 
      {
        label: ''
      }
    ,
    optional_text:"about_gemini",
    description: [
        {
            type: "h1",
            body: "helkle"
        }
    ],
    landingButtonRow: [
        {
            label: 'Find A Stockist',
            link: paths.findAStockist
        },
        {
            label: 'Shop the Range',
            link: paths.shop
        }
    ],
}
}