import React from "react"
import { buildForm } from "./BaseForm";
import { Controller } from "react-hook-form";

import * as yup from "yup"
import {
    Grid,
    Typography,
    TextField,
    DialogContent,
} from "@material-ui/core";




export const att = {
    oldPassword: { key: "oldPassword", label: "Old Password" },
    newPassword: { key: "newPassword", label: "New Password" },
    confirmPassword: { key: "confirmPassword", label: "Confirm New Password" },
}

export const Validation = yup.object().shape({
    [att.oldPassword.key]: yup.string().required().label(att.oldPassword.label),
    [att.newPassword.key]: yup.string().required().label(att.newPassword.label),
    [att.confirmPassword.key]: yup.string().equalTo(yup.ref(att.newPassword.key)).required().label(att.confirmPassword.label),
})




export const ChangePasswordModalFormBody = ({
    data = {}
    , footerProps = {}
    , Footer
    , validation: {
        errors = {},
        control
    } = {
        errors: {}
    }
    , ...props
}) => {
    return (
        <>
            <DialogContent dividers={true}>
                <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="stretch"
                >
                    <Typography variant="subtitle1">
                        We have sent you an email with the temporary password to use in the Old Password field below.
            </Typography>
                    <Grid item xs={12}>
                        <Controller
                            as={<TextField
                                error={typeof errors[att.oldPassword.key] !== "undefined"}
                                className={props.classes.textWideField}
                                type="password"
                                margin="narrow"
                                label={att.oldPassword.label}
                                helperText={(errors[att.oldPassword.key] || { message: "" }).message}
                            />}
                            name={att.oldPassword.key}
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            as={<TextField
                                error={typeof errors[att.newPassword.key] !== "undefined"}
                                className={props.classes.textWideField}
                                type="password"
                                margin="narrow"
                                label={att.newPassword.label}
                                helperText={(errors[att.newPassword.key] || { message: "" }).message}
                            />}
                            name={att.newPassword.key}
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            as={<TextField
                                error={typeof errors[att.confirmPassword.key] !== "undefined"}
                                className={props.classes.textWideField}
                                type="password"
                                margin="narrow"
                                label={att.confirmPassword.label}
                                helperText={(errors[att.confirmPassword.key] || { message: "" }).message}
                            />}
                            name={att.confirmPassword.key}
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <Footer {...footerProps} />
        </>
    );
}


export const ChangePasswordModalForm = buildForm({ FormBody: ChangePasswordModalFormBody, Validation })
