import React, {useState} from 'react';
import HiddenIf from './HiddenIf'
import {isEqual } from 'lodash';


export class BackupImageLoader extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            errorCount: 0,
            hidden: false,
        }
        this.onImageError = this.onImageError.bind(this);
    }

    onImageError(e) {
        if(this.state.errorCount >= this.props.images.length){
            this.setState({
                ...this.state,
                hidden: true,
                
            })
        }else{
            this.setState({ 
                ...this.state,
                errorCount: this.state.errorCount + 1
            });
        }
      };

      shouldComponentUpdate(nextProps, nextState) {
          if(nextProps.safe){
            return !isEqual(nextProps.images,this.props.images) || this.state.errorCount !== nextState.errorCount
          }else{
            return true
          }
      }

    render() {
        return (
            <HiddenIf value={!this.state.hidden}>
                <img className={this.props.className} src={this.props.images[this.state.errorCount]} onError={this.onImageError} style={this.props.style} />
            </HiddenIf>
        );
    }
}
// export const BackupImageLoader = ({images, style={} ,className=null}) => {

//     const [imgError, setImgError] = useState({
//         errorCount: 0,
//         hidden: false
//     });

//     const onImageError = e => {
//         console.log("LoadingImageError")
//         if(imgError.errorCount >= images.length){
//             setImgError({
//                 ...imgError,
//                 hidden: true
//             })
//         }else{
//             setImgError({ 
//                 ...imgError,
//                 errorCount: imgError.errorCount + 1
//             });
//         }
//       };
//     return (
//         <HiddenIf value={!imgError.hidden}>
//             <img className={className} src={images[imgError.errorCount]} onError={onImageError} style={style} />
//         </HiddenIf>
//     );
// }

