import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import htmlParser from 'react-markdown/plugins/html-parser';
import HtmlToReact from 'html-to-react';
import { withRouter } from 'react-router-dom';
import { Link } from '../components/Link'
import queryString from 'query-string';
import { pushUrlQuery } from "react-url-query";
import * as User from "../services/user";

//import $ from 'jquery';

import { Grid, Hidden } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

import Breadcrumb from '../components/navigation/Breadcrumb'
import LoginInline from '../components/navigation/LoginInline';
import FeaturedLinks from '../components/FeaturedLinks'
import { getAssets } from '../microSiteConfig'
import HiddenIf from "../components/HiddenIf"
import { write } from 'fs';

const config = getAssets()

const styles = theme => ({
  rootdefault: {
    paddingTop: theme.spacing(3),
  },
  rootdefaultinner: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  rootfullwidth: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: "-24px",
    marginRight: "-24px",
    marginBottom: "-44px",
  }
});

var processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
const parseHtml = htmlParser({
  isValidNode: node => node.type !== 'script',
  processingInstructions: [
    {
      shouldProcessNode: function (node) {
        return node.name && node.name === 'link';
      },
      processNode: function (node, children, index) {
        return React.createElement(Link, { to: node.attribs.to }, node.attribs.val);
      },
    },
    {
      shouldProcessNode: function (node) {
        return true;
      },
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ]
})

const AuthOnlyCont = "<div class='noauthinfo noauthinfocms'><h5><img alt='icon' src='https://d3i7dklp9u03xa.cloudfront.net/icon-login-required.png'> Independent Optical Practice Access Only<em>Please log in to view this page</em></h5><p>This page is only available to independent optical practices. Please log in with your username and password to view this page.</p> <p>If you do not have a username or password, please get in touch with sales@optiqueline.com.au to request access.</p></div>";

class CMSPage extends React.Component {
  _isMounted = false;
  _currentPageIs = '';
  blogposts = [];
  postsPerPage = 12;
  currentPage = 1;

  constructor(props) {
    super(props);

    let qs_params = queryString.parse(this.props.location.search);

    if(qs_params.brandreset){
        delete qs_params.brandreset;
        pushUrlQuery(qs_params);                             
        window.location.reload();
    }
    this.state = { thecontent: null, layout: 'fullwidth' }
  }

  static currentPageVal(thispageis, params) {

    //if(thispageis === 'blog'){

     // thispageis = 'blog/stepper';

   // }

    if(thispageis === 'blogsingle'){

      thispageis = 'blogsingle/'+params.path1;

    }

    if(thispageis === 'blogcategory'){

      thispageis = 'blogcategory/'+params.path1;

    }



    if( thispageis === 'custompath' && params.path1 !== undefined ){

      thispageis = params.path1;

      if(params.path2 !== undefined ){
        thispageis = thispageis+'/'+params.path2;
      }

    }
    return thispageis;
  }


  loadblogcategorynav(){

    fetch('https://prod-optiqueline-258565051107-site-assets.s3.amazonaws.com/cms/blog/category/nav-blog-categories.json', {
        cache: 'no-cache',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => response.json())
        .then(data => {
          
          //var thesidecol = document.getElementById('blog-side-column');
          var thesidecol = document.getElementById('blog-nav-column');
          thesidecol.innerHTML = '<div id="blog-side-column-nav"><h3>Categories</h3>'+data.content+'</div>';  


      }).catch(err => {

          fetch('https://prod-optiqueline-258565051107-site-assets.s3.amazonaws.com/cms/pagejson/404.json', {
            cache: 'no-cache',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(response => response.json())
            .then(data => {
              this.setState({ thecontent: data.content, layout: data.layout });
            }).catch(err => {
              console.log('Failed to show the 404 message');
            });

      });

  }

  blogindextypeoutput(thedata){
    var thisHTML = [];

    thisHTML.push('<div class="blog-post-summary-wrapper">');

    thisHTML.push('<div class="blog-post-summary-col1">');
    if(thedata.img !== false){
      thisHTML.push('<img alt="',thedata.imgalt===false?'':thedata.imgalt,'" src="',thedata.img,'">');

    }
    thisHTML.push('</div>');

    thisHTML.push('<div class="blog-post-summary-col2">');
    thisHTML.push('<h3><a href="/blog/',thedata.slug,'/">',thedata.title,'</a></h3>');
    thisHTML.push('<div class="blog-post-summary-date">',thedata.date,'</div>');
    thisHTML.push('<div class="blog-post-summary-text">',thedata.excerpt,'</div>');
    thisHTML.push('<div class="blog-post-summary-readmore"><a href="/blog/',thedata.slug,'/">Read More</a></div>');
    thisHTML.push('</div>');

    thisHTML.push('</div>');  

    return thisHTML.join('');
  
  }

  blogindextypeoutputV2(thedata){
    var thisHTML = [];

    thisHTML.push('<div class="blog-post-summary-wrapper">');

    thisHTML.push('<a class="blog-post-summary-img-wrap',thedata.img?'':' noblogimg','" href="/blog/',thedata.slug,'/">');
    if(thedata.img !== false){

      //remove the 7 chars before the .jpg if fourth to last char before .jpg is x
      var imgsrc = thedata.img,
          finaldotindex = imgsrc.lastIndexOf('.'),
          lengthlessdot = imgsrc.length - finaldotindex,
          filetype = imgsrc.substr(imgsrc.length - lengthlessdot, lengthlessdot);
      if(imgsrc.substr(imgsrc.length - 8, 1) === 'x' ){
        imgsrc = imgsrc.substr(0,imgsrc.length - 12)+filetype;
      }

      //abcdefg.jpg

      thisHTML.push('<img alt="',thedata.imgalt===false?'':thedata.imgalt,'" src="',imgsrc,'">');

    } else {
      thisHTML.push('<img src="/ol-main-logo.png">');
    }
    thisHTML.push('</a>');

    thisHTML.push('<h3><a href="/blog/',thedata.slug,'/">',thedata.title,'</a></h3>');
    thisHTML.push('<div class="blog-post-summary-date">',thedata.date,'</div>');

    thisHTML.push('</div>');  

    return thisHTML.join('');
  
  }

  loadPosts(page) {
    // Clear the current posts
    var thisHTML = [];
    // Calculate the start and end indices for the current page
    let start = (page - 1) * this.postsPerPage;
    let end = start + this.postsPerPage;
    // Loop through the posts for the current page
    for(let i = start; i < end && i < this.blogposts.length; i++){
      thisHTML.push(this.blogindextypeoutputV2(this.blogposts[i]));
    }
    
    document.getElementById('blog-post-summaries-wrapper').innerHTML = thisHTML.join('');
  }


  handleDocumentClick = (event) => {
    var tgt = event.target;
    if(tgt.classList.contains('blog-page-link') && !tgt.classList.contains('blog-page-link-active')) {
      // Get the page number from the clicked link
      let clickedPage = parseInt(tgt.getAttribute('data-page'));
      // Call the function to load the posts for the clicked page
      this.loadPosts(clickedPage);

      // get all .blog-page-link and remove class blog-page-link-active and then apply class blog-page-link-active to the tgt
      var allPageLinks = document.querySelectorAll('.blog-page-link');
      allPageLinks.forEach(function(link) {
        link.classList.remove('blog-page-link-active');
      }
      );
      tgt.classList.add('blog-page-link-active');

      //smooth scroll to #blog-post-summaries-wrapper
      document.getElementById('blog-post-summaries-wrapper').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});

    }
  }

  loadblogmainindex(){

    fetch('https://prod-optiqueline-258565051107-site-assets.s3.amazonaws.com/cms/blog/full-index.json', {
        cache: 'no-cache',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => response.json())
        .then(data => {
          
          var thesidecol = document.getElementById('blog-main-column'),
             // thisHTML = ['<div class="blog-post-summaries-wrapper">'];
             thisHTML = ['<div id="blog-banner-wrap"><img class="ol_section_background_image_section_background-image" srcset="https://d3i7dklp9u03xa.cloudfront.net/wp-media/uploads/2024/04/new24blogheader-768x471.jpg 768w, https://d3i7dklp9u03xa.cloudfront.net/wp-media/uploads/2024/04/new24blogheader-1024x628.jpg 1024w, https://d3i7dklp9u03xa.cloudfront.net/wp-media/uploads/2024/04/new24blogheader.jpg 1440w" sizes="(max-width: 768px) 100vw, (max-width: 1024px) 100vw, 1440px" src="https://d3i7dklp9u03xa.cloudfront.net/wp-media/uploads/2024/04/new24blogheader-768x471.jpg" alt="-"></div><div id="blogmain-intro"><h1>The Spectacle</h1><p>Welcome to our blog, where eyewear isn\'t just about seeing the world, but being seen. Here, we uncover the latest trends, share expert styling tips, and offer invaluable retail insights, all designed to elevate your optical experience. Join us as we explore the dynamic intersection of fashion, function, and forward-thinking design on The Spectacle.</p></div><div id="blog-post-summaries-wrapper" class="blog-post-summaries-wrapper">'];
        
            this.blogposts = data.posts;

            const totalPages = Math.ceil(this.blogposts.length / this.postsPerPage);

            // Calculate the start and end indices for the current page
            let start = (this.currentPage - 1) * this.postsPerPage;
            let end = start + this.postsPerPage;

            // Loop through the posts for the current page
            for(let i = start; i < end && i < this.blogposts.length; i++){
              thisHTML.push(this.blogindextypeoutputV2(data.posts[i]));
            }

          thisHTML.push('</div>');

          thisHTML.push('<div class="blog-posts-paging">');
          // Add paging elements
          for(let i = 1; i <= totalPages; i++){
            if(i === this.currentPage){
              // If this is the current page, add a non-clickable element
              thisHTML.push(`<div class="blog-page-link blog-page-link-active" data-page="${i}">${i}</div>`);
            } else {
              // If this is not the current page, add a clickable element
              thisHTML.push(`<div class="blog-page-link" data-page="${i}">${i}</div>`);
            }
          }
          thisHTML.push('</div>');

          thesidecol.innerHTML = thisHTML.join('');

      }).catch(err => {

          fetch('https://prod-optiqueline-258565051107-site-assets.s3.amazonaws.com/cms/pagejson/404.json', {
            cache: 'no-cache',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(response => response.json())
            .then(data => {
              this.setState({ thecontent: data.content, layout: data.layout });
            }).catch(err => {
              console.log('Failed to show the 404 message');
            });

      });

  }

  loadblogcategoryindex(thecat){

    fetch('https://prod-optiqueline-258565051107-site-assets.s3.amazonaws.com/cms/blog/category/categoryposts-'+thecat+'.json', {
        cache: 'no-cache',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => response.json())
        .then(data => {
          
          var thesidecol = document.getElementById('blog-main-column'),
          //thisHTML = ['<h1>Category: '+data.catname+'</h1><div class="blog-post-summaries-wrapper">'];
          thisHTML = ['<div id="blog-banner-wrap"><img class="ol_section_background_image_section_background-image" srcset="https://d3i7dklp9u03xa.cloudfront.net/wp-media/uploads/2024/04/new24blogheader-768x471.jpg 768w, https://d3i7dklp9u03xa.cloudfront.net/wp-media/uploads/2024/04/new24blogheader-1024x628.jpg 1024w, https://d3i7dklp9u03xa.cloudfront.net/wp-media/uploads/2024/04/new24blogheader.jpg 1440w" sizes="(max-width: 768px) 100vw, (max-width: 1024px) 100vw, 1440px" src="https://d3i7dklp9u03xa.cloudfront.net/wp-media/uploads/2024/04/new24blogheader-768x471.jpg" alt="-"></div><div id="blogmain-intro"><h1>Category: ',data.catname,'</h1></div><div id="blog-post-summaries-wrapper" class="blog-post-summaries-wrapper">'];
              //thisHTML = ['<h1>Category: '+data.catname+'</h1><div class="blog-post-summaries-wrapper">'];
          

              this.blogposts = data.posts;

              const totalPages = Math.ceil(this.blogposts.length / this.postsPerPage);
  
              // Calculate the start and end indices for the current page
              let start = (this.currentPage - 1) * this.postsPerPage;
              let end = start + this.postsPerPage;
  
              // Loop through the posts for the current page
              for(let i = start; i < end && i < this.blogposts.length; i++){
                thisHTML.push(this.blogindextypeoutputV2(data.posts[i]));
              }
  
            thisHTML.push('</div>');
  
            thisHTML.push('<div class="blog-posts-paging">');
            // Add paging elements
            for(let i = 1; i <= totalPages; i++){
              if(i === this.currentPage){
                // If this is the current page, add a non-clickable element
                thisHTML.push(`<div class="blog-page-link blog-page-link-active" data-page="${i}">${i}</div>`);
              } else {
                // If this is not the current page, add a clickable element
                thisHTML.push(`<div class="blog-page-link" data-page="${i}">${i}</div>`);
              }
            }
            thisHTML.push('</div>');
  
            thesidecol.innerHTML = thisHTML.join('');

      }).catch(err => {

          fetch('https://prod-optiqueline-258565051107-site-assets.s3.amazonaws.com/cms/pagejson/404.json', {
            cache: 'no-cache',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(response => response.json())
            .then(data => {
              this.setState({ thecontent: data.content, layout: data.layout });
            }).catch(err => {
              console.log('Failed to show the 404 message');
            });

      });

  }


  loadblogsingle (thispost){
    fetch('https://prod-optiqueline-258565051107-site-assets.s3.amazonaws.com/cms/blog/'+thispost+'.json', {
      cache: 'no-cache',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
      .then(data => {
        
        var thesidecol = document.getElementById('blog-main-column'),
            //thisHTML = ['<div class="blog-post-single-wrapper"><h1>'+data.title+'</h1><div class="blog-post-single-date">',data.date,'</div>'];
            thisHTML = ['<div class="blog-post-single-wrapper"><h1>'+data.title+'</h1><div class="blog-post-single-date">',data.date,'</div><div class="blog-post-single-content">'];
        

        thisHTML.push(data.content);

        //thisHTML.push('</div>');
        thisHTML.push('</div></div>');
        thesidecol.innerHTML = thisHTML.join('');

    }).catch(err => {

        fetch('https://prod-optiqueline-258565051107-site-assets.s3.amazonaws.com/cms/pagejson/404.json', {
          cache: 'no-cache',
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(response => response.json())
          .then(data => {
            this.setState({ thecontent: data.content, layout: data.layout });
          }).catch(err => {
            console.log('Failed to show the 404 message');
          });

    });

  }

  loadPageContent (thispageis){

    this._currentPageIs = thispageis;

    if(thispageis.indexOf('ollogsec28') > -1){
      thispageis='404';
    }
    if (thispageis === "authonly") {
      if (this._isMounted) {
        this.setState({ thecontent: AuthOnlyCont, layout: 'fullwidth' });
        var loginbox = document.getElementById('cmsinlinelogin');
        loginbox.style.display = 'block';
      }
    } else if (thispageis.indexOf('blog') === 0 ){//'blogindex' || thispageis === 'blogsingle' || thispageis === 'blogcategory'){

     //let contentZones = '<div id="blog-columns"><div id="blog-main-column">loading content...</div><div id="blog-side-column">loading content...</div></div>';
      let contentZones = '<div id="blog-main-wrap"><div id="blog-main-column">loading content...</div><div id="blog-nav-column">loading content...</div></div>';


      this.setState({ thecontent: contentZones, layout: 'fullwidth' });

      this.loadblogcategorynav();

      if(thispageis === 'blogindex'){
        this.loadblogmainindex();
      }
      else if(thispageis.indexOf('blogcategory') === 0){

        var thiscat = thispageis.split('/')[1];
        this.loadblogcategoryindex(thiscat);

      } else if(thispageis.indexOf('blogsingle') === 0){//is blog single

        var thispost = thispageis.split('/')[1];
          this.loadblogsingle(thispost);
      }

    } else {
      fetch('https://prod-optiqueline-258565051107-site-assets.s3.amazonaws.com/cms/pagejson/'+thispageis+'.json', {
        cache: 'no-cache',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => response.json())
        .then(data => {

          if(data.loginreq){

            this.setState({ thecontent: '<div>Loading...</div>', layout: data.layout });

            User.getUser()
            .then(user => {
              
              var part2 = this.adjtstmp(data.tstmp);
              this.checkforcontent(thispageis+'-ollogsec28'+part2);

            }).catch(err => {
              this.setState({ thecontent: AuthOnlyCont, layout: 'fullwidth' });
              var loginbox = document.getElementById('cmsinlinelogin');
        loginbox.style.display = 'block';
            });;


          } else {
            this.setState({ thecontent: data.content, layout: data.layout }, this.loadAnyForms);
            if(window.olCmsPagesScriptsLoaded === undefined){
              
              let jqueryscript = document.createElement("script");
              jqueryscript.src = "https://code.jquery.com/jquery-3.5.1.min.js?ver=1.12.4-wp";
              jqueryscript.async = true;
              jqueryscript.onload = () => this.jqLoaded();
              document.body.appendChild(jqueryscript);

              window.olCmsPagesScriptsLoaded = 1;

            }

            document.body.classList.add('isCMSPage');

        }

      }).catch(err => {

          fetch('https://prod-optiqueline-258565051107-site-assets.s3.amazonaws.com/cms/pagejson/404.json', {
            cache: 'no-cache',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(response => response.json())
            .then(data => {
              this.setState({ thecontent: data.content, layout: data.layout });
            }).catch(err => {
              console.log('Failed to show the 404 message');
            });

      });
      
    }

  }

  adjtstmp = function(tstmp){
    return tstmp/3;
  }

  checkforcontent = function(thispageis){

    fetch('https://prod-optiqueline-258565051107-site-assets.s3.amazonaws.com/cms/pagejson/'+thispageis+'.json', {
              cache: 'no-cache',
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
            }).then(response => response.json())//.then(data => new Promise(resolve => setTimeout(() => resolve(data), 3000)))
              .then(data => {
                this.setState({ thecontent: data.content, layout: data.layout }, this.loadAnyForms);

                if(window.olCmsPagesScriptsLoaded === undefined){
              
                  let jqueryscript = document.createElement("script");
                  jqueryscript.src = "https://code.jquery.com/jquery-3.5.1.min.js?ver=1.12.4-wp";
                  jqueryscript.async = true;
                  jqueryscript.onload = () => this.jqLoaded();
                  document.body.appendChild(jqueryscript);
    
                  window.olCmsPagesScriptsLoaded = 1;
    
                }

                document.body.classList.add('isCMSPage');

              }).catch(err => {
                fetch('https://prod-optiqueline-258565051107-site-assets.s3.amazonaws.com/cms/pagejson/404.json', {
                  cache: 'no-cache',
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json'
                  }
                }).then(response => response.json())
                  .then(data => {
                    this.setState({ thecontent: data.content, layout: data.layout });
                  }).catch(err => {
                    console.log('Failed to show the 404 message');
                  });

                });
                


  }


  loadAnyForms = function(){

    var anyForms = document.getElementsByClassName('pb-form-add');
    window.pbFormsToLoad = 0;
    for( var i =0, k = anyForms.length; i<k; i++) {

      let thisdiv =  anyForms[i],
        thisformid = thisdiv.dataset.formid;
        window.pbFormsToLoad++;
      fetch('https://prod-optiqueline-258565051107-site-assets.s3-ap-southeast-2.amazonaws.com/cms/pagejson/forms/pb_form_'+thisformid+'.json', {
        cache: 'no-cache',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => response.json())
        .then(data => {
          thisdiv.innerHTML = data.content;
          thisdiv.classList.add('pb-form-add-loaded');
          window.pbFormsToLoad--;
        }).catch(err => {

          console.log(err);
          console.log('Failed to load form');

        });

        //break;

    } 

    this.reInitPageFeatures();

  }

  reInitPageFeatures = function(){

    window.reInitFeaturesInterval = setInterval(function(){

      if(window.lateinitPBcustomSliderInit !== undefined){

        window.lateinitPBcustomSliderInit();

        clearInterval(window.reInitFeaturesInterval);

      }

    },200);


    window.reInitFeaturesIntervalForms = setInterval(function(){

      if(window.pbFormsToLoad <= 0 && window.lateinitPBcustomFormsInit !== undefined){

        window.lateinitPBcustomFormsInit();

        clearInterval(window.reInitFeaturesIntervalForms);
      }
    
    },200);

  }





  componentDidUpdate(){

    const { match: { params } } = this.props;

    let thispageis = CMSPage.currentPageVal(this.props.page,params);

    if(this._currentPageIs !== thispageis){
      this.loadPageContent(thispageis);
    }

  }

  componentDidMount() {
    this._isMounted = true;

    this.jqLoaded = function() {

      let slickscript = document.createElement("script");
      slickscript.src = "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.js?ver=5.5.3";
      slickscript.async = true;
      slickscript.onload = () => this.scriptLoaded();

      document.body.appendChild(slickscript);

      let csslink = document.createElement('link'); 
      csslink.rel = 'stylesheet';  
      csslink.type = 'text/css'; 
      csslink.href = 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick-theme.min.css?ver=5.5.3';  

      document.body.appendChild(csslink);
      let csslink2 = document.createElement('link'); 
      csslink2.rel = 'stylesheet';  
      csslink2.type = 'text/css'; 
      csslink2.href = 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.css?ver=5.5.3';  

      document.body.appendChild(csslink2);     

      let csslink3 = document.createElement('link'); 
      csslink3.rel = 'stylesheet';  
      csslink3.type = 'text/css'; 
      csslink3.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.min.css?ver=5.5.3';  

      document.body.appendChild(csslink3);  
      
    };

    this.scriptLoaded = function() {

      let custscript = document.createElement("script");
      custscript.src = "https://prod-optiqueline-258565051107-site-assets.s3-ap-southeast-2.amazonaws.com/cms/lateinit-pb-custom.js?ver=0.17";//https://d3i7dklp9u03xa.cloudfront.net/cms/
      custscript.async = true;
      //custscript.onload = () => this.scriptLoaded();

      document.body.appendChild(custscript);

    };

    const { match: { params } } = this.props;
    //console.log('params params params',params);

    let thispageis = CMSPage.currentPageVal(this.props.page, params);

    /*if(thispageis === 'blog'){

      thispageis = 'blog/stepper';

    }

    if( thispageis === 'custompath' && params.path1 !== undefined ){

      thispageis = params.path1;

      if(params.path2 !== undefined ){
        thispageis = thispageis+'/'+params.path2;
      }

    }*/
    this.loadPageContent(thispageis);

    document.addEventListener('click', this.handleDocumentClick);
    
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.body.classList.remove('isCMSPage');

    document.removeEventListener('click', this.handleDocumentClick);

  }

  render() {
    const thelayout = this.state.layout;
    return (
      <div>
      { thelayout === 'fullwidth' || thelayout === 'fullwidthold' ?
      <div>
      <div className={`CMSPage-root-wrapper CMSPage-root-wrapper-${thelayout} ${this.props.classes.rootfullwidth}`} dangerouslySetInnerHTML={{__html: this.state.thecontent}} />
        <div id="cmsinlinelogin">
          <LoginInline />
        </div>
      </div>
      :

      <div className={`CMSPage-root-wrapper ${this.props.classes.rootdefault}`}>
        <Grid container spacing={3}>
          <HiddenIf value={typeof config.sideColumnLinks1 !== "undefined" && typeof config.sideColumnLinks1 !== "undefined"}>
            <Hidden smDown>
              <Grid item md={3}>
                <FeaturedLinks
                  featuredStubs={[...config.sideColumnLinks1, ...config.sideColumnLinks2]}
                  direction='column'
                />
              </Grid>
            </Hidden>
          </HiddenIf>
          <Grid item md={9} sm={12} >
            <div className={`CMSPage-root-wrapper ${this.props.classes.rootdefaultinner}`} dangerouslySetInnerHTML={{__html: this.state.thecontent}} />
          </Grid>
        </Grid>

      </div>
  }
</div>
      
    )
  }
}


export default withRouter(withStyles(styles)(CMSPage));