import React, { Component } from 'react';
import {isEqual } from 'lodash';

export const ForEachView = ({component: Component, items}) => {
    const keys = Object.keys(items)
    return (
        <>
            {keys.map( (key) => {
                let value = items[key];
                if(typeof value !== "object"){
                    value = {value}
                }
                return (
                    <Component {...value} index={key} key={key}/>
                )
            })}
        </>
    );
}

export class ForEach extends Component {
    constructor(props){
        super(props)
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(nextProps.safe){
            return !isEqual(nextProps.items,this.props.items)
        }else{
            return true
        }
    }

    render(){
        return (
            <ForEachView items={this.props.items} component={this.props.component}/>
        )
    }
}

