import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class AutoScrollTop extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            try {
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: 'smooth',
                });
              } catch (error) {
                // just a fallback for older browsers
                window.scrollTo(0, 0);
              }
        }
    }

    render() {
        return <></>
    }
}

export default withRouter(AutoScrollTop)