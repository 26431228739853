import {
  cmsplaceholder
} from "./cms.utils";

import { CMSActionTypes } from "./cms.types";
import { INITIAL_STATE } from "./cms.state";

const cmsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CMSActionTypes.IS_FETCHING:
      return {
        ...state,
        isFetching: true
      };
    case CMSActionTypes.NOT_FETCHING:
      return {
        ...state,
        isFetching: false
      };
    case CMSActionTypes.CLEAR_CMS: {
      return INITIAL_STATE;
    }
    case CMSActionTypes.UPDATE_LOGO: {
      var data = {...state};
      data["logo"] = action.data.logo;
      return data;
    }
    default:
      return state;
  }
};

export default cmsReducer;
