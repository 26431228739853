const emailChecks = [];
const priceChecks = [];

const addressChecks = (nested = "") => ({
  firstname: {
    label: `${nested} First Name`,
    type: "field",
    checks: [NotNull(), CharLength(1)]
  },
  middlename: {
    label: `${nested} Middle Name`,
    type: "field",
    checks: [NotNull(), CharLength(1)]
  },
  lastname: {
    label: `${nested} Last Name`,
    type: "field",
    checks: [NotNull(), CharLength(1)]
  },
  street1: {
    label: `${nested} Street1`,
    type: "field",
    checks: [NotNull(), CharLength(1)]
  },
  street2: {
    label: `${nested} Street2`,
    type: "field",
    checks: [NotNull(), CharLength(1)]
  },
  city: {
    label: `${nested} City`,
    type: "field",
    checks: [NotNull(), CharLength(1)]
  },
  region: {
    label: `${nested} Region`,
    type: "field",
    checks: [NotNull(), CharLength(1)]
  },
  postcode: {
    label: `${nested} Postcode`,
    type: "field",
    checks: [NotNull(), CharLength(1)]
  },
  phonenumber: {
    label: `${nested} Phone Number`,
    type: "field",
    checks: [NotNull(), CharLength(1)]
  },
  country: {
    label: `${nested} Country`,
    type: "field",
    checks: [NotNull(), CharLength(1)]
  },
  company: {
    label: `${nested} Company`,
    type: "field",
    checks: [NotNull(), CharLength(1)]
  }
});

const PriceLimit = limit => id => arg => {};

const Price = () => {};

const Limit = () => {};

const NotNull = () => id => arg => {
  if (typeof arg === "undefined" || arg === null) {
    throw new Error(`${id} can not be empty.`);
  }
};

const CharLength = length => id => arg => {
  if (arg.length < length) {
    throw new Error(`${id} must be longer than ${length} characters.`);
  }
};

const orderChecks = {
  billingAddress: {
    type: "object",
    checks: addressChecks()
  },
  shippingAddress: {
    type: "object",
    checks: addressChecks()
  },
  recipients: {
    type: "array",
    checks: [...emailChecks]
  },
  order: {
    type: "object",
    checks: {
      grandtotal: {
        type: "field",
        label: "",
        checks: [NotNull(), CharLength(1), Price(), PriceLimit(0)]
      },
      shippingMethod: {
        type: "field",
        label: "",
        checks: [NotNull(), CharLength(1)]
      },
      labDirectKey: {
        type: "field",
        label: "",
        checks: [NotNull(), CharLength(1)]
      },
      labDirectValue: {
        type: "field",
        label: "",
        checks: [NotNull(), CharLength(1)]
      },
      storeDirectRef: {
        type: "field",
        label: "",
        checks: [NotNull(), CharLength(1)]
      },
      storeDirectComments: {
        type: "field",
        label: "",
        checks: [NotNull(), CharLength(0)]
      }
    }
  },
  details: {
    type: "object",
    checks: {
      labDirectQuantity: {
        type: "field",
        label: "",
        checks: [NotNull(), Limit(0, -1)]
      },
      storeDirectQuantity: {
        type: "field",
        label: "",
        checks: [NotNull(), Limit(0, -1)]
      }
    }
  }
};
export const validateOrder = (payload, reThrow = false) => {
  try {
    validate(orderChecks, payload);
  } catch (e) {
    if (reThrow) {
      throw e;
    }
    return e.message;
  }
};

export const _validate = (definition, key, value) => {
  const rules = definition[key];

  const type = rules.type;

  if (type === "field") {
    const checks = rules.checks;
    const id = rules.label;
    checks.map(check => {
      RegexCheck(check, id, value);
    });
  } else if (type === "array") {
  } else if (type === "object") {
    validate(definition[key].checks, value);
  }
};
export const validate = (definition, data) => {
  Object.keys(definition).map(key => {
    if (key in data) {
      _validate(definition, key, data[key]);
    } else {
      throw new Error(`${addressChecks[key].label} should not be empty.`);
    }
  });
};
export const validateAddress = (address, reThrow = false) => {
  try {
    validate(addressChecks(), address);
  } catch (e) {
    if (reThrow) {
      throw e;
    }
    return e.message;
  }
};

const RegexCheckTypes = {
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  "phone number": {
      aud: /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/,
      nz: /^(\(0\d\)\d{7}|\(02\d\)\d{6,8}|0800\s\d{5,8})$/
  }
};
const RegexCheck = type => id => arg => {
  if (!RegexCheckTypes[type].test(arg)) {
    throw new Error(`${id} must be of type ${type}.`);
  }
};
