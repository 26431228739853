import {AssetBucket} from '../default/assets'
import { getNavigationPaths } from "./navigation"



export const getAssets = () => {
    const paths = getNavigationPaths()
return {
    logo: `${AssetBucket}/steppereyewear.com.au/stepper-logo.png`,
    carousel: [
        {
            src:`${AssetBucket}/steppereyewear.com.au/slide2_1.png`,
            link: null
        }
    ],
    homeGridRow2: [
        {
            img: `${AssetBucket}/steppereyewear.com.au/ac539b5a-6a75-456c-ac5e-c8d5549ba256.png.jpg`,
            title: "",
            link: paths.stepperSI
        },
        {
            img: `${AssetBucket}/steppereyewear.com.au/84074785-78e5-40be-8c7a-cf5e3260c133.png.jpg`,
            title: "",
            link: paths.stepperSIFemale
        },
        {
            img: `${AssetBucket}/steppereyewear.com.au/26f38a1b-ebbb-49a7-899d-ef790a587d76.jpg`,
            title: "",
            link: paths.stepperSIMale
        },
        {
            img: `${AssetBucket}/steppereyewear.com.au/10814fbc-fc2e-47a8-80d4-9cfb91d553e3.jpg`,
            title: "",
            link: paths.stepperSIMale
        }
    ],
    homeMiddleSentenceText: 
      {
        label: ''
      }
    ,
    footerLogo: require("../../assets/img/people-footer.png"),
    sideColumnLinks1: [
        {
            img: `${AssetBucket}/homepage/home-row4-partnerprogram.jpg`,
            title: "INVENTORY MANAGEMENT SYSTEM",
            link: '/inventory-management-system/'
        },
        {
            img: `${AssetBucket}/homepage/home-row4-labdirect.jpg`,
            title: "LAB DIRECT",
            link: '/lab-direct/'
        },
        {
            img: `${AssetBucket}/homepage/home-row2-marketingsupport.jpg`,
            title: "MARKETING SUPPORT",
            link: paths.marketingSupport
        }
    ],
    sideColumnLinks2: [
        {
            img: `${AssetBucket}/homepage/1.jpg`,
            link: paths.convertiblesPromo
        },
        {
            img: `${AssetBucket}/homepage/2.jpg`,
            link: paths.stepperPromo
        }
    ],
    optional_text:"stepper_innovation",
    description: [
        {
            type: "h1",
            body: "helkle"
        }
    ],
    landingButtonRow: [
        {
            label: 'Find A Stockist',
            link: paths.findAStockist
        },
        {
            label: 'Shop the Range',
            link: paths.shop
        }
    ],
}
}
