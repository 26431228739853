//@ts-check

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AdjustableTable } from "../components/adjustable-table/AdjustableTable";
import CustomButton from "../components/custom-button/custom-button.component";
import { makeStyles } from "@material-ui/core/styles";
import { createStructuredSelector } from "reselect";
import PastOrderTable from "../components/PastOrderTable"
import { 
  AddressForm,
  UserInfoForm,
  ChangePasswordForm,
  mapOutput 
} from "../components/accountForms/";
import {
  selectUserData,
  selectUpdateInfoState,
  selectLoginModalStatus,
  selectUserNewsletter,
  selectShippingInfo,
  selectBillingInfo,
  selectPastOrders,
  selectWishList
} from "../redux/user/user.selectors";
import {
  updateUserData,
  saveUserData,
  resetUserData,
  changePasswordAccountPage,
  loadPastOrders
} from "../redux/user/user.actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Checkbox, Grid, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import HiddenIf from "../components/HiddenIf";
import { useForm } from 'react-hook-form';

const orders = [
  {
    orderId: "1000111",
    date: "6/02/2020",
    shipTo: "Bevan Robertson-Christie",
    orderTotal: "1232.22",
    status: ""
  },
  {
    orderId: "1000112",
    date: "6/02/2020",
    shipTo: "Bevan Robertson-Christie",
    orderTotal: "1232.22",
    status: ""
  }
];

const useStyles = makeStyles(theme => ({
  Page: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  edit: {
    color: "#34b3d2",
    fontSize: "0.875em",
    "&:hover": {
      color: "#333",
      cursor: "default"
    },
    "&:visited": {
      color: "#34b3d2"
    }
  },
  info: {
    display: "flex",
    flexDirection: "column",
    margin: "0px"
  },
  formFields: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    marginBottom: "20px",
    formHeading: {
      padding: "10px 15px",
      borderRadius: "3px",
      backgroundColor: "#212121",
      fontSize: "25px",
      color: "#fff",
      marginBottom: "10px",
      textAlign: "center"
    },
    "& input": {
      marginBottom: "10px",
      padding: "10px",
      textAlign: "center"
    }
  },
  buttonContainer: {
    display: "flex",
    alignContent: "center",
    margin: "1em"
  }
}));

const Uatt = {
  firstname: "firstname",
  middlename: "middlename",
  lastname: "lastname",
  email: "email",
  courier: "courier",
  newsletter: "newsletter",
  shippingAddress: "shippingAddress",
  billingAddress: "billingAddress"
};





function Account(props) {
  const classes = useStyles();

  useEffect(() => {
    console.log("loading past orders")
    props.loadPastOrders()
  },[true])


  const updateUserAddress = (address) => data => {
    props.saveUserData({ ...props.userInfo, [address]: data })
  };

  const updateUser = data => {
    const userData = { ...props.userInfo, ...data }
    console.log(userData)
    props.saveUserData({ ...props.userInfo, ...data })
  }

  const changePassword = data => {
    props.changePasswordAccountPage(data["currentPassword"], data["newPassword"]);
  };




  return (
    <div className="cart-and-review">
      <Grid container spacing={2} className={classes.Page}>
        <h1>Dashboard</h1>
        <Grid item>
          <Typography variant="body2">Hello, {props.userInfo.firstname || "User"}!</Typography>
          <Typography variant="body2">
            From your My Account Dashboard you have the ability to view a snapshot
            of your recent account activity and update your account information.
            Select a link below to view or edit information.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Recent Orders</Typography>
          <PastOrderTable
            rows={props.pastOrders}
            />
        </Grid>
        <Grid item container>
          <Grid item xs={12}>
            <Typography variant="h5">Account Information</Typography>
          </Grid>
          
          <Grid item xs={12} md={6}>
          <UserInfoForm
            title={"User Info"}
            data={props.userInfo}
            onSubmit={updateUser}
          />
          </Grid>
          <Grid item xs={12} md={6}>
          
            <ChangePasswordForm
              title={"Security"}
              onSubmit={changePassword}
              />

          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={12}>
            <Typography variant="h5">Address Book</Typography>
          </Grid>
          <Grid item xs={12} md={6} >
              <AddressForm
                title={"Default Billing Address"}
                data={props.userInfo.billingAddress}
                onSubmit={updateUserAddress(Uatt.billingAddress)}
              />
          
          </Grid>
          <Grid item xs={12} md={6} >
              <AddressForm
                title={"Default Shipping Address"}
                data={props.userInfo.shippingAddress}
                onSubmit={updateUserAddress(Uatt.shippingAddress)}
              />
          
          </Grid>
        </Grid>
        <WishList wishList={props.wishList} />
      </Grid>
      </div>
  );
}

const WishList = props => {
  console.log("wishlist=", props.wishList);
  return (
    <>
      <Grid item container>
        <Grid item xs={12}>
          <Typography variant={"h5"}>My Wishlist</Typography>
        </Grid>
        <Grid item xs={12}>
          <HiddenIf value={Object.keys(props.wishList).length !== 0}>
            <AdjustableTable rowItems={props.wishList} isWishListTable/>
          </HiddenIf>
          <HiddenIf value={Object.keys(props.wishList).length === 0}>
            <Typography variant="body2">Your wishlist is currently empty. Please <Link className="link" to="/shop">browse the product catalogue</Link> to add items.</Typography>
          </HiddenIf>
        </Grid>
      </Grid>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  wishList:selectWishList,
  shippingAddress: selectShippingInfo,
  billingAddress: selectBillingInfo,
  userInfo: selectUserData,
  changePasswordState: selectLoginModalStatus,
  updateInfoState: selectUpdateInfoState,
  userNewsletter: selectUserNewsletter,
  pastOrders: selectPastOrders
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateUserData,
      saveUserData,
      resetUserData,
      changePasswordAccountPage,
      loadPastOrders
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Account);
