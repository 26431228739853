import { NonRetryableError } from "@aws-amplify/core";

export const getTheme = () => {
  return {
    rootclass: 'merseybeateyewearcomau',
    overrides: {
      MuiButton: {
        text: {
          outline: "none"
        },
        root: {
          outline: "none",
          "&:visited": {
            outline: "none"
          },
          "&:active": {
            outline: "none"
          },
          "&:hover": {
            outline: "none"
          },
          "&:focus": {
            outline: "none"
          }
        }
      }
    },
    palette: {
      primary: {
        main: "#000000",
        color: "#ffffff",
        text: "#ffffff",
        contrastText: "#fff"
      }, // blue
      secondary: {
        main: "#f3a226",
        color: "#ffffff",
        text: "#ffffff",
        contrastText: "#ffffff"
      }, // gold

      background: {
        default: "#333"
      },
      button: {
        variant: "contained",
        textDecoration: "none",
        width: "100%"
      },
      catalogBox: {
        background: "rgb(153,153,153)",
        alignItems: "center",
        height: "120px"
      },
      catalogTextBox: {
        alignItems: "center",
        textAlign: "center",
        margin: "auto",
        transform: "translateY(-90px)",
        color: "white",
        fontSize: "29px"
      },
      catalogImageBox: {
        alignItems: "center",
        transform: "translateY(-100px)",
        background: "white",
        height: "150px",
        width: "150px",
        borderRadius: "50%",
        margin: "auto",
        position: "relative",
        zIndex: 1
      },
      catalogImage: {
        paddingTop: "55px",
        margin: "auto",
        width: "140px"
      }
    },
    logowrapper: {
      position: 'relative',
      width: 170,
      '& a': {
        position: 'absolute',
        top: -25,
        left: -20,
        '& img': {
          width: 140,
          maxWidth: 'none',
          maxHeight: 'none',
          marginRight: 0,
          marginLeft: 30,
          '&.img-logo-small': {
            width: 100,
            marginLeft: 20
          }
        }
      }
    },
    micrositefooter: {
      paddingBottom: 20
    }
  };
};
