import React from 'react';



const HiddenIf = (props) => {
  return (
    <>
        { props.value === true ? (
            <>
                {props.children}
            </>
        ) : null } 
    </>
  );
}

  
export default HiddenIf;
