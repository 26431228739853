import React, { useState } from "react";
import CustomButton from "../custom-button/custom-button.component";
import { buildForm } from "./BaseForm";

import { makeStyles, Box, Typography, Grid,Checkbox } from "@material-ui/core";
import HiddenIf from "../HiddenIf";
import * as yup from 'yup';


const useStyles = makeStyles({
  formHeading: {
    padding: "10px",
    borderRadius: "3px",
    backgroundColor: "#212121",
    marginBottom: "10px",
    width: "100%"
  },
  formHeadingText: {
    paddingLeft: "20px",
    margin: "auto",
    textAlign: "center",
    width: "100%",
    fontSize: "25px",
    color: "#fff",


  },
  edit: {
    color: "#34b3d2",
    fontSize: "0.875em",
    "&:hover": {
      color: "#34b3d2"
    }
  },
  formField: {
    padding: "8px",
  },
  formInput: {
    marginBottom: "10px",
    padding: "10px",
    textAlign: "center",
    width: "100%"
  }
});



export const att = {
currentPassword: {key:"currentPassword",label:"Current Password"},
newPassword: {key:"newPassword",label:"New Password"},
newPassword2: {key:"newPassword2",label:"Confirm Password"},
}


export const Validation = yup.object().shape({
  [att.currentPassword.key]: yup.string().required().label(att.currentPassword.label), 
  [att.newPassword.key]: yup.string().required().label(att.newPassword.label), 
  [att.newPassword2.key]: yup.string().equalTo(yup.ref(att.newPassword.key)).required().label(att.newPassword2.label), 
})



export const ChangePasswordFormBody = ({ 
  data = {}
, title
, disabled = false
, display = true 
, saveAble = true
, prefix=""
, validation: {
  reset,
  register,
  errors
} = {
  reset: ((e) => null),
  register: null,
  errors: {}
}

,...props 
}) => {
  const classes = useStyles();
  const [ isDisplay , setIsDisplay  ] = useState(display)



  const handleEdit = (event) => {
    if(display){
      setIsDisplay(false)
    }
    event.preventDefault()
  }
  const handleCancel = (event) => {
    if(display){
      setIsDisplay(true)
    }
    reset(event)
  }
  
    return (
      <Box className={classes.formField} >
        <Typography variant="h6">{title}</Typography>
      <Box className={classes.spanContainer}>
      <HiddenIf value={isDisplay && !disabled && display}>
        <span className={classes.edit} onClick={(event) => handleEdit(event)}>
          Edit
        </span>
       
      </HiddenIf>
      &nbsp;&nbsp;
      <HiddenIf value={!isDisplay && !disabled}>
        <span className={classes.edit} onClick={(event) => handleCancel(event)}>
          {display ? "Close" : "Reset"}
       </span>
      </HiddenIf>
    </Box>
    <HiddenIf value={!isDisplay}>
        <>
        <HiddenIf value={typeof errors[att.currentPassword.key] !== "undefined" }>
                <Typography variant="subtitle2" style={{color: "red", fontSize: "15px"}}>{(errors[att.currentPassword.key] || {}).message}</Typography>
              </HiddenIf>
          <input
            className={classes.formInput}
            type="password"
            name={att.currentPassword.key}
            ref={register}
            defaultValue=""
            placeholder={att.currentPassword.label}
          />
          <HiddenIf value={typeof errors[att.newPassword.key] !== "undefined" }>
                <Typography variant="subtitle2" style={{color: "red", fontSize: "15px"}}>{(errors[att.newPassword.key] || {}).message}</Typography>
              </HiddenIf>
          <input
            className={classes.formInput}
            type="password"
            name={att.newPassword.key}
            ref={register}
            defaultValue=""
            placeholder={att.newPassword.label}
          />
          <HiddenIf value={typeof errors[att.newPassword2.key] !== "undefined" }>
            <Typography variant="subtitle2" style={{color: "red", fontSize: "15px"}}>{(errors[att.newPassword2.key] || {}).message}</Typography>
          </HiddenIf>
          <input
            className={classes.formInput}
            type="password"
            name={att.newPassword2.key}
            ref={register}
            defaultValue=""
            placeholder={att.newPassword2.label}
          />
          <CustomButton type="submit">
            Save
          </CustomButton>
        </>
      </HiddenIf>
       <HiddenIf value={isDisplay}>
       <Typography className={classes.info}>
         Password:
         &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
         </Typography>
     </HiddenIf>
     </Box>
    )
}

export const ChangePasswordForm = buildForm({ FormBody: ChangePasswordFormBody, Validation })
  