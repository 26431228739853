import React, { Fragment, useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ImageZoom from "./ImageZoom";
import { getProduct } from "../../services/product";
import ColourChooser from "./ColourChooser";

import MessageBar from "../../components/MessageBar";
import { addItem } from "../../redux/cart/cart.actions";
import queryString from "query-string";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { selectUserAuthStatus } from "../../redux/user/user.selectors";
import { removeFromWishList, addToWishList } from '../../redux/user/user.actions';
import AuthOnly from "../../components/AuthOnly";
import { withRouter, Link } from "react-router-dom";
import {
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  ButtonGroup,
  CardMedia,
  Box
} from "@material-ui/core";

import "./productDetail.scss";
import HiddenIf from "../../components/HiddenIf";
import {
  selectWishList,selectBrands
} from "../../redux/user/user.selectors";

import {
  applyUserBrandDiscounts
} from "../../redux/products/products.utils";

import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

import { getFilters } from "../../microSiteConfig";
import { properRounding, productFilterNameMatch } from "../../utils/helpers";

const theFilters = getFilters();

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2)
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    minHeight: 0,
    seamless: true
  },
  priceWithSale: {
    //fontSize: "14px",
    color: "red",
    display: "inline"
  },
  priceWithoutSale: {
    fontSize: "14px",
    display: "inline",
    textDecoration: "line-through"
  },
  discountnoteclass: {
    fontSize: 11,
    margin: "10px 0"
  },
  iFrameCardMedia: {
    height: "395px",
    border: 0,
    marginTop: "20px",
    marginBottom: "40px",
    "@media screen and (max-width:600px)": {
      width: "calc(100% + 120px)",
      transform: "scale(0.85, 0.85)",
      marginLeft: "-73px"
    },
    "@media screen and (max-width:480px)": {
      width: "calc(100% + 230px)",
      transform: "scale(0.65, 0.65)",
      marginLeft: "-103px"
    }
  },
  imgCardMedia: {
    border: 0,
    margin: "30px auto 50px",
    maxWidth: "640px"
  },
  table: {
    width: "100%"
  },
  box: {
    // border: "solid 3px",
    // margin: "0 2px",
    paddingLeft: "7px",
    paddingRight: "7px",
    // borderRadius: "5px"
  }
}));

const ProductDetail = props => {
  const [isInWishList, setIsInWishList] = useState(false);
  const [product, setProduct] = useState({
    loaded: false,
    product: {}
  });
  const [state, setState] = useState({
    itemChosen: 0,
    itemChosenColorWatcher: "",
    itemChosenTitle: "",
    itemChosenColour: "",
    errorCount: 0,
    isSelected: true,
    imageLoaded: false
  });

  const handleChosenItem = (choice, choiceIndex) => {
    setState({
      itemChosen: choiceIndex,
      itemChosenColorWatcher: choice.colourswatch ? choice.colourswatch : "",
      itemChosenTitle: choice.title ? choice.title : "",
      itemChosenColour: choice.colour ? choice.colour : "",
      errorCount: 0,
      isSelected: true
    });
  };
  const [details, setDetails] = useState({
    details: {
      Brand: "__",
      Model: "Product Loading...",
      "Eye Size": "__",
      "Bridge Size": "__",
      Depth: "__",
      "Temple Length": "__",
      Gender: "__",
      Construction: "__",
      Material: "__",
      Shape: "__"
    },
    desc: {
      branddescription: "",
      materialdescription: ""
    },
    Modelheading: "",
  });
  const [imgError, setImgError] = useState({
    errorCount: 0
  });
  const classes = useStyles();

  const updateProductBackend = async productId => {
    let params = queryString.parse(productId);
    await getProduct(params.productId).then(shop => {
      console.log("res=", shop);
      
      setProduct({ loaded: true, product: shop });
    }).catch(error => {
      console.log("error=", error);
      setProduct({ loaded: false, product: null });
    });
  };

  const onImageError = e => {
    setImgError({ errorCount: imgError.errorCount + 1 });

    if (imgError.errorCount === 1) {
      e.target.src =
        product.product.productinstance[state.itemChosen].imageBackup;
    } else {
      e.target.src =
        product.product.productinstance[state.itemChosen].imageDefault;
    }
  };

  useEffect(() => {    
    if(product.loaded) {            
      if(Object.keys(props.wishList).includes(product.product.productid)) {
        setIsInWishList(true);
      }
    }
  }, [props.wishList, product]);

  useEffect(() => {    
    if(product.loaded) {            
      var thisprods = applyUserBrandDiscounts(props.brands,[product.product],1);
      handleChosenItem(product.product.productinstance[state.itemChosen], 0);
    }
  }, [props.brands, product]);


  useEffect(() => {
    if (!product.loaded) {
      updateProductBackend(props.location.search);
    }
  }, [props.location.search]);

  useEffect(() => {
    if (product.loaded) {
      setDetails({
        details: {
          Brand: productFilterNameMatch(product.product.subbrand,product.product.subbrandname),
          Model: product.product.productinstance[state.itemChosen].model,//product.product.productinstance[state.itemChosen].modelnotdisplayfixed?product.product.productinstance[state.itemChosen].modelnotdisplayfixed:
          "Eye Size": product.product.productinstance[state.itemChosen].eyesize,
          "Bridge Size":
            product.product.productinstance[state.itemChosen].bridge,
          Depth: product.product.productdepth,
          "Temple Length": product.product.producttemplelength,
          Gender: product.product.genderdescription,
          Construction: product.product.construction,
          Material: product.product.material,
          Shape: product.product.shapedescription
        },
        desc: {
          branddescription: product.product.branddescription,
          materialdescription: product.product.materialdescription
        },
        Modelheading: product.product.productinstance[state.itemChosen].model
      });
    }
  }, [product, state]);

  const handleAddToCart = triggerSuccess => {
    let data = { ...product.product };
    delete data.productinstance;
    props.addItem({
      ...data,
      ...product.product.productinstance[state.itemChosen]
    });
  };

  

  const toggleState = () => {
    if(!product.loaded) return;

    if(isInWishList){
        props.removeFromWishList({ ...product.product });
    } else{
        let productInstance = { ...product.product.productinstance[0], ...product.product};
        productInstance.productinstance = undefined;        
        props.addToWishList({ ...productInstance });
    }
    setIsInWishList(!isInWishList)
  }

  return (

    <div className={`${classes.root} shoppage_general shoppage_single`}>
      {product.product ?
        <Fragment>
          
              {product.product.productinstance && (
                <div className={`${classes.card} singleProduc-heroimage`}>
                  {typeof product.product.productinstance[state.itemChosen].pageasset === "undefined" || product.product.productinstance[state.itemChosen].pageasset === null ? (
                    null
                  ) : product.product.productinstance[state.itemChosen].pageasset.includes("html") ? (
                    <CardMedia
                    className={classes.iFrameCardMedia}
                    component={"iframe"}
                    alt="Glasses Viewer"
                    src={
                      product.product.productinstance[state.itemChosen].imageViewer
                    }
                    title="Glasses Viewer"
                  /> 
                  ) : (
                  <CardMedia
                    className={classes.imgCardMedia}
                    component={"img"}
                    alt="Glasses Viewer"
                    src={
                      product.product.productinstance[state.itemChosen].imageViewer
                    }
                    title="Glasses Viewer"
                    onError={e => {
                      e.target.src = 'https://d3i7dklp9u03xa.cloudfront.net/cdn/media/catalog/product/placeholder/default/Optique_Line_Watermark_3.png';
                    }}
                    />

                  )}
                  
                </div>
              )}

<div className="productcol2">

<div className="productcol2-title">
<AuthOnly>
<Typography variant="inherit" display={"inline"} style={{float:'right', paddingTop: 10}}>
    <HiddenIf value={isInWishList}>
      <FavoriteIcon className="wishlistButton" onClick={toggleState} style={{ fontSize: 20, color: '#de9dac' }} />
    </HiddenIf>
    <HiddenIf value={!isInWishList}>
      <FavoriteBorderIcon className="wishlistButton" onClick={toggleState} style={{ fontSize: 20, color: '#de9dac' }} />
    </HiddenIf>
  </Typography>
  </AuthOnly>
  <h2>
    {productFilterNameMatch(product.product.subbrand,product.product.subbrandname)} {details.Modelheading}
  </h2>
                  
</div>
<div className="productcol2-details">
  <Typography variant="inherit" className={classes.box}>
    {details.details["Eye Size"] +
      "." +
      details.details["Bridge Size"]}
  </Typography>
  <Typography variant="inherit" className={classes.box}>
    {details.details["Depth"]}
  </Typography>                    
</div>                  

{product.product.productinstance && (
  <>
    <AuthOnly>
    <div className="productcol2-pricing">
      <HiddenIf
          value={
            product.product.productinstance[state.itemChosen]
              .clearanceitem === false
          }
        >
      <h4 class="pricing">
            $
        {properRounding(
            product.product.productinstance[state.itemChosen]
              .price
          ,2,1)}{" "}
        +GST
      </h4>
      </HiddenIf>


      <HiddenIf
          value={
            product.product.productinstance[state.itemChosen]
              .clearanceitem === true
          }
        >
          <h4 class="pricing">
          <span className={classes.priceWithoutSale}>
            $
        {properRounding(
            product.product.productinstance[state.itemChosen]
              .normalprice
          ,2,1)}{" "}
        +GST
      </span>
          <span className={classes.priceWithSale}>
            &nbsp;$
        {properRounding(
            product.product.productinstance[state.itemChosen]
              .clearanceprice
          ,2,1)}{" "}
        +GST
      </span>
      </h4>
        </HiddenIf>


        <p>
          Availability:{" "}
          {Number(
            product.product.productinstance[state.itemChosen]
              .stockavailable
          ) === 0
            ? "Out of Stock"
            : "In Stock"}
        </p>

      {product.product.productinstance[state.itemChosen].discountnote?
        <Typography className={classes.discountnoteclass}>
        {product.product.productinstance[state.itemChosen].discountnote}
        </Typography>
        :null}

      </div>
    </AuthOnly>
    <div className="productcol2-colour">
      <ColourChooser
        choices={product.product}
        itemChosen={state}
        productIndex={product.product.productid}
        handleChosenItem={handleChosenItem}
        sizeMatters={0}
      />
    </div>
    <AuthOnly>
    <div className="productcol2-addbutton">
        <MessageBar thisStock={product.product.productinstance[state.itemChosen].stockavailable} handleAddToCart={handleAddToCart} />
      </div>
    </AuthOnly>
  </>
)}
</div>

              <div className="singleProducInfoWrap">

                <div className="singleProducInfo-col singleProducInfo-col1">
                  <h3>Specifications</h3>
                  <table className="singleProducInfo-table">
                      <tbody>
                        {Object.keys(details.details).map(key => (
                          <tr key={key}>
                            <th align="left">{key}</th>
                            <td align="left">{details.details[key]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  {/* <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      aria-label="simple table"
                      size="small"
                    >
                      <TableBody>
                        {Object.keys(details.details).map(key => (
                          <TableRow key={key}>
                            <TableCell component="th" scope="row">
                              {null}
                            </TableCell>
                            <TableCell align="left">{key}</TableCell>
                            <TableCell align="left">{details.details[key]}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer> */}
                </div>
                <div className="singleProducInfo-col singleProducInfo-col2">
                <h3>Product Description</h3> 
            {/*
                 <Typography variant="body1" className="desc">
                  {details.desc.branddescription}
                </Typography>
               <Typography
                  variant="body1"
                  className="desc"
                  style={{
                    paddingBottom: 0
                  }}
                >
                  {details.desc.materialdescription}
                </Typography>
                */}
                <div className="product-descriptions product-descriptions-brand" dangerouslySetInnerHTML={{
                      __html: details.desc.branddescription
                    }}></div>

                <div className="product-descriptions product-descriptions-material" dangerouslySetInnerHTML={{
                      __html: details.desc.materialdescription
                    }}></div>
                    
                </div>
                
              </div>
            

                
        </Fragment>
        :
        <Grid container direction="row" alignItems="center" justify="center" className="no_product_container">
          <Typography variant="subtitle1">
            Sorry, that product could not be found. Please try and <Link to="/shop">locate it here</Link> or <Link to="/contactus">contact us</Link> for assistance
          </Typography>
        </Grid>
      }
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  isLoggedIn: selectUserAuthStatus,
  wishList:selectWishList,
  brands:selectBrands
});

export default withRouter(connect(mapStateToProps, { addItem, addToWishList, removeFromWishList })(ProductDetail));
