import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { 
  SignInForm 
  , ChangePasswordModalForm
  , RegisterForm
  , ForgotPasswordStepOneForm
  , ForgotPasswordStepTwoForm
} from "../accountForms"
import {
  Grid,
  Typography,
  TextField,
  Button,
  Fade,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Link,
  IconButton,
  useMediaQuery,
  ButtonGroup
} from "@material-ui/core";

import MuiAlert from "@material-ui/lab/Alert";

// State Libraries
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";

import {
  loginUser,
  forgotPasswordSubmit,
  forgotPasswordComplete,
  registerUser,
  setModalStatus,
  setLoginModalOpen,
  changePassword,
  logoutUser
} from "../../redux/user/user.actions";

import {
  selectLoginStatus,
  selectRegisterStatus,
  selectResetStatus,
  selectLoginModalStatus,
  selectUserFetching,
  selectLoginModalOpen,
  selectUserAuthError,
  selectUserAuthStatus
} from "../../redux/user/user.selectors";

import CloseIcon from "@material-ui/icons/Close";

import Login from "./Login";


import { authStates } from "../../settings";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  root: {
    padding: theme.spacing(3, 2)
  },
  textNarrowField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  textWideField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300
  },
  dropDownWide: {
    width: 300,
    margin: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  closeButton: {
    float: "right",
    paddingTop: "5px",
    // right: theme.spacing(1),
    // top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));




const LoginInline = props => {
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");

  const [inlineIniter, setInlineIniter] = useState('1');
  

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  
  /*useEffect(() => { 
    console.log('inlineIniter',inlineIniter);
if(inlineIniter === '1'){
    props.setModalStatus('Log in');
    setInlineIniter('2');
}
  },[inlineIniter]);*/

  const handleOpen = type => {
    props.setModalStatus(type);
    props.setLoginModalOpen(true);
  };

  const handleClose = () => {
    props.setLoginModalOpen(false);
  };

  const handleSignIn = data => {
    props.loginUser(data.emailAddress, data.password);
  }

  const handleRegister = data => {

    props.registerUser(data);
  };

  const handleChangePassword = data => {

    props.changePassword(
      data.oldPassword,
      data.newPassword
    );
  }

  const handleForgotPasswordStepOne = data => {
    setForgotPasswordEmail(data.emailAddress);
    props.forgotPasswordSubmit(data.emailAddress);
  }
  const handleForgotPasswordStepTwo = data => {
    props.forgotPasswordComplete(
      forgotPasswordEmail,
      data.newPassword,
      data.code
    );
  }

  return (
    <div id="inlineloginsection">
      <DialogTitle id="scroll-dialog-title">
          Log in
        </DialogTitle>
        {props.errorMessage && (
            <MuiAlert severity="error">{props.errorMessage}</MuiAlert>
          )}
          
            {props.userIsFetching === true ? (
              
                <Typography><span className="loadingmsginline">Loading ...</span></Typography>
              
            ) : (
                
                <SignInForm
                classes={classes}
                Footer={ModelActions}
                footerProps={{
                  modalStatus: props.modalStatus,
                  handleOpen: handleOpen
                }}
                handleOpen={handleOpen}
                onSubmit={handleSignIn}
              />

            )}
         
      
    </div>
  );
};

const ModelActions = (props) => (
    <DialogActions>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
                <Button
                  onClick={() => props.handleOpen(authStates.FORGOT_PASSWORD)}
                  color="secondary"
                >
                  Forgot your password?
                </Button>
            </Grid>
            <Grid item>
                {[
                  authStates.CHANGE_PASSWORD_SUCCESS,
                  authStates.LOGIN_SUCCESS,
                  authStates.SIGNUP_SUCCESS,
                  authStates.FORGOT_PASSWORD_COMPLETED_SUCCESS
                ].includes(props.modalStatus) === false && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </Button>
                )}
            </Grid>
          </Grid>
        </DialogActions>
     
)

const mapStateToProps = createStructuredSelector({
  loginStatus: selectLoginStatus,
  registerStatus: selectRegisterStatus,
  resetStatus: selectResetStatus,
  modalStatus: selectLoginModalStatus,
  userIsFetching: selectUserFetching,
  modalOpen: selectLoginModalOpen,
  errorMessage: selectUserAuthError,
  isLoggedIn: selectUserAuthStatus
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loginUser,
      forgotPasswordSubmit,
      forgotPasswordComplete,
      changePassword,
      registerUser,
      setModalStatus,
      setLoginModalOpen,
      changePassword,
      logoutUser
    },
    dispatch
  );

// export default connect(mapStateToProps, mapDispatchToProps)(Shop);
export default connect(mapStateToProps, mapDispatchToProps)(LoginInline);
