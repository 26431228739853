import React, { useState, useEffect } from "react";

import OrderSummary from "../pages/cartpage/OrderSummary"
import { reAddItem } from "../redux/cart/cart.actions";
import { connect } from "react-redux";

const OrderSummaryDataMapping = (props) => {

    const [double, setDouble] = useState(false);
    return (
      <>
<button
    className={`custom-button`}
    disabled={double}
    style={{maxWidth:500,margin:"10px auto",display:"block"}}
    onClick={() => { setDouble(true); props.reAddItem({ items: props.items });}}
  >
    Add the items from this order to my cart
  </button>
        <OrderSummary
shippingAddress={props.shippingAddress}
billingAddress={props.billingAddress}
shippingFee={props.order.shipping}
totalWeight={0}
coupon={{}}
rows={props.items}
subTotal={props.order.subtotal}
tax={props.order.tax}
discount={0}
total={props.order.grandtotal}
storeDirectDetailRef={props.order.storeDirectRef}
storeDirectDetailComments={props.order.storeDirectComments}
labDirect={props.order.labDirectKey ? props.order.labDirectKey.state: null}
labDirectQuantity={props.details.labDirectQuantity}
storeDirectQuantity={props.details.storeDirectQuantity}
shippingCheckbox={props.shippingToBillingAddress}
/> 
 

</>
)
    }



export default connect(null, { reAddItem })(OrderSummaryDataMapping);