//@ts-check

import * as React from 'react';
import { Link as LinkImport } from 'react-router-dom';
import isExternal from 'is-url-external';



export const Link = ({
  to,
  ...otherProps
}) => {
    const isEx = isExternal(to)
    //console.log('URL ', to,' external ',isEx)
    return (
    isExternal(to)
    ? (
      <a
        href={to}
        {...otherProps}
      />
    ) : (
      <LinkImport
        to={to}
        {...otherProps}
      />
    )
    )
};
