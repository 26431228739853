import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button
} from "@material-ui/core";

import OrderSummary from "../pages/cartpage/OrderSummary"
import CustomButton from "./custom-button/custom-button.component";
import { reAddItem } from "../redux/cart/cart.actions";
import { connect } from "react-redux";
import OrderSummaryDataMapping from "./PastOrderDetails";


const useStyles = makeStyles(theme => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    root: {
      padding: theme.spacing(3, 2)
    },
    closeButton: {
      float: "right",
      paddingTop: "5px",
      // right: theme.spacing(1),
      // top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
}));




const Defaults = {
      billingAddress: {},
      shippingAddress: {},
      shippingToBillingAddress: "",
      recipients: [""],
      order: {
        id: "",
        date: "",
        subtotal: 0,
        shipping: 0,
        tax: 0,
        grandtotal: 0,
        shippingMethod:
          "",
        labDirectKey: "",
        labDirectValue: "",
        storeDirectRef: "",
        storeDirectComments: "",
      },
      details: {
        labDirectQuantity: "",
        storeDirectQuantity: "",
      },
      items: "",
      UserId: ""
}
{/* */}



export const PastOrderReviewModal = ({
    isOpen = false
    ,data = Defaults
    ,handleClose = (e=>(null))
    ,fullScreen = false
    ,...props
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setOpen(isOpen)
    },[isOpen])

    const _handleClose = (event) => {
        setOpen(false)
        handleClose(event)
    }
    return (
        <Dialog
        fullScreen={true}
        open={isOpen}
        onClose={_handleClose}
        maxWidth={"lg"}
        fullWidth={true}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">
          Past Order {data.OrderId}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={_handleClose}
          >
            <CloseIcon />
          </IconButton>

        </DialogTitle>
        <DialogContent dividers={true}>
                <OrderSummaryDataMapping {...data.data } />
        </DialogContent>
        </Dialog>
    )
}





