import React, { Fragment } from "react";

import { makeStyles } from "@material-ui/styles";

import { Grid, Typography, Button, Container, Hidden } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import PinterestIcon from "@material-ui/icons/Pinterest";
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from "@material-ui/icons/YouTube";
import PublicIcon from '@material-ui/icons/Public';
import { Link } from "./Link";
import { getURL } from "../microSiteConfig";
import { getAssets, getNavigationPaths } from "../microSiteConfig";

import LoginModalFooter from './navigation/LoginModalFooter';

const config = getAssets();
const ENDPOINTS = getNavigationPaths();

const useStyles = makeStyles(theme => ({
  icon: {
    color: "#ffffff"
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    "@media screen and (max-width:800px)": {
      display: "flex",
      flexDirection: "column",
      width: "inherit",
      "& :first-child": {
        padding: "0 auto"
      }
    }
  },
  content: {
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto"
    },
    display: "flex",
    flexDirection: "row",
  },
  copyright: {
    color: "#c7f0ff",
    padding: "3px"
  },
  separator: {
    color: "#c7f0ff",
    paddingLeft: "5px",
    paddingRight: "4px",
    paddingTop: 0,
    paddingBottom: 0
  },
  socials: {
    paddingTop: "1px",
  },
  links: {
    color: "white",
    margin: 0,
    padding: 0,
    fontSize: 16
  },
  root: {
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    zIndex: 1,
    color: "white",
  },
  break: {
    color: "white",
    margin: 0,
    padding: 0,
    fontSize: 16,
    [theme.breakpoints.down("sm")]: { textAlign: "center" }
  },
  micrositefooter: theme.micrositefooter,
  footerinfo: {
    paddingTop: 20,
  },
  footerlogo: {
    float: "right",
    bottom: 65,
    position: "relative",
    maxWidth: 211,   
    maxHeight: 107,
    objectFit: "contain"     
  },
  findsocket: {
    position:'relative', 
    bottom: 30,
    right: 20,
    textDecoration:'inherit !important',
    color: 'inherit !important',
    paddding: 0,
    margin: 0,    
  }
}));

const Separator = () => {
  const classes = useStyles();
  return (
    <Typography
      component={"div"}
      className={classes.separator}
      display="inline"
    >
      •
    </Typography>
  );
};

const FooterV2 = () => {
  const classes = useStyles();
  const currURL = getURL();
  const isBatchOfSites1 = (currURL === "www.auraeyewear.com.au" 
  || currURL === "www.geminieyewear.com.au" || currURL === "www.merseybeateyewear.com.au" || currURL === "www.convertibleseyewear.com");
  const isMicroSiteAny = isBatchOfSites1;//(currURL === "www.steppereyewear.com.au" || isBatchOfSites1);

  const isStepper = (currURL === "www.steppereyewear.com.au");

  return (
    <footer className={isMicroSiteAny?classes.root:isStepper?'landingpage mainsite':'mainsite'}>
      {isMicroSiteAny ?
        <Container className={classes.micrositefooter}>
          <Grid container direction="row">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container direction="column" className={classes.footerinfo}>
                <Grid item>
                  <Grid container className={classes.socials} direction="row">
                    <h6 className={classes.content}>
                      INFORMATION
                  </h6>
                  </Grid>
                </Grid>
                <Grid item>
                  <div className={classes.break}>
                    <Typography className={classes.break}>
                      {currURL === "www.steppereyewear.com.au" && "© 2019 Stepper Eyewear Australia. All Rights Reserved."}
                      {currURL === "www.auraeyewear.com.au" && "© 2019 Aura Eyewear Australia. All Rights Reserved."}
                      {currURL === "www.geminieyewear.com.au" && "© 2019 Gemini Eyewear Australia. All Rights Reserved."}
                      {currURL === "www.merseybeateyewear.com.au" && "© 2019 Merseybeat Eyewear Australia. All Rights Reserved."}
                      {currURL === "www.convertibleseyewear.com" && "© 2019 Convertibles Eyewear Australia. All Rights Reserved."}
                  </Typography>
                    <Typography className={classes.links}>
                      <Link className="link" to="/becomeastockist">
                        Become A Stockist
                    </Link>{" "}
                    /{" "}
                    <Link className="link" to="/faq?brandreset=1">
                        FAQs
                    </Link>{" "}
                    /{" "}
                    <Link className="link" to="/privacy?brandreset=1">
                        Privacy
                    </Link>{" "}
                    /{" "}
                    <Link className="link" to="/terms?brandreset=1">
                        T & C
                    </Link>
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {(currURL === "www.steppereyewear.com.au" ) &&
            <Hidden smDown>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container direction="column" alignItems="flex-end">
                <img className={classes.footerlogo} src={config.footerLogo} />                
                  <Link to={`/findAStockist`} className={classes.findsocket}>
                    <h6><PublicIcon style={{ fontSize: 18, color: '#fff', marginBottom: '-2px', display: 'inline-block' }} /> Find a Stockist</h6>
                  </Link>              
              </Grid>
            </Grid>
            </Hidden>
           }
           {(isBatchOfSites1) &&
            <Hidden smDown>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container justify="flex-end" alignItems="flex-end" style={{height:"100%"}}>                
                  <Link to={`/findAStockist`} className="link">
                    <h6><PublicIcon style={{ fontSize: 18, color: '#fff', marginBottom: '-2px', display: 'inline-block' }} /> Find a Stockist</h6>
                  </Link>              
              </Grid>
            </Grid>
            </Hidden>
           }
          </Grid>
        </Container>
        :
 
        <div>
          <div className="row1">
            <div className="column1">
            {isStepper ?
              <a className="link" href="/"><img className="landingpage-footer-logo footer-logo" src="https://d3i7dklp9u03xa.cloudfront.net/wp-media/uploads/2024/01/Brands_Stepper-1024x512.png" /></a>
              :
              <a className="link" href="/"><img className="footer-logo" src="/ol-main-logo.png" /></a>
            }
            </div>
            <div className="column2">
              {isStepper ?
              <Fragment>
                <div className="footernavcol">
                  <ul>
                    <li><strong><a href={ENDPOINTS.findAStockist}>Find a Stockist</a></strong></li>
                    <li><a href="https://www.optiqueline.com.au/becomeastockist?brandreset=1">Become a Stockist</a></li>
                    <li><a href="https://www.optiqueline.com.au/privacy?brandreset=1">Privacy Policy</a></li>
                  </ul>
                </div>
                <div className="footernavcol">
                <ul>
                  <li><strong><a href={ENDPOINTS.shop}>Shop the Range</a></strong></li>
                  <li><a href="https://www.optiqueline.com.au/faq?brandreset=1">FAQs</a></li>
                  <li><a href="https://www.optiqueline.com.au/terms?brandreset=1">T&amp;Cs</a></li>
                </ul>
              </div>
              </Fragment>  
                 :
              <Fragment>
              <div className="footernavcol">
                <ul>
                  <li><strong><a href="/blog/">Blog</a></strong></li>
                  <li><a href="/newsletter">Join Newsletter</a></li>
                  <li><a href="/shop">See Catalogue</a></li>
                  <li><a href="/marketing-support/">Marketing Support</a></li>
                </ul>
              </div>
              <div className="footernavcol">
                <ul>
                  <li><strong><a href="/forms">Forms</a></strong></li>
                  <li><a href="/terms">T&amp;Cs</a></li>
                  <li><a href="/faq">FAQs</a></li>
                  <li><a href="/aboutus">Social Responsibility</a></li>
                </ul>
              </div>
              <div className="footernavcol">
                <ul>
                  <li><strong><a href="/meet-our-team">Account Managers</a></strong></li>
                  <li><a href="/meet-our-team">About Us</a></li>
                  <li><a href="/contact-the-team">Contact Us</a></li>
                  <li><a href="/privacy">Privacy Policy</a></li>
                </ul>
              </div>
              <div className="footernavcol">
              
                <LoginModalFooter />
              </div>
              </Fragment>
              }
            </div>
          </div>
          <div className="row2">
            <div className="column1">
              {isStepper ?
              <Fragment>&copy; {new Date().getFullYear()}{" "} Stepper Eyewear Australia<br /></Fragment>
            :
            null
            }
              &copy; {new Date().getFullYear()}{" "} Optique Line PTY Ltd - Brands With Vision 
            </div>
            <div className="column2">
              <span>Follow Us: </span>
              <a href={"https://www.facebook.com/optique.line"} className="footericonlink">
                <FacebookIcon className={'footericon'} fontSize="large" />
              </a>
              <a href={"#"} className="footericonlink">
                <InstagramIcon className={'footericon'} fontSize="large" />
              </a>
              {/* <a href={"https://www.youtube.com/channel/UCg6KvHKoKd7uMWzwrXnRNkw/videos"}>
                <YouTubeIcon className={'footericon'} fontSize="large" />
                </a> */}
            </div>
          </div>
        </div>
      }
    </footer>
  );
};

export default FooterV2;
