import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

export default (props) => {
    return (
        <div>
            <Carousel className='carousel'>
            {props.images.map((image,index) => (
                <Carousel.Item key={index}>
                    {image.link!==null?
                    <a href={image.link}>
                        <img key={index} src={image.src} alt=""/>
                        </a>
                        :
                        <img key={index} src={image.src} alt=""/>
                    }
                    
                </Carousel.Item>
            ))}
            </Carousel>
        </div>
    );
};
