import React from "react"
import { buildForm } from "./BaseForm";
import { Controller } from "react-hook-form";

import * as yup from "yup"
import {
  Grid,
  Typography,
  TextField,
  DialogContent,
} from "@material-ui/core";

export const att = {
  emailAddress: {key: "emailAddress", label: "Email Address"},
  password: {key: "password", label: "Password"},
}

export const Validation =  yup.object().shape({
  [att.emailAddress.key]: yup.string().email().required().label(att.emailAddress.label), 
  [att.password.key]: yup.string().required().label(att.password.label), 
})



export const SignInFormBody = ({ 
  data = {}
,  footerProps = {}
, Footer
, validation: {
  errors,
  control
} = {
  errors: {}
}
,...props 
}) => {
    return (
      <>
      <DialogContent dividers={true}>
      <Grid container justify="flex-start" alignItems="flex-start">
        <Grid item xs={12}>
          <Typography variant="subtitle1">Registered Customers</Typography>
          <Typography>
            If you have an account with us, please log in.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={<TextField 
              error={typeof errors[att.emailAddress.key] !== "undefined"}
              className={props.classes.textNarrowField}
              type="text"
              margin="normal"
              label={att.emailAddress.label}
              helperText={(errors[att.emailAddress.key]||{message: "" }).message}
            />}
            name={att.emailAddress.key}
            control={control}
            defaultValue=""
            />
        </Grid>
        <Grid item xs={12} md={6}>
        <Controller
            as={<TextField 
              error={typeof errors[att.password.key] !== "undefined"}
              className={data.textNarrowField}
              type="password"
              margin="normal"
              label={att.password.label}
              helperText={(errors[att.password.key]||{message: ""}).message}
            />}
            name={att.password.key}
            control={control}
            defaultValue=""
        />
        </Grid>
      </Grid>
      </DialogContent>
      <Footer {...footerProps} />
      </>
    );
  }
  
export const SignInForm = buildForm({ FormBody: SignInFormBody, Validation })

