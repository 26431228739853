import React from 'react';
import { selectUserAuthStatus } from "../redux/user/user.selectors";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import HiddenIf from './HiddenIf';


const AuthOnly = (props) => {
  return (
    <HiddenIf value={(props.showiffail?!props.isLoggedIn:props.isLoggedIn)}>
        {props.children}
    </HiddenIf>
  );
}
const mapStateToProps = createStructuredSelector({
    isLoggedIn: selectUserAuthStatus
});
  
export default connect(mapStateToProps)(AuthOnly);
