import {getNavigationPaths} from './navigation'

export const AssetBucket = "https://d3i7dklp9u03xa.cloudfront.net"


export const getTerritoryManagers = () => ({
    Victoria: {
        managers: [
          {
            name: "JOHN NICOLA",
            phoneNumber: "0418 322 658",
            phoneNumberLink: "+61418322658",
            email: "john@optiqueline.com.au"
          },
          {
            name: "ANDREW CRAIB",
            phoneNumber: "0400 168 754",
            phoneNumberLink: "+61400168754",
            email: "acraib@optiqueline.com.au"
          },
          {
            name: "ROY PARKER",
            phoneNumber: "0435 940 775",
            phoneNumberLink: "+61435940775",
            email: "roy@optiqueline.com.au"
          },
          {
            name: "URVASHI BILIMORIA",
            phoneNumber: "0432 091 318",
            phoneNumberLink: "+61432091318",
            email: "urvashi@optiqueline.com.au"
          }
        ]
      },
      Tasmania: {
        managers: [
          {
            name: "ANDREW CRAIB",
            phoneNumber: "0400 168 754",
            phoneNumberLink: "+61400168754",
            email: "acraib@optiqueline.com.au"
          }
        ]
      },
      "South Australia": {
        managers: [
          {
            name: "ROY PARKER",
            phoneNumber: "0435 940 775",
            phoneNumberLink: "+61435940775",
            email: "roy@optiqueline.com.au"
          }
        ]
      },
      "New South Wales": {
        managers: [
          {
            name: "BETH BIRCH",
            phoneNumber: "0413 837 383",
            phoneNumberLink: "+61413837383",
            email: "beth@optiqueline.com.au"
          },
          {
            name: "DAVID BIRCH",
            phoneNumber: "0411 622 848",
            phoneNumberLink: "+61411622848",
            email: "david@optiqueline.com.au"
          }
        ]
      },
      "Western Australia": {
        managers: [
          {
            name: "ANDREW CRAIB",
            phoneNumber: "0400 168 754",
            phoneNumberLink: "+61400 168 754",
            email: "acraib@optiqueline.com.au"
          }
        ]
      },
      "QUEENSLAND & NORTHERN TERRITORY": {
        managers: [
          {
            name: "DOUG STABE",
            phoneNumber: "0418 782 848",
            phoneNumberLink: "+61418782848",
            email: "doug@optiqueline.com.au"
          },
          {
            name: "JASON HANRAHAN",
            phoneNumber: "0414 928 787",
            phoneNumberLink: "+61414928787",
            email: "jason@optiqueline.com.au"
          }
        ]
      }

});

export const getAssets = () => {
  const paths = getNavigationPaths()
  return {
      logo: "/ol-main-logo.png",
      carousel: [
          /*{
            src:`${AssetBucket}/homepage/AU-GETAWAY-SLIDER-OCTOBER-1.jpg`,
            link: '/luxurygetaway_au/'
          },
          {
            src:`${AssetBucket}/homepage/AU-GETAWAY-SLIDER-OCTOBER-2.jpg`,
            link: '/luxurygetaway_au/'
          },*/
          {
            src:`${AssetBucket}/homepage/a.png`,
            link: null
          },
          {
            src:`${AssetBucket}/homepage/b.png`,
            link: null
          },
          /*{
            src:`${AssetBucket}/homepage/c.png`,
            link: null
          },*/
          {
            src:`${AssetBucket}/homepage/d.png`,
            link: null
          },
          /*{
            src:`${AssetBucket}/homepage/e.png`,
            link: null
          },
          {
            src:`${AssetBucket}/homepage/f.png`,
            link: null
          },
          {
            src:`${AssetBucket}/homepage/h.png`,
            link: null
          },*/
          {
            src:`${AssetBucket}/homepage/i.png`,
            link: null
          }
      ],
      glasses: [
          {
              img:  `${AssetBucket}/homepage/aura-911-C1@2x.jpg`,
              link: "/productDetail?productId=911-54-17"
          },
          {
              img: `${AssetBucket}/homepage/convertibles-92016-A@2x.jpg`,
              link: "/productDetail?productId=92016-51-16"
          },
          {
              img: `${AssetBucket}/homepage/essentials-163-B-1@2x.jpg`,
              link: "/productDetail?productId=163-54-17"
          },
          {
              img: `${AssetBucket}/homepage/gemini-517-A@2x.jpg`,
              link: "/productDetail?productId=517-50-16"
          },
          {
              img: `${AssetBucket}/homepage/stepper-SI-30168-F580@2x.jpg`,
              link: "/productDetail?productId=30168-54-14"
          },
          {
              img: `${AssetBucket}/homepage/stepper-sts-30051-F391@2x.jpg`,
              link: "/productDetail?productId=30051-53-14"
          }
      ],
      homeGridRow1: [
          {
              //img: `${AssetBucket}/homepage/AU-GETAWAY-OCTOBER.gif`,
              //link: "/luxurygetaway_au/"
              //img: `${AssetBucket}/homepage/stepper-2021-home-block-img.jpg`,
              //link: paths.stepperPromo
              //img: `${AssetBucket}/homepage/WEBSITE-xmas-block-v3.png`,
              //link: "/contact-the-team/"
              img: `${AssetBucket}/homepage/1.jpg`,
              link: paths.convertiblesPromo
          },
          {
              img: `${AssetBucket}/homepage/2.jpg`,
              link: paths.stepperPromo //"/csr/"
          }
      ],
      homeGridRow2: [
          {
              img: `${AssetBucket}/homepage/home-row2-marketingsupport.jpg`,
              title: "MARKETING SUPPORT",
              link: paths.marketingSupport
          },
          {
              img: `${AssetBucket}/homepage/Brands_SMALL_BLOCKS2.jpg`,
              title: "BRANDS",
              link: '/brands/'
          },
          {
              img: `${AssetBucket}/homepage/accessories_SMALL_BLOCKS.jpg`,
              title: "CASES & CLOTHS",
              link: '/accessories/'
          },
          {
              img: `${AssetBucket}/homepage/Clearance_SMALL_BLOCKS4.jpg`,
              title: "CLEARANCE ITEMS",
              link: paths.clearance
          }
      ],
      homeGridRow3: [
          {
              img: `${AssetBucket}/homepage/3.jpg`,
              link: paths.shop
          }
      ],
      homeMiddleSentenceText: 
        {
          label: 'The Brands With Vision'
        }
      ,
      homeGridRow4: [
          {
              img: `${AssetBucket}/homepage/home-row4-partnerprogram.jpg`,
              title: "INVENTORY MANAGEMENT SYSTEM",
              link: '/inventory-management-system/'
          },
          {
              img: `${AssetBucket}/homepage/home-row4-seeandsave.jpg`,
              title: "FRAME & LENS",
              link: '/frame-and-lens-packages/'
          },
          {
              img: `${AssetBucket}/homepage/home-row4-labdirect.jpg`,
              title: "LAB DIRECT",
              link: '/lab-direct/'
          },
          {
              img: `${AssetBucket}/homepage/home-row4-newsletter.jpg`,
              title: "NEWSLETTER",
              link: paths.newsLetter
          }
      ],
      sideColumnLinks1: [
          {
              img: `${AssetBucket}/miscimages/supply-fit.jpg`,
              title: "Supply & Fit",
              link: '/supply-fit-partner-program'
          },
          {
              img: `${AssetBucket}/miscimages/lab-direct.jpg`,
              title: "Lab Direct",
              link: '/lab-direct'
          }
      ],
      sideColumnLinks2: [
          // {
          //     img: `${AssetBucket}/homepage/1.jpg`,
          //     link: paths.convertiblesPromo
          // },
          // {
          //     img: `${AssetBucket}/homepage/2.jpg`,
          //     link: paths.stepperPromo
          // }
      ],
      description: [
          {
              type: "h1",
              body: "helkle"
          }
      ],
      landingButtonRow: [
          {
              label: 'Contact Us',
              link: '/contact-the-team/'
          },
          {
              label: 'Subscribe to Newsletter',
              link: paths.newsLetter
          }
      ]
  }
}
