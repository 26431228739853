import React from 'react'
import { Route, Redirect
} from 'react-router-dom'
import { selectUserAuthStatus } from "../redux/user/user.selectors";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import StaticPage from '../pages/StaticPage'


const PrivateRoute = ({ component: Component , isLoggedIn, ...rest }) => (
    <Route {...rest} render={(props) => (
        isLoggedIn === true
        ? <Component {...props} />
        : <StaticPage page="authonly" />
    )} />
  )
const mapStateToProps = createStructuredSelector({
    isLoggedIn: selectUserAuthStatus
});
  
export default connect(mapStateToProps)(PrivateRoute);